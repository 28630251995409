import React from 'react'
import { useSelector } from 'react-redux'
import { getTotalPrice } from '../PricingBreakdownModal/components/PricingBreakdown/utils'
import OrderSummaryLabel from './components/OrderSummaryLabel'
import TotalPriceLabel from './components/TotalPriceLabel'
import { selectors as pricingDataSelectors } from '../../../../store/ducks/pricingData'

const PricingSummary = () => {
  const pricingDetails = useSelector(pricingDataSelectors.pricingData)
  return (
    <>
      <OrderSummaryLabel />
      <div>
        <TotalPriceLabel total={getTotalPrice(pricingDetails)} />
      </div>
    </>
  )
}

export default PricingSummary

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectors as pricingDataSelectors } from '../../../../store/ducks/pricingData'
import { ACTIONS } from '../../../../store/ducks/pricingDetails'
import ActionLink from '../../../../components/ui/typography/ActionLink'
import { MODALS, trackViewModal } from '../../../../lib/tracking'

const PricingBreakdownTrigger = () => {
  const dispatch = useDispatch()

  const pricingDetails = useSelector(pricingDataSelectors.pricingData)
  const handleClick = React.useCallback(() => {
    trackViewModal({
      name: MODALS.PRICING_BREAKDOWN,
      properties: { pricingDetails, mobile: false },
    })
    dispatch(ACTIONS.togglePricingBreakdown(true))
  }, [dispatch, pricingDetails])

  if (!pricingDetails.total) return null
  return (
    <h5
      className="col-xs-12 center-xs"
      style={{ marginTop: '10px' }}
      data-tid="pricing-breakdown-trigger-25322"
    >
      <ActionLink
        id="PricingBreakdownTrigger"
        content="View Pricing Breakdown"
        onClick={handleClick}
        style={{ fontWeight: '800' }}
      />
    </h5>
  )
}

export default PricingBreakdownTrigger

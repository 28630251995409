import React from 'react'
import debounce from 'lodash/fp/debounce'
import isEqual from 'lodash/fp/isEqual'
import { useDispatch, useSelector } from 'react-redux'
import { handleBackButton } from '../../components/hocs/withBackButtonProtection'
import useWindowWidth from '../../lib/hooks/windowWidth'
import { LAYOUT_BREAKPOINT } from '../../lib/const'
import FinalServiceLocationUnavailableModal from './components/FinalServiceLocationUnavailableModal'
import InvalidDateModal from './components/InvalidDateModal'
import PageLayout from './components/PageLayout'
import OrderAccordion from './components/OrderAccordion'
import PaymentErrorModal from './components/PaymentErrorModal'
import PricingBreakdownModal from './components/PricingBreakdownModal'
import PricingBreakdownTrigger from './components/PricingBreakdownTrigger'
import PricingSummary from './components/PricingSummary'
import PricingSummaryFooter from './components/PricingSummaryFooter'
import PromoCodeForm from './components/PromoCodeForm'
import ItemRestrictionsModal from './components/ItemRestrictionsModal/index'
import ChargeUpfrontModal from './components/ChargeUpfrontModal'
import GoogleTrustedBadge from './components/GoogleTrustedBadge'
import NortonSeal from './components/NortonSeal'
import TextForEstimate from './components/TextForEstimate'
import { thunks as pricingDataThunks } from '../../store/ducks/pricingData'
import { thunks as excludedDatesThunks } from '../../store/ducks/excludedDates'
import styles from './NewOrder.module.scss'
import { isProductionEnvironment } from '../../lib/environment/environment'
import {
  THUNKS as sessionThunks,
  SELECTORS as sessionSelectors,
} from '../../store/ducks/session'
import { AppState } from '../../store/rootReducer'
import { updateOrderSessionData } from '../../components/hocs/withOrderSessionTracking'

interface IProps {
  itemTypes: Array<Object>
}
function NewOrder(props: IProps) {
  const width = useWindowWidth()
  const { itemTypes } = props
  const dispatch = useDispatch()

  const orderData = useSelector((state: AppState) => {
    return updateOrderSessionData(state.form)
  })

  const [previousOrderData, setPreviousOrderData] = React.useState(orderData)

  const sessionStatus = useSelector(sessionSelectors.getSessionStatus)

  React.useEffect(() => {
    if (!isEqual(orderData, previousOrderData)) {
      dispatch(pricingDataThunks.fetchPricingData())
      dispatch(excludedDatesThunks.fetchExcludedDates())
      dispatch(sessionThunks.updateSession())
      setPreviousOrderData(orderData)
    }
  }, [orderData, setPreviousOrderData, dispatch, previousOrderData])

  React.useEffect(() => {
    debounce(1500, () => {
      if (sessionStatus === 'succeeded') {
        dispatch(sessionThunks.updateSession())
      }
    })
  }, [sessionStatus, dispatch])

  React.useEffect(() => {
    if (isProductionEnvironment()) {
      window.addEventListener('beforeunload', handleBackButton)
    } else {
      console.log('Skipping beforeunload listener in development')
    }

    return () => {
      if (isProductionEnvironment()) {
        window.removeEventListener('beforeunload', handleBackButton)
      }
    }
  }, [])

  if (width >= LAYOUT_BREAKPOINT) {
    return (
      <div>
        <PageLayout
          left={<OrderAccordion itemTypes={itemTypes} />}
          right={
            <div className="new-order-summary">
              <div className="fixed-card">
                <PricingSummary />
                <PromoCodeForm />
                <PricingBreakdownTrigger />
              </div>
              <div className={styles.badges}>
                <GoogleTrustedBadge />
                <NortonSeal />
              </div>
              <TextForEstimate />
            </div>
          }
        />
        <PricingBreakdownModal />
        <InvalidDateModal />
        <PaymentErrorModal />
        <FinalServiceLocationUnavailableModal />
        <ItemRestrictionsModal />
        <ChargeUpfrontModal />
        <PricingSummaryFooter />
      </div>
    )
  }

  return (
    <div>
      <OrderAccordion itemTypes={itemTypes} />
      <PricingBreakdownModal />
      <InvalidDateModal />
      <PaymentErrorModal />
      <FinalServiceLocationUnavailableModal />
      <ItemRestrictionsModal />
      <ChargeUpfrontModal />
      <PromoCodeForm />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <GoogleTrustedBadge />
        <NortonSeal />
      </div>
      <TextForEstimate />
      <PricingSummaryFooter />
    </div>
  )
}

export default NewOrder

import React from 'react'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import PricingBreakdown from './components/PricingBreakdown'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACTIONS as pricingDetailsActions,
  SELECTORS as pricingDetailsSelectors,
} from '../../../../store/ducks/pricingDetails'

const PricingBreakdownModal = () => {
  const dispatch = useDispatch()
  const closePricingBreakdown = React.useCallback(() => {
    dispatch(pricingDetailsActions.togglePricingBreakdown(false))
  }, [dispatch])

  const isPricingBreakdownModalVisible = useSelector(
    pricingDetailsSelectors.getPricingBreakdownVisible
  )

  return (
    <CenteredModal
      blockScroll={false}
      open={isPricingBreakdownModalVisible}
      onClose={closePricingBreakdown}
    >
      <PricingBreakdown />
    </CenteredModal>
  )
}

export default PricingBreakdownModal

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import renderCounterInput from '../../../../../../../../components/ui/forms/renderCounterInput/renderCounterInput'

const FlightsOfStairsForm = ({ unitPrice }) => (
  <div className="col-xs-12 row center-xs" style={{ width: '100%' }}>
    <Field
      name="flightsOfStairs"
      component={renderCounterInput}
      min={0}
      max={30}
      subtitle={`$${unitPrice} per flight`}
    />
  </div>
)

FlightsOfStairsForm.propTypes = {
  unitPrice: PropTypes.number,
}

const validate = ({ flightsOfStairs }) => ({
  flightsOfStairs:
    flightsOfStairs > 5
      ? `Hmm...That's a lot of stairs. We don't mind, but if there is an elevator onsite that is accessible for our drivers to remove your items, you don't need to pay for all these stairs!`
      : null,
})

const withForm = reduxForm({
  validate,
  form: 'flightsOfStairsForm',
  destroyOnUnmount: false,
})

export default compose(withForm)(FlightsOfStairsForm)

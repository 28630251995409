import { gql } from 'apollo-boost'

export const query = gql`
  query PricingDetails($inputs: PricingDetailsInputs!) {
    pricingDetails(inputs: $inputs) {
      allowedDate
      asapSchedulingAllowed
      basePrice
      discounts {
        eligible
        subtotal
        type
        unitPrice
      }
      flightsOfStairsCount
      flightsOfStairsSubtotal
      flightsOfStairsUnitPrice
      items {
        assemblyAllowed
        assemblyCount
        assemblySubtotal
        assemblyUnitPrice
        disassemblyAllowed
        disassemblyCount
        disassemblySubtotal
        disassemblyUnitPrice
        itemType {
          detail
          id
          icon
          name
        }
        pickupAllowed
        pickupCount
        pickupSubtotal
        pickupUnitPrice
      }
      minimumPrice
      minimumPriceApplied
      promoApplied
      promoCode
      promoDiscountSubtotal
      total
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import styles from './CenteredModal.module.scss'

const CenteredModal = ({ children, onClose, open, focusTrapped, ...rest }) => (
  <Modal
    center
    classNames={{
      overlay: styles.customOverlay,
      modal: styles.customModal,
    }}
    onClose={onClose}
    open={open}
    focusTrapped={focusTrapped || false}
    {...rest}
  >
    <div className="row">{children}</div>
  </Modal>
)

CenteredModal.propTypes = {
  children: PropTypes.node.isRequired,
  focusTrapped: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CenteredModal

import React, { PureComponent } from 'react'
import Badge from '../../../../assets/images/google-reviews-badge.png'

const GOOGLE_URL = `https://www.google.com/search?ei=NamsXIvnGvCmggef1rWQBw&q=loadup+reviews
&lrd=0x88f51048f05f32eb:0x5b24368438869df5,1#lrd=0x88f51048f05f32eb:0x5b24368438869df5,1`

class GoogleTrustedBadge extends PureComponent {
  render() {
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          padding: '10px 20px 20px',
        }}
      >
        <a href={GOOGLE_URL} target="_blank" rel="noopener noreferrer">
          <img
            alt="Google Trusted Badge"
            src={Badge}
            style={{
              maxWidth: '140px',
              maxHeight: '60px',
              objectFit: 'contain',
              margin: '15px auto 0',
            }}
          />
        </a>
      </div>
    )
  }
}

export default GoogleTrustedBadge

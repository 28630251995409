import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import uniq from 'lodash/fp/uniq'
import PropTypes from 'prop-types'
import scrollToElement from 'scroll-to-element'
import { trackPage } from '../../../../lib/tracking'
import { ItemTypePropType } from '../../../../lib/types'
import { ACTIONS } from '../../../../store/ducks/section'
import OrderSectionExpandableContainer from './components/OrderSectionExpandableContainer/orderSectionExpandableContainer'
import {
  isSectionActive,
  isSectionFinished,
  isSectionDisabled,
  SECTION_NAMES,
} from './utils'
import ContactSection from './components/ContactSection/contactSection'
import DetailsSection from './components/DetailsSection'
import RemovalItemsSection from './components/RemovalItemsSection'
import ScheduleSection from './components/ScheduleSection'
import BillingSection from './components/BillingSection/billingSection'
import ChargeUpfrontLabel from './components/BillingSection/components/CreditCardPaymentForm/components/ChargeUpfront'

const createScrollTarget = (panel) => `section-${panel}`

class OrderAccordion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSection: 0,
      finishedSections: [],
    }

    this.scrollToSection = (panel) => {
      setTimeout(() => {
        scrollToElement(`#${createScrollTarget(panel)}`, {
          offset: -80,
          duration: 200,
        })
      }, 450)
    }

    this.generatePropsForSection = (section) => {
      const { activeSection, finishedSections } = this.state
      return {
        id: createScrollTarget(section),
        active: isSectionActive(activeSection, section),
        finished: isSectionFinished(finishedSections, section),
        disabled: isSectionDisabled(activeSection, section),
        onChange: this.handleChange(section),
      }
    }

    this.goToSection = ({ prev, next }) => {
      trackPage({ name: SECTION_NAMES[next] })
      this.setState({
        activeSection: next,
        finishedSections: uniq(this.state.finishedSections.concat(prev)).filter(
          (item) => item !== next
        ),
      })
      this.props.setCurrentSection(SECTION_NAMES[next])
      this.scrollToSection(next)
    }

    this.handleChange = (panel) => (event, expanded) => {
      trackPage({ name: SECTION_NAMES[panel] })
      this.props.setCurrentSection(SECTION_NAMES[panel])
      this.scrollToSection(panel)
      if (!expanded) return null
      this.setState({
        activeSection: expanded ? panel : panel - 1,
      })
    }
  }

  componentDidMount() {
    trackPage({ name: SECTION_NAMES[0] })
    this.props.setCurrentSection(SECTION_NAMES[0])
  }

  render() {
    const { itemTypes } = this.props
    return (
      <Fragment>
        <OrderSectionExpandableContainer
          {...this.generatePropsForSection(0)}
          disabled={false}
          summary="Please select your items for removal"
        >
          <RemovalItemsSection
            itemTypes={itemTypes}
            onFinishSection={() => this.goToSection({ prev: 0, next: 1 })}
          />
        </OrderSectionExpandableContainer>
        <OrderSectionExpandableContainer
          {...this.generatePropsForSection(1)}
          summary="When would you like your items picked up?"
        >
          <ScheduleSection
            onFinishSection={() => this.goToSection({ prev: 1, next: 2 })}
          />
        </OrderSectionExpandableContainer>
        <OrderSectionExpandableContainer
          {...this.generatePropsForSection(2)}
          summary="Order Details"
        >
          <DetailsSection
            onFinishSection={() => this.goToSection({ prev: 2, next: 3 })}
          />
        </OrderSectionExpandableContainer>
        <OrderSectionExpandableContainer
          {...this.generatePropsForSection(3)}
          summary="Contact Information"
        >
          <ContactSection
            onFinishSection={() => this.goToSection({ prev: 3, next: 4 })}
          />
        </OrderSectionExpandableContainer>
        <OrderSectionExpandableContainer
          {...this.generatePropsForSection(4)}
          summary="Billing Information"
          subtext={<ChargeUpfrontLabel />}
        >
          <BillingSection />
        </OrderSectionExpandableContainer>
      </Fragment>
    )
  }
}

OrderAccordion.propTypes = {
  itemTypes: PropTypes.arrayOf(ItemTypePropType).isRequired,
  setCurrentSection: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  setCurrentSection: ACTIONS.setCurrentSection,
}

export default connect(null, mapDispatchToProps)(OrderAccordion)

import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import styles from './LeftIconButton.module.scss'

const LeftIconButton = ({ className, iconSource, title, ...rest }) => (
  <button
    className={`${styles.btnSuccess} ${className} btn row center-xs`}
    {...rest}
  >
    <ReactSVG
      className={styles.icon}
      src={iconSource}
      style={{ width: 30, height: 30 }}
    />
    <span>{title}</span>
  </button>
)

LeftIconButton.propTypes = {
  className: PropTypes.string,
  iconSource: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

LeftIconButton.defaultProps = {
  className: '',
}

export default LeftIconButton

import moment from 'moment-timezone'

export const DATE_FORMAT = 'YYYY-MM-DD'
const ISO_WEEKEND_CODES = [6, 7]

export const addWeekdays = (date, days) => {
  let cloneDate = moment(date)
  while (days > 0) {
    cloneDate = cloneDate.add(1, 'days')
    const isWeekday = !ISO_WEEKEND_CODES.includes(cloneDate.isoWeekday())

    if (isWeekday) {
      days -= 1
    }
  }
  return cloneDate
}

export const easternMoment = (date = undefined) =>
  moment(date).tz('America/New_York')

export const isBeforeToday = (date) =>
  easternMoment(date).isBefore(easternMoment())

export const isToday = (date) =>
  easternMoment(date).isSame(easternMoment(), 'day')

export const isOutsideXDaysExclusive = (daysFromNow) => (date) =>
  easternMoment(date).isAfter(easternMoment().add(daysFromNow, 'days'))

export const getMinAndMaxDateRange = () => ({
  minDate: easternMoment()
    .subtract(10, 'days')
    .format(DATE_FORMAT),
  maxDate: easternMoment()
    .add(90, 'days')
    .format(DATE_FORMAT),
})

export const isLastDayOfMonth = () => {
  const today = easternMoment().format(DATE_FORMAT)
  const lastDayOfMonth = easternMoment()
    .endOf('month')
    .format(DATE_FORMAT)
  return today === lastDayOfMonth
}

export const makeGetFirstAvailableDate = (disabledDates) => () => {
  const isDayBlocked = makeIsDayBlocked(disabledDates)
  /**
   * Make a collection of days that has approximately
   * a months worth of dates. It's unlikely we'd have a contiguous
   * block of excluded dates bigger than this.
   */
  const nextMonthOfDays = Array.from({ length: 30 }).map((_, index) =>
    easternMoment().add(index + 1, 'days')
  )

  /**
   * Grab the first valid day starting from today that is not excluded
   */
  const firstAvailableDate = nextMonthOfDays.find((date) => !isDayBlocked(date))

  if (!firstAvailableDate) {
    console.error('No valid date found within 30 days')
  }

  /**
   * React Dates expects a moment object, so we need to move
   * the formatted version back into a valid moment object
   */
  return firstAvailableDate
}

export const makeIsDayBlocked = (disabledDates) => (day) =>
  disabledDates.includes(easternMoment(day).format(DATE_FORMAT)) ||
  isBeforeToday(day) ||
  isToday(day) ||
  isOutsideXDaysExclusive(90)(day)

export const isSecondToLastDayOfMonth = () => {
  const today = easternMoment().format(DATE_FORMAT)
  const secondToLastDayOfMonth = moment()
    .endOf('month')
    .subtract(1, 'days')
    .format(DATE_FORMAT)
  return today === secondToLastDayOfMonth
}

export const isAfter5Eastern = () => easternMoment().get('hour') > 16 // If the hours is greater than 4pm, then we know it's 5pm or later

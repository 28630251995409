import React from 'react'
import PropTypes from 'prop-types'
import { minus } from 'react-icons-kit/icomoon/minus'
import { plus } from 'react-icons-kit/icomoon/plus'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import ItemCounterButton from '../renderItemCounterInput/components/ItemCounterButton'

const createValue = (value) => value || 0

const renderCounterInput = ({ input, meta, min, max, subtitle }) => {
  const error = fieldIsInvalid(meta)
  const value = createValue(input.value)
  return (
    <div className="row center-xs middle-xs">
      <div className="col-xs-12 row center-xs">
        <ItemCounterButton
          className="item-counter-button-minus"
          style={{ marginTop: '10px' }}
          disabled={value <= min}
          icon={minus}
          onClick={() => input.onChange(value - 1)}
        />
        <div className="text-center col-xs-6">
          <h3
            style={{
              fontWeight: '700',
              marginBottom: '10px auto 20px !important',
            }}
          >
            {createValue(value)}
          </h3>
          <p>{subtitle}</p>
        </div>
        <ItemCounterButton
          className="item-counter-button-plus"
          style={{ marginTop: '10px' }}
          disabled={value >= max}
          icon={plus}
          onClick={() => input.onChange(value + 1)}
        />
      </div>
      <div className="col-xs-12">
        <p
          style={{
            fontSize: '1.2rem !important',
            fontStyle: 'italic',
            lineHeight: '1.5rem !important',
          }}
        >
          {error}
        </p>
      </div>
    </div>
  )
}

renderCounterInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  meta: PropTypes.any,
  subtitle: PropTypes.string.isRequired,
}

export default renderCounterInput

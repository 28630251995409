import LogRocket from 'logrocket'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { isProductionEnvironment } from '../lib/environment/environment'
import rootReducer from './rootReducer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any
  }
}

/**
 * @desc Development Redux config with DevTools enabled
 */
const developmentConfiguration = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f: any) => f
)

/**
 * @desc Deployed Redux config with DevTools disabled and Error Reporting enabled
 */
const deployedConfiguration = compose(
  applyMiddleware(LogRocket.reduxMiddleware()),
  applyMiddleware(thunk)
)

/**
 * @desc Decide which configuration to use depending on environment
 */
const configuration = isProductionEnvironment()
  ? deployedConfiguration
  : developmentConfiguration

/**
 * @desc Creates the Redux store
 */
const store = createStore(rootReducer, {}, configuration)

export default store

import intersection from 'lodash/fp/intersection'
import words from 'lodash/fp/words'

/**
 * @desc Runs a word tokenizer and matches on the intersection of all searchable and input tokens
 * @param {string} filterString
 * @param {string} inputString
 */
const isIncludedInTokenizedSearch = (filterString = '', inputString = '') => {
  const inputWords = words(inputString)
  const filterStringWords = words(filterString)
  const inter = intersection(filterStringWords, inputWords)
  return inter.length > 0
}

/**
 * @desc Create a filter function for the select box
 * @param label
 * @param input
 * @returns {boolean}
 */
export const filterOption = ({ data: { filterString = '' } }, input = '') => {
  const trimmedLowercaseInput = input.toLowerCase().trim()
  const trimmedLowercaseFilterString = filterString.toLowerCase().trim()
  if (trimmedLowercaseFilterString.includes('unlisted')) {
    return true
  } else {
    return (
      trimmedLowercaseFilterString.includes(trimmedLowercaseInput) ||
      isIncludedInTokenizedSearch(
        trimmedLowercaseFilterString,
        trimmedLowercaseInput
      )
    )
  }
}

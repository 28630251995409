import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { navigateToHome } from '../../../../lib/navigation'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import WeCallYouDescription from './components/WeCallYouDescription'
import YouCallUsDescription from './components/YouCallUsDescription'
import withUnrecoverableErrorModalData from './components/withUnrecoverableErrorModalData'
import { removeUnloadEventListener } from '../../../../components/hocs/withBackButtonProtection'

const UnrecoverableErrorModal = ({
  copy,
  isUnrecoverableErrorModalVisible,
}) => (
  <CenteredModal
    open={isUnrecoverableErrorModalVisible}
    onClose={() => {
      removeUnloadEventListener()
      navigateToHome()
    }}
  >
    <div className="col-xs-12 center-xs">
      <h3 style={{ color: '#DC2E48', marginTop: 30 }}>
        This is what the kids call... technical difficulties.
      </h3>
      <p style={{ margin: '5px auto' }}>{copy}</p>
      <YouCallUsDescription />
      <h4 style={{ margin: '5px auto' }}>Or</h4>
      <WeCallYouDescription />
    </div>
  </CenteredModal>
)

UnrecoverableErrorModal.propTypes = {
  copy: PropTypes.string.isRequired,
  isUnrecoverableErrorModalVisible: PropTypes.bool.isRequired,
}

export default compose(withUnrecoverableErrorModalData)(UnrecoverableErrorModal)

import React from 'react'

const SuccessBanner = () => (
  <div
    className="bg-success row center-xs"
    data-tid="retail-order-success-57nvhd"
  >
    <h1 style={{ fontSize: '2em', fontWeight: 'bold' }} className="text-white">
      Thank you for your booking
    </h1>
  </div>
)

export default SuccessBanner

/**
 * @desc Checks if an item can be disassembled
 * @param disassemblyAllowed
 * @param pickupCount
 */
const isDisassemblable = ({ disassemblyAllowed, pickupCount }) =>
  disassemblyAllowed && pickupCount > 0

/**
 * @desc Filters for only items that are disassemblable and are being picked up
 * @param items
 */
export const getDisassemblableItems = (items = []) =>
  items.filter(isDisassemblable)

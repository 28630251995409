import React, { PureComponent } from 'react'

export default class NortonSeal extends PureComponent {
  handleContextMenu() {
    return false
  }

  render() {
    return (
      <div>
        <table
          width="135"
          border="0"
          cellPadding="2"
          cellSpacing="0"
          title="Click to Verify - This site chose Symantec SSL for secure e-commerce and confidential communications."
        >
          <tbody>
            <tr>
              <td width="135" align="center" valign="top">
                <script
                  type="text/javascript"
                  src="https://seal.websecurity.norton.com/getseal?host_name=goloadup.com&amp;size=M&amp;use_flash=NO&amp;use_transparent=Yes&amp;lang=en"
                />
                <img
                  name="seal"
                  src="https://seal.websecurity.norton.com/getseal?at=0&amp;sealid=1&amp;dn=goloadup.com&amp;lang=en&amp;tpt=transparent"
                  onContextMenu={this.handleContextMenu}
                  border="0"
                  useMap="#sealmap_medium"
                  alt=""
                />
                <map name="sealmap_medium" id="sealmap_medium">
                  {/* eslint-disable-next-line no-script-url */}
                  <area
                    alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security"
                    title=""
                    // eslint-disable-next-line no-script-url
                    href="javascript:vrsn_splash()"
                    shape="rect"
                    coords="0,0,115,58"
                    tabIndex="-1"
                    style={{ outline: 'none' }}
                  />
                  {/* eslint-disable-next-line no-script-url */}
                  <area
                    alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security"
                    title=""
                    // eslint-disable-next-line no-script-url
                    href="javascript:vrsn_splash()"
                    shape="rect"
                    coords="0,58,63,81"
                    tabIndex="-1"
                    style={{ outline: 'none' }}
                  />
                  {/* eslint-disable-next-line no-script-url */}
                  <area
                    alt=""
                    title=""
                    // eslint-disable-next-line no-script-url
                    href="javascript:symcBuySSL()"
                    shape="rect"
                    coords="63,58,115,81"
                    style={{ outline: 'none' }}
                  />
                </map>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

import { connect } from 'react-redux'
import { ACTIONS, SELECTORS } from '../../../../../../store/ducks/modals'

export default connect(
  (state) => ({
    copy: SELECTORS.getCopy(state),
    isUnrecoverableErrorModalVisible: SELECTORS.isUnrecoverableErrorModalVisible(
      state
    ),
  }),
  {
    toggleUnrecoverableErrorModal: ACTIONS.toggleUnrecoverableErrorModal,
  }
)

import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import { useSelector } from 'react-redux'
import { query } from '../../../../../../components/hocs/withPricingData'
import { getPricingVariables } from '../../../../../../lib/forms/getPricingVariablesFromForm'
import { SELECTORS as pricingDetailsSelectors } from '../../../../../../store/ducks/pricingDetails'
import AssemblyExplainer from './components/AssemblyExplainer'
import BasePriceExplainer from './components/BasePriceExplainer'
import DisassemblyExplainer from './components/DisassemblyExplainer'
import DiscountsExplainer from './components/DiscountsExplainer'
import ExtrasExplainer from './components/ExtrasExplainer'
import GuaranteedPriceExplainer from './components/GuaranteedPriceExplainer/index'
import PromoCodeExplainer from './components/PromoCodeExplainer'
import RemovalExplainer from './components/RemovalExplainer'
import TotalExplainer from './components/TotalExplainer'
import {
  getAssemblyItems,
  getBasePrice,
  getDisassemblyItems,
  getDiscountsApplied,
  getExtrasInfo,
  getPromoVariables,
  getRemovalItems,
  getTotalPrice,
  shouldUseMinimumPrice,
} from './utils'

const PricingBreakdown = () => {
  const isPromoCodeValid = useSelector(pricingDetailsSelectors.isPromoCodeValid)

  return (
    <Query
      query={query}
      variables={{ inputs: getPricingVariables({}, isPromoCodeValid) }}
      fetchPolicy="cache-first"
    >
      {({ data, error, loading }) => {
        if (error) return null
        if (loading) return null

        return (
          <Fragment>
            <BasePriceExplainer basePrice={getBasePrice(data.pricingDetails)} />
            <RemovalExplainer data={getRemovalItems(data.pricingDetails)} />
            <DisassemblyExplainer
              data={getDisassemblyItems(data.pricingDetails)}
            />
            <AssemblyExplainer data={getAssemblyItems(data.pricingDetails)} />
            <ExtrasExplainer data={getExtrasInfo(data.pricingDetails)} />
            <DiscountsExplainer
              data={getDiscountsApplied(data.pricingDetails)}
            />
            <PromoCodeExplainer {...getPromoVariables(data.pricingDetails)} />
            <TotalExplainer
              minimumPrice={shouldUseMinimumPrice(data.pricingDetails)}
              total={getTotalPrice(data.pricingDetails)}
            />
            <GuaranteedPriceExplainer />
          </Fragment>
        )
      }}
    </Query>
  )
}

export default PricingBreakdown

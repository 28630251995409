import React from 'react'
import PropTypes from 'prop-types'
import ContactListItem from './components/ContactListItem/contactListItem'
import styles from './OrderContactInfo.module.scss'

const OrderContactInfo = ({ order }) => {
  const { email, firstName, lastName, primaryPhone, secondaryPhone } = order

  return (
    <div className={styles.card}>
      <h3 className={styles.cardTitle}>On-site Contact</h3>
      <ContactListItem label="Name" value={`${firstName} ${lastName}`} />
      <ContactListItem label="Phone" value={primaryPhone} />
      <ContactListItem label="Secondary Phone" value={secondaryPhone} />
      <ContactListItem label="Email" value={email} />
    </div>
  )
}

OrderContactInfo.propTypes = {
  order: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    primaryPhone: PropTypes.string,
    secondaryPhone: PropTypes.string,
  }).isRequired,
}

export default OrderContactInfo

import React from 'react'
import PropTypes from 'prop-types'
import OptInSwitch from '../../../../../../../../../../components/ui/touchables/OptInSwitch'

const OptableTrigger = ({ active, content, subcontent, onClick }) => (
  <div className="row col-xs-12 middle-xs" style={{ width: '100%' }}>
    <h5 className="col-xs-8">
      {content}
      <span className="subtext">{subcontent}</span>
    </h5>
    <OptInSwitch active={active} onClick={onClick} />
  </div>
)

OptableTrigger.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  subcontent: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

OptableTrigger.defaultProps = {
  active: false,
}

export default OptableTrigger

import * as React from 'react'
import { BeatLoader } from 'react-spinners'
import get from 'lodash/fp/get'
import { makeJsonRequest } from '../../../../../lib/makeRequest'
import styles from './ConfirmNewZipForm.module.css'

interface IZipResponse {
  available: boolean
  zip: string
}

interface IProps {
  className?: string
  onCompleted: (response: IZipResponse) => void
}

const getZipValidationUrl = (zipCode: string) =>
  `${process.env.REACT_APP_RETAIL_ZIP_VALIDATION_URL}?zip=${zipCode}`

const ConfirmNewZipForm = ({ className, onCompleted }: IProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [zip, setZip] = React.useState('')

  const handleZipChange = React.useCallback(
    async (zipCode) => {
      const zipIsValid = zipCode && zipCode.length === 5

      if (zipIsValid) {
        setIsSubmitting(true)
        try {
          const response: IZipResponse = await makeJsonRequest({
            url: getZipValidationUrl(zipCode),
            method: 'GET',
          })

          onCompleted({ available: response.available || false, zip: zipCode })
        } catch ({ errorStatus = '', errorText = '' }) {
          const errorMessage = `${errorStatus} - ${errorText}`
          console.error(errorMessage)
        } finally {
          setIsSubmitting(false)
        }
      }
    },
    [onCompleted]
  )

  const handleZipInput = React.useCallback(
    (event) => {
      const zip = get('target.value', event) || ''
      const normalized = zip.replace(/\D/g, '')

      setZip(normalized)
      handleZipChange(normalized)
    },
    [setZip, handleZipChange]
  )

  return (
    <form
      className={`${className || ''} ${styles.container} form-group`}
      onSubmit={(event) => {
        event.preventDefault()
      }}
    >
      <div>
        <label className={styles.zipLabel} htmlFor="zipConfirmation">
          Zip Code
        </label>
        <input
          className={`form-control ${styles.zipInput}`}
          disabled={isSubmitting}
          name="zipConfirmation"
          onInput={handleZipInput}
          maxLength={5}
          type="text"
          value={zip}
        />
      </div>
      <BeatLoader size={10} loading={isSubmitting} color="#222" />
    </form>
  )
}

export default ConfirmNewZipForm

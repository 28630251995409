/**
 * @desc Creates common PropTypes used throughout the application
 * @desc Some of these PropType shapes map directly to GraphQL Types to enforce the contract
 */

import PropTypes from 'prop-types'

// Primitives
const BOOLEAN = PropTypes.bool
const ID = PropTypes.oneOfType([PropTypes.number, PropTypes.string])
const NUMBER = PropTypes.number
const STRING = PropTypes.string

/**
 * @desc GraphQL ItemType
 */
export const ItemTypePropType = PropTypes.shape({
  aliases: PropTypes.arrayOf(STRING),
  assemblyAllowed: BOOLEAN,
  assemblyPrice: NUMBER,
  disassemblyAllowed: BOOLEAN,
  disassemblyPrice: NUMBER,
  icon: STRING,
  id: ID,
  marquee: BOOLEAN,
  name: STRING,
  pickupAllowed: BOOLEAN,
  pickupPrice: NUMBER,
  slug: STRING,
  subtext: STRING,
})

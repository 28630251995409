import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ActionLink from '../../../../../../components/ui/typography/ActionLink'
import styles from './TotalPriceLabel.module.scss'
import { toTwoDecimals } from '../../../../../../lib/math'

const TotalPriceLabel = ({ clickable, onClick, total }) => (
  <div className={`row hidden-sm center-xs ${styles.bottomFixed}`}>
    <h4 className={`col-xs-12 ${styles.orderTitle}`}>
      Guaranteed Price<sup>*</sup>
      <span
        style={{ marginLeft: '20px' }}
        data-tid="retail-displayed-price-97b8a07c"
      >
        ${toTwoDecimals(total)}
      </span>
    </h4>
    <div className="col-xs-12">
      {clickable && total && (
        <Fragment>
          <h5>
            <ActionLink
              id="view-pricing-breakdown-link"
              onClick={onClick}
              content="View Pricing Breakdown"
              style={{ color: '#fff' }}
            />
          </h5>
        </Fragment>
      )}
    </div>
  </div>
)

TotalPriceLabel.propTypes = {
  clickable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TotalPriceLabel

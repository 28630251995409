import React from 'react'
import PropTypes from 'prop-types'
import { mapDiscountTypeToName } from './utils'
import { toTwoDecimals } from '../../../../../../../../lib/math'

const DiscountAppliedRow = ({ item }) => (
  <div className="row col-xs-12 text-danger">
    <div
      className="col-sm-8 col-xs-12"
      style={{ padding: '0', fontSize: '1.4rem' }}
    >
      {mapDiscountTypeToName(item)}
    </div>
    <div
      className="col-sm-4 col-xs-12 end-sm"
      style={{ padding: '0', fontSize: '1.4rem' }}
    >
      -${toTwoDecimals(item.subtotal)}
    </div>
  </div>
)

DiscountAppliedRow.propTypes = {
  item: PropTypes.object.isRequired,
}

export default DiscountAppliedRow

import React from 'react'
import PropTypes from 'prop-types'
import { minus } from 'react-icons-kit/icomoon/minus'
import { plus } from 'react-icons-kit/icomoon/plus'
import ICONS from '../../../../../../assets/icons'
import colors from '../../../../../../styles'
import { ItemTypePropType } from '../../../../../../lib/types'
import ItemCounterButton from '../ItemCounterButton'
import styles from './ItemCounterRow.module.scss'

const border = `1px solid ${colors.colors.light0}`

const ItemCounterRow = ({
  count,
  item,
  last,
  onAdd,
  onSubtract,
  addDisabled,
  subtractDisabled,
}) => (
  <div
    className={`row middle-xs ${styles.itemRow}`}
    style={{ border, borderBottom: last ? border : null }}
  >
    <div className="col-sm-2">
      <img
        src={ICONS[item.itemType.icon]}
        alt={`LoadUp ${item.itemType.name}`}
        className={styles.img}
      />
    </div>
    <div className="col-sm-6 col-md-6 col-xs-5 col-md-offset-1 start-xs">
      <div className={styles.label}>
        {item.itemType.name}
        <p className={styles.subText}>{item.itemType.subtext}</p>
      </div>
    </div>
    <div className="col-sm-4 col-md-3 col-xs-5 row middle-xs center-xs">
      <ItemCounterButton
        disabled={subtractDisabled}
        icon={minus}
        onClick={onSubtract}
      />
      <p style={{ margin: '0 10px' }}>{count || 0}</p>
      <ItemCounterButton disabled={addDisabled} icon={plus} onClick={onAdd} />
    </div>
  </div>
)

ItemCounterRow.propTypes = {
  addDisabled: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  item: ItemTypePropType.isRequired,
  last: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  subtractDisabled: PropTypes.bool.isRequired,
}

export default ItemCounterRow

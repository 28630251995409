import { gql } from 'apollo-boost'

export const CREATE_ORDER = gql`
  mutation CreateOrder($inputs: CreateOrderInputs!) {
    createOrder(inputs: $inputs) {
      errorCode
      order {
        addressline1
        city
        customer {
          id
          firstName
          lastName
          email
          customerOrganizationId
          primaryPhone
          secondaryPhone
        }
        orderEntries {
          itemType {
            slug
            name
            detail
            assemblyPrice
            disassemblyPrice
            pickupPrice
          }
          assemblyCount
          disassemblyCount
          pickupCount
        }
        date
        elevatorRestriction
        email
        firstName
        flightsOfStairsCount
        id
        lastName
        latitude
        longitude
        placement
        primaryPhone
        promoCode
        refundTotal
        secondaryPhone
        specialInstructions
        state
        timeslot
        transactionTotal
        unitNumber
        zip
      }
    }
  }
`

export const trackFacebookConversion = ({ total }) => {
  if (!window.fbq) {
    console.log('Facebook Pixel not loaded during conversion')
    return
  }
  window.fbq('track', 'Purchase', {
    currency: 'USD',
    value: total,
  })
}

import { connect } from 'react-redux'
import { ACTIONS, SELECTORS } from '../../../../../../store/ducks/modals'

export default connect(
  (state) => ({
    isOutOfServiceModalVisible: SELECTORS.isOutOfServiceModalVisible(state),
  }),
  {
    toggleOutOfServiceModal: ACTIONS.toggleOutOfServiceModal,
  }
)

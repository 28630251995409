export function makeJsonRequest<ResponseShape>({
  url = '',
  method = 'GET',
}): Promise<ResponseShape> {
  const request = new XMLHttpRequest()
  return new Promise((resolve, reject) => {
    request.onreadystatechange = () => {
      // readyState of 4 means it is not yet complete
      if (request.readyState !== 4) {
        // eslint-disable-next-line no-useless-return
        return
      }

      if (request.status >= 200 && request.status < 300) {
        resolve(JSON.parse(request.responseText))
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          errorStatus: request.status,
          errorText: request.statusText,
        })
      }
    }

    request.open(method, url, true)
    request.setRequestHeader('Content-Type', 'application/json')
    request.send()
  })
}

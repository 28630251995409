import React from 'react'
import { gql } from 'apollo-boost'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withApollo } from 'react-apollo'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { ACTIONS } from '../../../../store/ducks/pageNavigation'
import { getMinAndMaxDateRange } from '../../../../lib/dates/availableDates'
import renderTextInput from '../../../../components/ui/forms/renderTextInput'
import withInvalidZipAttemptsData from '../../../NewOrder/components/InvalidZipAttemptsModal/components/withInvalidZipAttemptsData'
import withOutOfServiceModalData from '../../../NewOrder/components/ZipCodeOutOfServiceModal/components/withOutOfServiceModalData'
import withSyncFormErrors from '../../../../components/hocs/withSyncFormErrors'
import withUnrecoverableErrorModalData from '../../../NewOrder/components/UnrecoverableErrorModal/components/withUnrecoverableErrorModalData'
import { onlyNumbers } from '../../../../lib/forms/normalizers'
import GoButton from './components/GoButton'
import { isValidZipCode, isZipCodeInService } from './utils'
import { MODALS, trackViewModal } from '../../../../lib/tracking'
import { logRocketTrack } from '../../../../lib/tracking/logrocket/index'
import { trackError } from '../../../../lib/errors/specificErrors'

const INVALID_ZIP_MESSAGE = 'Please enter a valid Zip Code'

const query = gql`
  query ServiceAvailability(
    $serviceAvailabilityInputs: ServiceAvailabilityInputs!
    $excludedDatesInputs: ExcludedDatesInputs!
  ) {
    serviceAvailability(inputs: $serviceAvailabilityInputs) {
      inService
      validZip
    }
    excludedDates(inputs: $excludedDatesInputs)
  }
`

const ZipCodeForm = ({ handleSubmit, invalid, error, submitting }) => (
  <form className="row col-xs-12" onSubmit={handleSubmit}>
    {error && <p className="col-xs-12 text-danger">{error}</p>}
    <Field
      autoFocus
      className="col-xs-12 center-xs"
      name="zipcode"
      component={renderTextInput}
      placeholder="Enter Zip Code"
      type="text"
      maxLength={5}
      normalize={onlyNumbers}
      readOnly={submitting}
    />
    <GoButton disabled={invalid || submitting} />
  </form>
)

ZipCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
}

const validate = (values) => ({
  zipcode:
    values.zipcode && values.zipcode.length === 5
      ? null
      : '5-digit zip code is required',
})

const withForm = reduxForm({
  form: 'zipCodeForm',
  validate,
  destroyOnUnmount: false,
  onSubmit: async (values, dispatch, props) => {
    try {
      const {
        data: { serviceAvailability },
      } = await props.client.query({
        query,
        variables: {
          serviceAvailabilityInputs: { zip: values.zipcode },
          excludedDatesInputs: {
            zip: values.zipcode,
            orderSessionUuid: props.sessionIdentifier,
            ...getMinAndMaxDateRange(),
          },
        },
      })

      logRocketTrack(`entered_zip_code:${values.zipcode}`)

      if (!isValidZipCode(serviceAvailability)) {
        // Zip Code is actually invalid
        props.setInvalidZipAttempts(props.invalidZipAttempts + 1)
        throw new Error(INVALID_ZIP_MESSAGE)
      } else if (!isZipCodeInService(serviceAvailability)) {
        // Zip Code isn't in service area
        props.toggleOutOfServiceModal(true)
        trackViewModal({
          name: MODALS.OUT_OF_SERVICE,
          properties: { zip: values.zipcode },
        })
      } else {
        // Zip code is valid and in service
        dispatch(ACTIONS.setZipCodeSuccess(values.zipcode))
      }
    } catch (error) {
      switch (error.message) {
        case INVALID_ZIP_MESSAGE:
          // Zip Code is invalid
          throw new SubmissionError({ _error: error.message })
        default:
          // Anything else
          trackError(error)
          props.toggleUnrecoverableErrorModal(true)
          trackViewModal({
            name: MODALS.UNRECOVERABLE_ERROR,
            properties: { error },
          })
      }
    }
  },
})

export default compose(
  withApollo,
  withOutOfServiceModalData,
  withInvalidZipAttemptsData,
  withUnrecoverableErrorModalData,
  withForm,
  withSyncFormErrors('zipCodeForm')
)(ZipCodeForm)

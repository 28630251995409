import React from 'react'

// eslint-disable-next-line react/display-name
export default () => (
  <img
    style={{
      borderStyle: 'none',
      opacity: '0',
      position: 'absolute',
      height: '1px',
      width: '1px',
    }}
    alt=""
    src={`//insight.adsrvr.org/track/conv/?adv=9t5smg4&ct=0:ybc63h0&fmt=3&v=123.45&vf=USD"`}
  />
)

import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import { isRequired } from '../../../../../../../../lib/forms/formValidators'
import renderSelectableExplainerInput from '../../../../../../../../components/ui/forms/renderSelectableExplainerInput'

const OPTIONS = [
  {
    content:
      'Your Loaders will remove and transport your item(s) from inside your home.',
    subtitle: null,
    title: 'In-home pick up',
    value: 'INDOOR',
  },
  {
    content:
      'Your Loaders will pickup your item(s) from outside your home or building. You are responsible for placing your item(s) outdoors and are not required to be present during the pickup.',
    subtitle: '$5 Discount',
    title: 'Outdoor pick up',
    value: 'OUTDOOR',
  },
]

const PickupLocationForm = () => (
  <Fragment>
    <Field
      name="placement"
      component={renderSelectableExplainerInput}
      options={OPTIONS}
    />
  </Fragment>
)

PickupLocationForm.propTypes = {}

const validate = ({ placement }) => ({
  placement: isRequired(placement),
})

const withForm = reduxForm({
  validate,
  form: 'pickupLocationForm',
  destroyOnUnmount: false,
})

export default compose(withForm)(PickupLocationForm)

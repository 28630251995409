import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../../../../../styles'

const ExplainerHeading = ({ content, subcontent }) => (
  <div
    className="col-xs-12"
    style={{
      borderBottom: `2px solid #ccc`,
      padding: '5px',
      margin: '10px auto 2px',
    }}
  >
    <p style={{ fontSize: '1.5rem', fontWeight: '800', lineHeight: '1rem' }}>
      {content}
    </p>
    {subcontent && (
      <p className="subtext" style={{ color: styles.colors.dark4 }}>
        {subcontent}
      </p>
    )}
  </div>
)

ExplainerHeading.propTypes = {
  content: PropTypes.any.isRequired,
  subcontent: PropTypes.any,
}

export default ExplainerHeading

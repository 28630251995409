import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from 'react-icons-kit'
import { checkCircle } from 'react-icons-kit/feather/checkCircle'
import { useSelector } from 'react-redux'
import { injectStripe } from 'react-stripe-elements'
import { compose } from 'recompose'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import withStripe from '../../../../../../../../components/hocs/withStripe'
import withStripeElements from '../../../../../../../../components/hocs/withStripeElements'
import withSyncFormErrors from '../../../../../../../../components/hocs/withSyncFormErrors'
import renderCheckboxInput from '../../../../../../../../components/ui/forms/renderCheckboxInput'
import renderCreditCardCVCInput from '../../../../../../../../components/ui/forms/renderCreditCardCVCInput'
import renderCreditCardExpiryInput from '../../../../../../../../components/ui/forms/renderCreditCardExpiryInput'
import renderCreditCardNumberInput from '../../../../../../../../components/ui/forms/renderCreditCardNumberInput'
import renderCreditCardPostalCodeInput from '../../../../../../../../components/ui/forms/renderCreditCardPostalCodeInput'
import { isRequired } from '../../../../../../../../lib/forms/formValidators'
import getCreditCardFormValidity from '../../../../../../../../lib/forms/getCreditCardFormValidity'
import LoadUpStyles from '../../../../../../../../styles'
import CreatingOrderLoadingModal from '../../../../../CreatingOrderLoadingModal'
import PaypalButtons from '../../../../../PaypalButtons'
import DisclaimerLabel from './components/DisclaimerLabel/index'
import PlaceOrderButton from './components/PlaceOrderButton'
import StripeBadge from './components/StripeBadge'
import { SELECTORS as sessionDataSelectors } from '../../../../../../../../store/ducks/session'
import { paypalSubmit, stripeSubmit } from './utils'

const ELEMENTS = {
  CARD_NUMBER: 'creditCardNumber',
  EXPIRATION: 'creditCardExpiry',
  CARD_CVC: 'creditCardCVC',
  CARD_POSTAL_CODE: 'creditCardPostalCode',
}

function CreditCardPaymentForm(props) {
  const {
    change,
    handleSubmit,
    invalid,
    pristine,
    submitting,
    syncErrors,
    validate,
  } = props

  const {
    creditCardNumber,
    creditCardExpiry,
    creditCardCVC,
    creditCardPostalCode,
  } = syncErrors

  const [submitted, setSubmitted] = React.useState(false)

  const attemptSubmit = React.useCallback(() => {
    setSubmitted(true)
  }, [setSubmitted])

  const usingPaypal = useSelector((state) =>
    formValueSelector('creditCardPaymentForm')(state, 'usePaypal')
  )

  const sessionIdentifier = useSelector(
    sessionDataSelectors.getSessionIdentifier
  )
  const [focusedElement, changeFocusedElement] = React.useState(null)

  const isFocused = React.useMemo(
    () => (element) => focusedElement === element,
    [focusedElement]
  )

  React.useEffect(() => {
    setTimeout(validate, 1000)
    return () => {
      clearTimeout(validate)
    }
  }, [validate])

  const setUsePaypal = React.useCallback(() => {
    change('usePaypal', true)
  }, [change])

  const setPaypalOrderId = React.useCallback(
    (paypalOrderId) => {
      change('paypalOrderId', paypalOrderId)
    },
    [change]
  )

  return (
    <form
      onSubmit={handleSubmit}
      className="row col-xs-12"
      style={{ width: '100%' }}
    >
      {!usingPaypal && (
        <>
          <PaypalButtons
            onApprove={setPaypalOrderId}
            setUsePaypal={setUsePaypal}
            sessionIdentifier={sessionIdentifier}
          />
          <div
            className="col-xs-12"
            style={{
              color: LoadUpStyles.colors.green0,
              display: 'flex',
              fontSize: '1.5rem',
              fontWeight: 'bold',
              justifyContent: 'center',
              margin: '2rem 0 1rem',
              width: '100%',
            }}
          >
            OR
          </div>
          <Field
            onFocus={() => changeFocusedElement(ELEMENTS.CARD_NUMBER)}
            name={ELEMENTS.CARD_NUMBER}
            label="Credit Card Number"
            component={renderCreditCardNumberInput}
            className="col-sm-6 col-xs-12"
            externalError={
              !isFocused(ELEMENTS.CARD_NUMBER) && submitted && creditCardNumber
            }
          />
          <Field
            onFocus={() => changeFocusedElement(ELEMENTS.EXPIRATION)}
            label="Expiration"
            name={ELEMENTS.EXPIRATION}
            className="col-sm-6 col-xs-12"
            component={renderCreditCardExpiryInput}
            externalError={
              !isFocused(ELEMENTS.EXPIRATION) && submitted && creditCardExpiry
            }
          />
          <Field
            onFocus={() => changeFocusedElement(ELEMENTS.CARD_CVC)}
            label="CVC"
            name={ELEMENTS.CARD_CVC}
            className="col-sm-6 col-xs-12"
            component={renderCreditCardCVCInput}
            externalError={
              !isFocused(ELEMENTS.CARD_CVC) && submitted && creditCardCVC
            }
          />
          <Field
            onFocus={() => changeFocusedElement(ELEMENTS.CARD_POSTAL_CODE)}
            label="Zip Code"
            name="creditCardPostalCode"
            className="col-sm-6 col-xs-12"
            component={renderCreditCardPostalCodeInput}
            externalError={
              !isFocused(ELEMENTS.CARD_POSTAL_CODE) &&
              submitted &&
              creditCardPostalCode
            }
          />
        </>
      )}
      {usingPaypal && (
        <>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <h3
              style={{
                fontSize: '2.2rem',
                fontWeight: 'bold',
                marginRight: '0.4em',
              }}
            >
              Using Paypal
            </h3>
            <Icon
              icon={checkCircle}
              size={30}
              style={{
                color: '#8ac646',
                marginLeft: '0.4em',
              }}
            />
          </div>
          <h4
            style={{
              textAlign: 'center',
              fontWeight: 'normal',
              margin: '0.8rem auto 0',
              width: '80%',
            }}
          >
            Please finish your order by agreeing to the terms and clicking the{' '}
            <strong>Place Order</strong> button
          </h4>
        </>
      )}
      <Field component="input" name="usePaypal" type="checkbox" hidden />
      <Field component="input" name="paypalOrderId" type="text" hidden />

      <StripeBadge style={{ margin: '0.8rem 0.5rem 0 auto' }} />

      <Field
        component={renderCheckboxInput}
        className="col-xs-12"
        name="tosConfirmation"
        label={<DisclaimerLabel />}
        props={{
          dataTid: 'disclaimer-checkbox-2613629564',
        }}
      />

      <PlaceOrderButton
        disabled={invalid || pristine || submitting}
        submitting={submitting}
        onClick={attemptSubmit}
      />

      <CreatingOrderLoadingModal isVisible={submitting} />
    </form>
  )
}

CreditCardPaymentForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  syncErrors: PropTypes.object.isRequired,
  usingPaypal: PropTypes.bool,
  validate: PropTypes.any,
}

const validate = (values = {}) => {
  if (values.usePaypal) {
    return {
      tosConfirmation: isRequired(values.tosConfirmation),
    }
  }
  return {
    creditCardNumber: getCreditCardFormValidity(values.creditCardNumber),
    creditCardExpiry: getCreditCardFormValidity(values.creditCardExpiry),
    creditCardCVC: getCreditCardFormValidity(values.creditCardCVC),
    creditCardPostalCode: getCreditCardFormValidity(
      values.creditCardPostalCode
    ),
    tosConfirmation: isRequired(values.tosConfirmation),
  }
}

const withForm = reduxForm({
  validate,
  form: 'creditCardPaymentForm',
  onSubmit: async (values, dispatch, props) => {
    if (values.usePaypal) {
      await paypalSubmit(dispatch, props)
    } else {
      await stripeSubmit(dispatch, props)
    }
  },
})

export default compose(
  withStripe,
  withStripeElements,
  injectStripe,
  withForm,
  withSyncFormErrors('creditCardPaymentForm')
)(CreditCardPaymentForm)

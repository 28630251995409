import React from 'react'
import PropTypes from 'prop-types'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import styles from './RenderTextInput.module.scss'

const renderTextInput = ({
  className,
  externalError,
  input,
  label,
  meta,
  readOnly,
  type,
  ...rest
}) => {
  const error = fieldIsInvalid(meta, externalError)
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <input
        type={type}
        className={`${error && 'is-invalid'} ${styles.input}`}
        readOnly={readOnly}
        {...input}
        {...rest}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  )
}

renderTextInput.propTypes = {
  className: PropTypes.string,
  externalError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
}

renderTextInput.defaultProps = {
  className: '',
  type: 'text',
}

export default renderTextInput

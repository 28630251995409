import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'react-icons-kit'
import styles from './ItemCounterButton.module.scss'

const ItemCounterButton = ({ icon, className, onClick, style, ...rest }) => (
  <button
    {...rest}
    className={`${styles.itemCounterBtn} ${className}`}
    onClick={onClick}
    style={{
      ...style,
    }}
    type="button"
  >
    <Icon icon={icon} />
  </button>
)

ItemCounterButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.any,
}

export default ItemCounterButton

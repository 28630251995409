import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import renderDisassemblyItemCounterInput from '../../../../../../../../components/ui/forms/renderDisassemblyItemCounterInput'

const DisassemblyItemsForm = ({ items }) => (
  <div className="col-xs-12" style={{ width: '100%' }}>
    <Field
      name="disassemblyItems"
      component={renderDisassemblyItemCounterInput}
      options={items}
    />
  </div>
)

DisassemblyItemsForm.propTypes = {
  items: PropTypes.array.isRequired,
}

DisassemblyItemsForm.defaultProps = {
  items: [],
}

const withForm = reduxForm({
  form: 'disassemblyItemsForm',
  destroyOnUnmount: false,
})

export default compose(withForm)(DisassemblyItemsForm)

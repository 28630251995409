import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../../../styles'
import { toTwoDecimals } from '../../../../../../lib/math'

const borderStyle = `6px solid ${styles.colors.dark3}`

const TotalPriceLabel = ({ total }) => (
  <div
    className="row center-xs middle-xs"
    style={{
      borderTop: borderStyle,
      borderBottom: borderStyle,
      padding: '5px 0',
    }}
  >
    <p className="col-xs-6" style={{ fontSize: '1.2rem', fontWeight: '900' }}>
      Guaranteed Price<sup>*</sup>
    </p>
    <p
      className="col-xs-6 start-xs"
      style={{ fontSize: '1.5rem', fontWeight: '900' }}
      data-tid="retail-displayed-price-73h4e"
    >
      ${toTwoDecimals(total)}
    </p>
  </div>
)

TotalPriceLabel.propTypes = {
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TotalPriceLabel

import React from 'react'
import PropTypes from 'prop-types'
import DetailsSectionExpandableContainer from '../../../../routes/NewOrder/components/OrderAccordion/components/DetailsSection/components/DetailsSectionExpandableContainer/detailsSectionExpandableContainer'

const renderExpandableSectionInput = ({ children, content, input }) => (
  <DetailsSectionExpandableContainer
    content={content}
    active={Boolean(input.value)}
    onChange={input.onChange}
  >
    {children}
  </DetailsSectionExpandableContainer>
)

renderExpandableSectionInput.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.any,
  }),
}

export default renderExpandableSectionInput

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../styles'

const ActionLink = ({ id, content, style, ...rest }) => (
  <span
    id={id}
    style={{ color: styles.colors.green0, cursor: 'pointer', ...style }}
    {...rest}
  >
    {content}
  </span>
)

ActionLink.propTypes = {
  content: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
}

ActionLink.defaultProps = {
  id: null,
  style: {
    WebkitTextFillColor: styles.colors.green0,
    WebkitOpacity: 1,
  },
}

export default ActionLink

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import ExplainerHeading from '../ExplainerHeading'
import ItemQuantityRow from '../ItemQuantityRow'
import avoidRenderingEmptyList from '../avoidRenderingEmptyList'

const RemovalExplainer = ({ data }) => (
  <Fragment>
    <ExplainerHeading content="Items for Removal" />
    {data.map((item) => (
      <ItemQuantityRow key={item.name} unitPriceKey="unitPrice" item={item} />
    ))}
  </Fragment>
)

RemovalExplainer.propTypes = {
  data: PropTypes.array.isRequired, // @TODO Use actual Shape
}

RemovalExplainer.defaultProps = {
  data: [],
}

export default compose(avoidRenderingEmptyList)(RemovalExplainer)

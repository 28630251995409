import { useCallback, useState, useEffect } from 'react'

export default function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  const handleResize = useCallback(
    function() {
      setWidth(window.innerWidth)
    },
    [setWidth]
  )

  const cleanup = useCallback(
    function() {
      window.removeEventListener('resize', handleResize)
    },
    [handleResize]
  )

  useEffect(
    function() {
      window.addEventListener('resize', handleResize)
      return cleanup
    },
    [cleanup, handleResize]
  )

  return width
}

import React from 'react'
import { Elements } from 'react-stripe-elements'

// eslint-disable-next-line react/display-name
const withStripeElements = (BaseComponent) => (props) => (
  <Elements>
    <BaseComponent {...props} />
  </Elements>
)

export default withStripeElements

import { connect } from 'react-redux'
import {
  ACTIONS,
  SELECTORS,
} from '../../../../../../../../store/ducks/orderDetailsAccordion'

export default connect(
  (state) => ({
    isDisassemblyActive: SELECTORS.getDisassemblyState(state),
    isStairsActive: SELECTORS.getStairsState(state),
  }),
  ACTIONS
)

import React from 'react'
import { selectors as pricingDataSelectors } from '../../../../store/ducks/pricingData'
import { ACTIONS } from '../../../../store/ducks/pricingDetails'
import { MODALS, trackViewModal } from '../../../../lib/tracking'
import { getTotalPrice } from '../PricingBreakdownModal/components/PricingBreakdown/utils'
import TotalPriceLabel from './components/TotalPriceLabel'
import { useSelector, useDispatch } from 'react-redux'

const PricingSummaryFooter = () => {
  const dispatch = useDispatch()

  const pricingDetails = useSelector(pricingDataSelectors.pricingData)
  const total = getTotalPrice(pricingDetails)
  const canShowBreakdownModal = total !== '--'
  return (
    <TotalPriceLabel
      clickable={canShowBreakdownModal}
      total={total}
      onClick={React.useCallback(() => {
        trackViewModal({
          name: MODALS.PRICING_BREAKDOWN,
          properties: { pricingDetails, mobile: true },
        })
        dispatch(ACTIONS.togglePricingBreakdown(true))
      }, [dispatch, pricingDetails])}
    />
  )
}

export default PricingSummaryFooter

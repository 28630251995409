import React from 'react'
import PropTypes from 'prop-types'
import { PostalCodeElement } from 'react-stripe-elements'
import createPaymentInputOptions from '../../../../lib/forms/createPaymentInputOptions'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import { denyWeirdCharacters } from '../../../../lib/forms/normalizers/index'

const renderCreditCardPostalCodeInput = ({
  className,
  externalError,
  input,
  label,
  meta,
}) => {
  const error = fieldIsInvalid(meta, externalError)
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <PostalCodeElement
        onChange={input.onChange}
        id="stripe-postal-element"
        {...createPaymentInputOptions(input)}
      />
      <div className="invalid-error">{error}</div>
    </div>
  )
}

renderCreditCardPostalCodeInput.propTypes = {
  className: PropTypes.string,
  externalError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
}

renderCreditCardPostalCodeInput.defaultProps = {
  className: '',
  normalize: denyWeirdCharacters,
}

export default renderCreditCardPostalCodeInput

import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import renderTextInput from '../../../../components/ui/forms/renderTextInput/index'
import { trackError } from '../../../../lib/errors/specificErrors'
import { isRequired } from '../../../../lib/forms/formValidators'
import { toUpper } from '../../../../lib/forms/normalizers'
import { thunks as pricingDataThunks } from '../../../../store/ducks/pricingData'
import { ACTIONS as pricingDetailsActions } from '../../../../store/ducks/pricingDetails'
import styles from './PromoCodeForm.module.scss'

const PromoCodeForm = ({
  error,
  handleSubmit,
  invalid,
  pristine,
  submitSucceeded,
  submitting,
}) => {
  const dispatch = useDispatch()
  const setInvalidPromoCode = React.useCallback(() => {
    dispatch(pricingDetailsActions.toggleValidPromoCode(false))
  }, [dispatch])

  return (
    <form
      className="row center-xs"
      onSubmit={handleSubmit}
      style={{ width: '100%', marginTop: '10px', marginBottom: '20px' }}
    >
      <Field
        name="promoCode"
        component={renderTextInput}
        className="col-xs-8 center-xs"
        placeholder="Promo Code"
        normalize={toUpper}
        onChange={setInvalidPromoCode}
      />
      <button
        className={`col-xs-4 btn ${styles.btnPromo}`}
        style={{ fontSize: '1.3rem', borderRadius: '0' }}
        type="submit"
        disabled={invalid || pristine || submitting}
      >
        Apply
      </button>
      {submitSucceeded && (
        <p className="text-success col-xs-12 center-xs">Promo Code Applied!</p>
      )}
      {error && (
        <p className="text-danger col-xs-12 center-xs">Invalid Promo Code</p>
      )}
    </form>
  )
}
PromoCodeForm.propTypes = {
  error: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const validate = ({ promoCode }) => ({
  promoCode: isRequired(promoCode),
})

const withForm = reduxForm({
  validate,
  form: 'promoCodeForm',
  onSubmit: async (values, _, props) => {
    try {
      await props.dispatch(pricingDataThunks.fetchPromoData())
    } catch (error) {
      trackError(error)
      throw new SubmissionError({ _error: error.message })
    }
  },
})

export default withForm(PromoCodeForm)

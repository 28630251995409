/**
 * @desc Use this to automatically re-render the component/fetch from cache with updates to the form
 */
import { getFormValues } from 'redux-form'
import { connect } from 'react-redux'

export const selectors = {
  formData: (state: any) => ({
    contactFormValues: getFormValues('contactForm')(state),
    disassemblyItemsFormValues: getFormValues('disassemblyItemsForm')(state),
    flightsOfStairsFormValues: getFormValues('flightsOfStairsForm')(state),
    orderFormValues: getFormValues('orderForm')(state),
    pickupLocationFormValues: getFormValues('pickupLocationForm')(state),
    promoCodeFormValues: getFormValues('promoCodeForm')(state) || null,
    removalItemsFormValues: getFormValues('removalItemsForm')(state),
    scheduleFormValues: getFormValues('scheduleForm')(state),
  }),
}

export default connect(selectors.formData)

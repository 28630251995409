import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ExplainerHeading from '../ExplainerHeading'
import { toTwoDecimals } from '../../../../../../../../lib/math'

const BasePriceExplainer = ({ basePrice }) => (
  <Fragment>
    <ExplainerHeading
      content="Area Service Fees"
      subcontent="Uncle Sam has to get paid too."
    />
    <div className="row col-xs-12">
      <div
        className="col-sm-8 col-xs-12"
        style={{ padding: '0', fontSize: '1.4rem' }}
      >
        This includes taxes, transportation, and service loading costs.
      </div>
      <div
        className="col-sm-4 end-sm col-xs-12"
        style={{ padding: '0', fontSize: '1.4rem', fontWeight: '600' }}
      >
        ${toTwoDecimals(basePrice)}
      </div>
    </div>
  </Fragment>
)

BasePriceExplainer.propTypes = {
  basePrice: PropTypes.number.isRequired,
}

export default BasePriceExplainer

import React from 'react'

const CancellationPolicy = () => (
  <div style={{ margin: '15px auto' }} className="col-xs-12">
    <h3 style={{ fontSize: '1.17em', fontWeight: '600' }}>
      Cancellation Policy
    </h3>
    <p>
      A cancellation fee of 50% applies for cancellations made less than 24
      hours in advance. A cancellation fee of 20% applies for cancellations made
      24 or more hours in advance.
    </p>
  </div>
)

export default CancellationPolicy

import React from 'react'
import PropTypes from 'prop-types'

// https://www.shareasale.com/itp/custom.htm#_Toc520969536
function shareasaleGetCookie(e) {
  var r = e + '='
  var a = decodeURIComponent(document.cookie)
  var o = a.split(';')
  for (var n = 0; n < o.length; n++) {
    var t = o[n]
    while (t.charAt(0) === ' ') {
      t = t.substring(1)
    }
    if (t.indexOf(r) === 0) {
      return t.substring(r.length, t.length)
    }
  }
  return ''
}

const sscid = shareasaleGetCookie('shareasaleSSCID')

const ShareASaleConversionTracker = ({ id, total }) => (
  <img
    alt=""
    id="_SHRSL_img_1"
    height="1"
    src={`https://shareasale.com/sale.cfm?tracking=${id}&amount=${total}&transtype=sale&merchantID=75506&sscidmode=6&sscid=${sscid}`}
    width="1"
  />
)

ShareASaleConversionTracker.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  total: PropTypes.number.isRequired,
}

export default ShareASaleConversionTracker

import React from 'react'

const OrderSummaryLabel = () => (
  <div className="row center-xs middle-xs">
    <h4 className="col-xs-12" style={{ marginBottom: 'none', padding: '5px' }}>
      Order Summary
    </h4>
  </div>
)

export default OrderSummaryLabel

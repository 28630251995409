import React from 'react'
import PropTypes from 'prop-types'
import ItemCounterRow from './components/ItemCounterRow'

const renderDisassemblyItemCounterInput = ({ input, options }) =>
  options.map((option, index) => {
    const currentValue = input.value[option.itemType.id] || 0
    return (
      <ItemCounterRow
        last={index === options.length - 1}
        key={option.itemType.id}
        subtractDisabled={currentValue < 1}
        count={input.value[option.itemType.id] || 0}
        addDisabled={currentValue >= option.pickupCount}
        item={option}
        onSubtract={() =>
          input.onChange({
            ...input.value,
            [option.itemType.id]: currentValue - 1,
          })
        }
        onAdd={() =>
          input.onChange({
            ...input.value,
            [option.itemType.id]: currentValue + 1,
          })
        }
      />
    )
  })

renderDisassemblyItemCounterInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  options: PropTypes.array.isRequired,
}

renderDisassemblyItemCounterInput.defaultProps = {
  options: [],
}

export default renderDisassemblyItemCounterInput

import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { filterOption } from './utils'
import './styles.css'

const getReturnValue = (selectedValue) => {
  // If length is undefined, then we got a proper object, else it returns an empty array of length 0
  if (selectedValue && selectedValue.length === undefined) {
    return selectedValue
  }
  return null
}

const renderSearchableAutocompleteInput = ({
  className,
  input,
  options,
  ...rest
}) => (
  <Select
    className={`${className} searchable-autocomplete-input`}
    filterOption={filterOption}
    onChange={(val) => {
      const returnValue = getReturnValue(val)
      if (returnValue) {
        input.onChange(returnValue)
      }
    }}
    options={options}
    {...rest}
  />
)

const OptionPropType = PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.string,
})

renderSearchableAutocompleteInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  options: PropTypes.arrayOf(OptionPropType).isRequired,
}

renderSearchableAutocompleteInput.defaultProps = {
  className: '',
  everpresentOptions: [],
  options: [],
}

export default renderSearchableAutocompleteInput

import React from 'react'
import get from 'lodash/fp/get'
import { useSelector, useDispatch } from 'react-redux'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import ItemRestriction from './components/ItemRestriction'
import { selectors as pricingDataSelectors } from '../../../../store/ducks/pricingData'
import {
  SELECTORS as modalSelectors,
  ACTIONS as modalActions,
} from '../../../../store/ducks/modals'

const hasRestrictions = (items = []) =>
  items.some(({ itemType: { detail } }) => detail)

const ItemRestrictionsModal = () => {
  const dispatch = useDispatch()

  const handleClose = React.useCallback(() => {
    dispatch(modalActions.toggleItemRestrictionsModal(false))
  }, [dispatch])

  const isItemRestrictionsModalVisible = useSelector(
    modalSelectors.isItemRestrictionsModalVisible
  )

  const pricingDetails = useSelector(pricingDataSelectors.pricingData)
  const items = get('items', pricingDetails) || []
  return (
    <CenteredModal
      blockScroll={false}
      open={isItemRestrictionsModalVisible}
      onClose={handleClose}
      focusTrapped
    >
      <div
        className="col-xs-12 center-xs"
        style={{ paddingBottom: 30, paddingTop: 30 }}
      >
        <h3>
          {hasRestrictions(items)
            ? 'There are some special restrictions on your item pickups!'
            : "Hooray! You don't have any special restrictions on your items."}
        </h3>
        {items.map(({ itemType }: any) => (
          <ItemRestriction key={itemType.id} {...itemType} />
        ))}
      </div>
    </CenteredModal>
  )
}

export default ItemRestrictionsModal

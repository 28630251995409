import uuidv4 from 'uuid/v4'
import { AppState } from '../../rootReducer'
import { Dispatch } from 'redux'
import { logRocketTrack } from '../../../lib/tracking/logrocket'
import { trackError } from '../../../lib/errors/specificErrors'
import { client } from '../../../components/hocs/withGraphQL'
import {
  CREATE_ORDER_SESSION,
  UPDATE_SESSION,
  updateOrderSessionData,
} from '../../../components/hocs/withOrderSessionTracking'
import { getHostSource } from '../../../lib/environment/environment'
import moment from 'moment'

const RESET_SESSION = 'loadup/session/reset'
const SET_SESSION_STATUS = 'loadup/session/setStatus'

export type SessionStatus = 'failed' | 'not_started' | 'succeeded'

export type SessionDataState = Readonly<{
  sessionIdentifier: string | null
  status: SessionStatus
}>

const initialState: SessionDataState = {
  sessionIdentifier: null,
  status: 'not_started',
}

type Action = Readonly<{
  type: string
}> &
  any

export function reducer(
  state: SessionDataState = { ...initialState },
  action: Action
): SessionDataState {
  switch (action.type) {
    case RESET_SESSION: {
      return {
        ...state,
        sessionIdentifier: uuidv4(),
        status: 'not_started',
      }
    }
    case SET_SESSION_STATUS: {
      return {
        ...state,
        status: action.status,
      }
    }
    default:
      return state
  }
}

export const ACTIONS = {
  resetSession: () => ({
    type: RESET_SESSION,
  }),
  setStatus: (status: SessionStatus) => ({
    type: SET_SESSION_STATUS,
    status,
  }),
}

export const SELECTORS = {
  getSessionIdentifier: (state: AppState) => state.session.sessionIdentifier,
  getSessionStatus: (state: AppState) => state.session.status,
  isOrderSessionCreated: (state: AppState) => {
    return state.session.status === 'succeeded'
  },
}

export const THUNKS = {
  setStatus: (status: SessionStatus) => async (dispatch: Dispatch) => {
    dispatch(ACTIONS.setStatus(status))
  },
  resetSession: () => async (dispatch: Dispatch, getState: () => AppState) => {
    await dispatch(ACTIONS.resetSession())
    const state = getState()
    const sessionIdentifier = SELECTORS.getSessionIdentifier(state)
    try {
      // Creates the order session (async call to LoadUp backend)
      const result = await client.mutate({
        mutation: CREATE_ORDER_SESSION,
        variables: {
          inputs: {
            uuid: sessionIdentifier,
          },
        },
      })

      if (result.data.errorCode) {
        trackError(result.data.errorCode)
        dispatch(ACTIONS.setStatus('failed'))
      }

      // Updates state to dictate that the order session has been created successfully
      dispatch(ACTIONS.setStatus('succeeded'))
      logRocketTrack(`Created Order Session: ${sessionIdentifier}`)
      console.log('createdOrderSession', sessionIdentifier)
    } catch (error) {
      trackError(error)
      dispatch(ACTIONS.setStatus('failed'))
    }
  },
  updateSession: () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState()
    const sessionIdentifier = SELECTORS.getSessionIdentifier(state)
    const formValues = state.form
    const orderUpdateData = updateOrderSessionData(formValues)
    const inputs = {
      data: orderUpdateData,
      hostSource: getHostSource(),
      firedAt: moment().toISOString(),
      uuid: sessionIdentifier,
    }

    try {
      const result = await client.mutate({
        mutation: UPDATE_SESSION,
        variables: { inputs },
      })

      if (result.data.errorCode) {
        trackError(result.data.errorCode)
        dispatch(ACTIONS.setStatus('failed'))
      }
    } catch (error) {
      trackError(error)
      dispatch(ACTIONS.setStatus('failed'))
    }
  },
}

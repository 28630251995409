import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'react-icons-kit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { check } from 'react-icons-kit/feather/check'
import styles from '../../../../../../styles'
import ExpandablePanel from '../../../../../../components/ui/layout/ExpandablePanel'

const createTriggerStyling = ({ active, disabled, finished }) => {
  if (active) {
    return Object.assign({})
  } else if (finished) {
    return Object.assign({}, { color: styles.colors.green0 })
  } else if (disabled) {
    return Object.assign(
      {},
      {
        color: styles.colors.dark3,
      }
    )
  }
}

const isComplete = ({ active, finished }) => finished && !active

const renderIcon = (props) =>
  isComplete(props) && (
    <div className="col-xs-1">
      <Icon
        icon={check}
        size={20}
        style={{
          color: styles.colors.green0,
        }}
      />
    </div>
  )

const OrderSectionExpandableContainer = ({
  active,
  children,
  disabled,
  finished,
  onChange,
  summary,
  subtext,
  ...rest
}) => (
  <ExpandablePanel
    {...rest}
    disabled={disabled}
    expanded={active}
    classes={`row accordionCard ${
      active ? 'accordionCardActive' : 'accordionCardDisabled'
    }`}
    expandIcon={!active && <ExpandMoreIcon />}
    onChange={onChange}
    summary={
      <div className="col-xs-12 row">
        {renderIcon({ active, finished })}
        <h4
          className="col-xs-11 h4"
          style={createTriggerStyling({ active, disabled, finished })}
        >
          {summary}
        </h4>
        <div
          className="col-xs-12"
          style={createTriggerStyling({ active, disabled, finished })}
        >
          {subtext}
        </div>
      </div>
    }
  >
    {children}
  </ExpandablePanel>
)

OrderSectionExpandableContainer.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  subtext: PropTypes.element,
  summary: PropTypes.string.isRequired,
}

OrderSectionExpandableContainer.defaultProps = {
  disabled: true,
}

export default OrderSectionExpandableContainer

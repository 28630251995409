import React from 'react'
import PropTypes from 'prop-types'
import { toTwoDecimals } from '../../../../../../../../lib/math'

const TotalPriceLabel = ({ minimumPrice, total }) => (
  <div
    className="col-xs-12 center-xs middle-xs"
    style={{ marginTop: '10px', background: '#f7f7f7', padding: '10px' }}
  >
    <p style={{ fontSize: '2rem', fontWeight: '900' }}>
      Guaranteed Price<sup>*</sup>{' '}
      <span style={{ marginLeft: '20px' }}>${toTwoDecimals(total)}</span>
    </p>
    <div className="col-xs-12 center-xs middle-xs">
      {minimumPrice && (
        <p className="subtext">
          {
            "There's a minimum price for any order. That's why your total price is greater than your base price."
          }
        </p>
      )}
    </div>
  </div>
)

TotalPriceLabel.propTypes = {
  minimumPrice: PropTypes.bool.isRequired,
  total: PropTypes.number,
}

export default TotalPriceLabel

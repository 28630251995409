export default (
  { active, dirty, error, invalid },
  externalError,
  externalErrorMessage = 'Please fill out this field'
) => {
  if (externalError) {
    return externalErrorMessage
  }
  return !active && dirty && invalid && error
}

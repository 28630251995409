import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import WeCallYouDescription from '../UnrecoverableErrorModal/components/WeCallYouDescription'
import {
  ACTIONS as modalActions,
  SELECTORS as modalSelectors,
} from '../../../../store/ducks/modals'

const ManyItemsCallModal = () => {
  const dispatch = useDispatch()
  const isManyItemsCallModalVisible = useSelector(
    modalSelectors.isManyItemsCallModalVisible
  )
  const handleClose = React.useCallback(() => {
    dispatch(modalActions.toggleManyItemsCallModal(false))
  }, [dispatch])

  return (
    <CenteredModal
      blockScroll={false}
      open={isManyItemsCallModalVisible}
      onClose={handleClose}
    >
      <div
        className="col-xs-12 center-xs"
        style={{ marginTop: '20px', padding: '0 10px 40px' }}
      >
        <WeCallYouDescription leadReason="TOO_MANY_ITEMS" />
      </div>
    </CenteredModal>
  )
}

export default ManyItemsCallModal

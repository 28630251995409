import styles from '../../../styles'

const common = {
  fontSize: '14px',
  letterSpacing: '0.025em',
  lineHeight: '1em',
  '::placeholder': {
    color: styles.colors.dark5,
  },
}

export default ({ onFocus }) => ({
  classes: {
    base: 'StripeElement baseStripe',
    complete: 'StripeElement--complete',
    empty: 'StripeElement--empty',
    focus: 'StripeElement--focus baseStripeFocus',
    invalid: 'StripeElement--invalid baseStripeError',
  },
  onFocus,
  style: {
    base: {
      ...common,
    },
    complete: {
      color: styles.colors.dark3,
    },
    invalid: {
      color: styles.colors.red0,
    },
  },
})

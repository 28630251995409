export const ACTION_TYPES = {
  TOGGLE_PRICING_BREAKDOWN_MODAL:
    'loadup/pricingDetails/TOGGLE_PRICING_BREAKDOWN_MODAL',
  TOGGLE_VALID_PROMO_CODE: 'loadup/pricingDetails/TOGGLE_VALID_PROMO_CODE',
  SET_ASAP_SCHEDULING_ALLOWED:
    'loadup/pricingDetails/SET_ASAP_SCHEDULING_ALLOWED',
}

export const initialState = {
  asapSchedulingAllowed: false,
  pricingBreakdownVisible: false,
  validPromoCode: false,
}

export default (reduxState = initialState, { payload, type }) => {
  switch (type) {
    case ACTION_TYPES.TOGGLE_PRICING_BREAKDOWN_MODAL:
      return {
        ...reduxState,
        pricingBreakdownVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_VALID_PROMO_CODE:
      return {
        ...reduxState,
        validPromoCode: payload.nextState,
      }
    case ACTION_TYPES.SET_ASAP_SCHEDULING_ALLOWED:
      return {
        ...reduxState,
        asapSchedulingAllowed: payload.nextState,
      }
    default:
      return reduxState
  }
}

const togglePricingBreakdown = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_PRICING_BREAKDOWN_MODAL,
  payload: { nextState },
})

const toggleValidPromoCode = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_VALID_PROMO_CODE,
  payload: { nextState },
})

export const ACTIONS = {
  togglePricingBreakdown,
  toggleValidPromoCode,
}

export const SELECTORS = {
  getPricingBreakdownVisible: (state) =>
    state.pricingDetails.pricingBreakdownVisible,
  isPromoCodeValid: (state) => state.pricingDetails.validPromoCode,
}

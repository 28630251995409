import React from 'react'
import copy from '../../../../../../lib/copy'

const YouCallUsDescription = () => (
  <div style={{ margin: '20px auto' }}>
    <h4>Call Us Now</h4>
    <h2>
      <a style={{ color: '#7BAE42' }} href={`tel:${copy().phone}`}>
        {copy().phone}
      </a>
    </h2>
  </div>
)

export default YouCallUsDescription

import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import activeRemovalItems from './ducks/activeRemovalItems'
import finalServiceLocation from './ducks/finalServiceLocation'
import modals from './ducks/modals'
import orderConfirmation from './ducks/orderConfirmation'
import orderDetailsAccordion from './ducks/orderDetailsAccordion'
import pageNavigation from './ducks/pageNavigation'
import { PricingDataState, reducer as pricingData } from './ducks/pricingData'
import pricingDetails from './ducks/pricingDetails'
import { reducer as session, SessionDataState } from './ducks/session'
import section from './ducks/section'
import {
  ExcludedDatesState,
  reducer as excludedDates,
} from './ducks/excludedDates'

export type AppState = Readonly<{
  excludedDates: ExcludedDatesState
  pricingData: PricingDataState
  session: SessionDataState
  form: any
}>

export default combineReducers({
  session,
  form,
  activeRemovalItems,
  finalServiceLocation,
  excludedDates,
  modals,
  orderConfirmation,
  orderDetailsAccordion,
  pageNavigation,
  pricingData,
  pricingDetails,
  section,
})

import React from 'react'
import PropTypes from 'prop-types'
import styles from './PlaceOrderButton.module.scss'

const PlaceOrderButton = ({ disabled, onClick, submitting }) => (
  <button
    className={`col-xs-12  ${styles.btnSuccess} ${disabled &&
      styles.btnSuccessDisabled}`}
    type="submit"
    onClick={onClick}
  >
    {submitting ? 'Submitting Order....' : 'Place Order'}
  </button>
)

PlaceOrderButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

PlaceOrderButton.defaultProps = {
  onClick() {},
}

export default PlaceOrderButton

import { createSelector } from 'reselect'

export const ACTION_TYPES = {
  TOGGLE_DISASSEMBLY: 'loadup/orderDetailsAccordion/TOGGLE_DISASSEMBLY',
  TOGGLE_STAIRS: 'loadup/orderDetailsAccordion/TOGGLE_STAIRS',
}

export const initialState = {
  disassemblyActive: false,
  stairsActive: false,
}

export default (reduxState = initialState, { payload, type }) => {
  switch (type) {
    case ACTION_TYPES.TOGGLE_DISASSEMBLY:
      return {
        ...reduxState,
        disassemblyActive: payload.disassemblyActive,
      }
    case ACTION_TYPES.TOGGLE_STAIRS:
      return {
        ...reduxState,
        stairsActive: payload.stairsActive,
      }
    default:
      return reduxState
  }
}

const toggleDisassembly = (disassemblyActive) => ({
  type: ACTION_TYPES.TOGGLE_DISASSEMBLY,
  payload: { disassemblyActive },
})
const toggleStairs = (stairsActive) => ({
  type: ACTION_TYPES.TOGGLE_STAIRS,
  payload: { stairsActive },
})

export const ACTIONS = {
  toggleDisassembly,
  toggleStairs,
}

const getState = (state) => state.orderDetailsAccordion

const getDisassemblyState = createSelector(
  getState,
  ({ disassemblyActive }) => disassemblyActive
)

const getStairsState = createSelector(
  getState,
  ({ stairsActive }) => stairsActive
)

export const SELECTORS = {
  getDisassemblyState,
  getStairsState,
}

import { createSelector } from 'reselect'

export const ACTION_TYPES = {
  SET_INVALID_ZIP_ATTEMPTS: 'loadup/modals/SET_INVALID_ZIP_ATTEMPTS',
  TOGGLE_INVALID_FINAL_SERVICE_LOCATION:
    'loadup/modals/TOGGLE_INVALID_FINAL_SERVICE_LOCATION',
  TOGGLE_INVALID_DATE_MODAL: 'loadup/modals/TOGGLE_INVALID_DATE_MODAL',
  TOGGLE_OUT_OF_SERVICE_AREA_MODAL: 'loadup/modals/TOGGLE_OUT_OF_SERVICE_MODAL',
  TOGGLE_PARTNER_REDIRECT_MODAL: 'loadup/modals/TOGGLE_PARTNER_REDIRECT_MODAL',
  TOGGLE_PAYMENT_ERROR_MODAL: 'loadup/modals/TOGGLE_PAYMENT_ERROR_MODAL',
  TOGGLE_UNRECOVERABLE_ERROR_MODAL:
    'loadup/modals/TOGGLE_UNRECOVERABLE_ERROR_MODAL',
  TOGGLE_ITEM_RESTRICTIONS_MODAL:
    'loadup/modals/TOGGLE_ITEM_RESTRICTIONS_MODAL',
  TOGGLE_CANCELLATION_POLICY_MODAL:
    'loadup/cancellationPolicy/TOGGLE_CANCELLATION_POLICY_MODAL',
  TOGGLE_CHARGE_UPFRONT_MODAL: 'loadup/modals/TOGGLE_CHARGE_UPFRONT_MODAL',
  TOGGLE_MANY_ITEMS_CALL_MODAL: 'loadup/modals/TOGGLE_MANY_ITEMS_CALL_MODAL',
  TOGGLE_REP_CALL_MODAL: 'loadup/modals/TOGGLE_REP_CALL_MODAL',
}

export const initialState = {
  copy: '',
  paymentCopy: '',
  invalidZipAttempts: 0,
  invalidDateModalVisible: false,
  invalidFinalServiceLocationModalVisible: false,
  outOfServiceModalVisible: false,
  partnerRedirectModalVisible: false,
  paymentErrorModalVisible: false,
  unrecoverableErrorModalVisible: false,
  itemRestrictionsModalVisible: false,
  cancellationPolicyModalVisible: false,
  chargeUpfrontModalVisible: false,
  manyItemsCallModalVisible: false,
  repCallModalVisible: false,
}

export default (reduxState = initialState, { payload, type }) => {
  switch (type) {
    case ACTION_TYPES.SET_INVALID_ZIP_ATTEMPTS:
      return {
        ...reduxState,
        invalidZipAttempts: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_INVALID_FINAL_SERVICE_LOCATION:
      return {
        ...reduxState,
        invalidFinalServiceLocationModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_INVALID_DATE_MODAL:
      return {
        ...reduxState,
        invalidDateModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_OUT_OF_SERVICE_AREA_MODAL:
      return {
        ...reduxState,
        outOfServiceModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_PARTNER_REDIRECT_MODAL:
      return {
        ...reduxState,
        partnerRedirectModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_PAYMENT_ERROR_MODAL:
      return {
        ...reduxState,
        paymentCopy: payload.paymentCopy || '',
        paymentErrorModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_UNRECOVERABLE_ERROR_MODAL:
      return {
        ...reduxState,
        copy: payload.copy || '',
        unrecoverableErrorModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_ITEM_RESTRICTIONS_MODAL:
      return {
        ...reduxState,
        itemRestrictionsModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_CANCELLATION_POLICY_MODAL:
      return {
        ...reduxState,
        cancellationPolicyModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_CHARGE_UPFRONT_MODAL:
      return {
        ...reduxState,
        chargeUpfrontModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_MANY_ITEMS_CALL_MODAL:
      return {
        ...reduxState,
        manyItemsCallModalVisible: payload.nextState,
      }
    case ACTION_TYPES.TOGGLE_REP_CALL_MODAL:
      return {
        ...reduxState,
        repCallModalVisible: payload.nextState,
      }
    default:
      return reduxState
  }
}

const setInvalidZipAttempts = (nextState) => ({
  type: ACTION_TYPES.SET_INVALID_ZIP_ATTEMPTS,
  payload: {
    nextState,
  },
})

const toggleInvalidFinalServiceLocationModalVisible = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_INVALID_FINAL_SERVICE_LOCATION,
  payload: {
    nextState,
  },
})

const toggleInvalidDateModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_INVALID_DATE_MODAL,
  payload: {
    nextState,
  },
})

const toggleOutOfServiceModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_OUT_OF_SERVICE_AREA_MODAL,
  payload: {
    nextState,
  },
})

const togglePartnerRedirectModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_PARTNER_REDIRECT_MODAL,
  payload: { nextState },
})

const togglePaymentErrorModal = (nextState, paymentCopy) => ({
  type: ACTION_TYPES.TOGGLE_PAYMENT_ERROR_MODAL,
  payload: { nextState, paymentCopy },
})

const toggleUnrecoverableErrorModal = (nextState, copy) => ({
  type: ACTION_TYPES.TOGGLE_UNRECOVERABLE_ERROR_MODAL,
  payload: { nextState, copy },
})

const toggleItemRestrictionsModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_ITEM_RESTRICTIONS_MODAL,
  payload: { nextState },
})

const toggleCancellationPolicyModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_CANCELLATION_POLICY_MODAL,
  payload: { nextState },
})

const toggleChargeUpfrontModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_CHARGE_UPFRONT_MODAL,
  payload: { nextState },
})

const toggleManyItemsCallModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_MANY_ITEMS_CALL_MODAL,
  payload: { nextState },
})
const toggleRepCallModal = (nextState) => ({
  type: ACTION_TYPES.TOGGLE_REP_CALL_MODAL,
  payload: { nextState },
})

export const ACTIONS = {
  setInvalidZipAttempts,
  toggleInvalidFinalServiceLocationModalVisible,
  toggleInvalidDateModal,
  toggleOutOfServiceModal,
  togglePartnerRedirectModal,
  toggleUnrecoverableErrorModal,
  togglePaymentErrorModal,
  toggleItemRestrictionsModal,
  toggleCancellationPolicyModal,
  toggleChargeUpfrontModal,
  toggleManyItemsCallModal,
  toggleRepCallModal,
}

const getState = ({ modals }) => modals

const getCopy = createSelector(getState, ({ copy }) => copy)

const getPaymentCopy = createSelector(
  getState,
  ({ paymentCopy }) => paymentCopy
)

const getInvalidZipAttempts = createSelector(
  getState,
  ({ invalidZipAttempts }) => invalidZipAttempts
)

const isInvalidFinalServiceLocationModalVisible = createSelector(
  getState,
  ({ invalidFinalServiceLocationModalVisible }) =>
    invalidFinalServiceLocationModalVisible
)

const isInvalidDateModalVisible = createSelector(
  getState,
  ({ invalidDateModalVisible }) => invalidDateModalVisible
)

const isOutOfServiceModalVisible = createSelector(
  getState,
  ({ outOfServiceModalVisible }) => outOfServiceModalVisible
)

const isPartnerRedirectModalVisible = createSelector(
  getState,
  ({ partnerRedirectModalVisible }) => partnerRedirectModalVisible
)

const isPaymentErrorModalVisible = createSelector(
  getState,
  ({ paymentErrorModalVisible }) => paymentErrorModalVisible
)

const isUnrecoverableErrorModalVisible = createSelector(
  getState,
  ({ unrecoverableErrorModalVisible }) => unrecoverableErrorModalVisible
)

const isItemRestrictionsModalVisible = createSelector(
  getState,
  ({ itemRestrictionsModalVisible }) => itemRestrictionsModalVisible
)

const isCancellationPolicyModalVisible = createSelector(
  getState,
  ({ cancellationPolicyModalVisible }) => cancellationPolicyModalVisible
)

const isChargeUpfrontModalVisible = createSelector(
  getState,
  ({ chargeUpfrontModalVisible }) => chargeUpfrontModalVisible
)

const isManyItemsCallModalVisible = createSelector(
  getState,
  ({ manyItemsCallModalVisible }) => manyItemsCallModalVisible
)

const getRepCallModalVisible = createSelector(
  getState,
  ({ repCallModalVisible }) => repCallModalVisible
)

export const SELECTORS = {
  getCopy,
  getPaymentCopy,
  getInvalidZipAttempts,
  isInvalidDateModalVisible,
  isInvalidFinalServiceLocationModalVisible,
  isOutOfServiceModalVisible,
  isPartnerRedirectModalVisible,
  isPaymentErrorModalVisible,
  isUnrecoverableErrorModalVisible,
  isItemRestrictionsModalVisible,
  isCancellationPolicyModalVisible,
  isChargeUpfrontModalVisible,
  isManyItemsCallModalVisible,
  getRepCallModalVisible,
}

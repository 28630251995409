import React from 'react'
import PropTypes from 'prop-types'
import { CardCVCElement } from 'react-stripe-elements'
import createPaymentInputOptions from '../../../../lib/forms/createPaymentInputOptions'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import { denyWeirdCharacters } from '../../../../lib/forms/normalizers/index'

const renderCreditCardCVCInput = ({
  externalError,
  input,
  label,
  meta,
  className,
}) => {
  const error = fieldIsInvalid(meta, externalError)
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <CardCVCElement
        id="stripe-cvc-element"
        onChange={input.onChange}
        {...createPaymentInputOptions(input)}
      />
      <div className="invalid-error">{error}</div>
    </div>
  )
}

renderCreditCardCVCInput.propTypes = {
  className: PropTypes.string,
  externalError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
}

renderCreditCardCVCInput.defaultProps = {
  className: '',
  normalize: denyWeirdCharacters,
}

export default renderCreditCardCVCInput

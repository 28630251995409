import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

const ExpandablePanel = ({
  id,
  children,
  disabled,
  expanded,
  expandIcon,
  onChange,
  summary,
  classes,
}) => (
  <Fragment>
    <div id={id} />
    <ExpansionPanel
      expanded={expanded}
      disabled={disabled}
      onChange={onChange}
      className={classes}
    >
      <ExpansionPanelSummary className="col-xs-12" expandIcon={expandIcon}>
        {summary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="col-xs-12 row">
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Fragment>
)

ExpandablePanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  disabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
}

export default ExpandablePanel

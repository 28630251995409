import validator from 'validator'
import sum from 'lodash/fp/sum'
import { STATE_NAMES } from '../../const'

/**
 * @desc Checks if the result of the google address picker is valid
 * @param address
 * @returns {*}
 */
export const isAddressValid = (address) => {
  if (!address) {
    return 'No valid address selected.'
  } else {
    const { parsed } = address
    return parsed && parsed.city && !parsed.zipCode
      ? 'The address you selected is invalid. Please pick a different address.'
      : null
  }
}

/**
 * @desc Assures the sum of several values is > 0
 * @param values
 */
export const sumGreaterThanZero = (values) =>
  !isRequired(values) && sum(values) > 0 ? null : 'Invalid Selection'

/**
 * @desc Checks if an email is valid
 * @param value
 */
export const isEmail = (value) =>
  !isRequired(value) && validator.isEmail(value) ? null : 'Invalid Email'

/**
 * @desc Checks if an email is correctly formatted if present
 * @param value
 */
export const isUnrequiredEmail = (value) => {
  if (!value || value.length === 0) return null
  return validator.isEmail(value) ? null : 'Invalid Email'
}

/**
 * @desc Checks if a value is an integer
 * @param value
 */
export const isInt = (value) =>
  validator.isInt(value) ? null : 'Must be a Number'

/**
 * @desc Checks if a value is in the right character length range
 * @param value
 * @param min
 * @param max
 * @returns {*}
 */
export const isLength = (value, { min, max }) => {
  if (!value) return 'Value is required'
  if (value && value.length <= min) return `Must be at least ${min} characters`
  if (value && value.length >= max) return `Must be a max of ${max} characters`
  return null
}

/**
 * @desc Checks if a value is present
 * @param value
 */
export const isRequired = (value) => (value ? null : 'Required')

/**
 * @desc Checks if a value is a valid zip code
 * @param value
 */
export const isZipCode = (value) =>
  !isRequired(value) && value.length === 5 && !isInt(value)
    ? null
    : 'Invalid Zip Code'

/**
 * @desc Checks if a phone number is valid
 */
export const isPhoneNumber = (value = '') =>
  value.length === 12 ? null : 'Invalid Phone Number'

/**
 * @desc Checks if a Phone number is null or valid
 * @param value
 * @returns {null}
 */
export const isPhoneNumberOrNull = (value = '') => {
  if (!value) return null
  return isPhoneNumber(value)
}

export function isValidState(name) {
  const uppercased = (name || '').toUpperCase()
  return STATE_NAMES.includes(uppercased)
    ? null
    : 'Must enter a valid state abbreviation'
}

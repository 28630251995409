import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'react-icons-kit'
import Tooltip from '@material-ui/core/Tooltip'
import { info } from 'react-icons-kit/feather/info'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import {
  isEmail,
  isLength,
  isRequired,
  isPhoneNumber,
  isPhoneNumberOrNull,
  isValidState,
  isZipCode,
} from '../../../../../../../../lib/forms/formValidators'
import createChangeValueAction from '../../../../../../../../lib/forms/createChangeValueAction'
import {
  normalizePhone,
  normalizeState,
  normalizeZip,
} from '../../../../../../../../lib/forms/normalizers'
import renderExpandableSectionInput from '../../../../../../../../components/ui/forms/renderExpandableSectionInput'
import RenderAddressAutocompleteInput from '../../../../../../../../components/ui/forms/renderAddressAutocompleteInput'
import renderTextInput from '../../../../../../../../components/ui/forms/renderTextInput'
import RenderDatalistInput from '../../../../../../../../components/ui/forms/renderDatalistInput'
import renderCheckboxInput from '../../../../../../../../components/ui/forms/renderCheckboxInput'
import NextSectionButton from '../../../../../../../../components/ui/touchables/NextSectionButton'
import withSyncFormErrors from '../../../../../../../../components/hocs/withSyncFormErrors'
import { SELECTORS as LocationSelectors } from '../../../../../../../../store/ducks/finalServiceLocation'
import { SELECTORS as SectionSelectors } from '../../../../../../../../store/ducks/section'
import { SELECTORS as PageNavigationSelectors } from '../../../../../../../../store/ducks/pageNavigation'
import { CONTACT_SECTION } from '../../../../utils'
import {
  EMAIL_CONSENT_COPY,
  SMS_CONSENT_COPY,
  STATE_NAMES,
} from '../../../../../../../../lib/const'
import { buildAddressComponents } from '../../../../../../../../lib/addresses'
import OnSiteContactFields, {
  validate as subValidate,
} from './components/OnSiteContactFields'
import styles from './ContactForm.module.scss'

const getExternalError = ({ field, submitted, syncErrors }) =>
  submitted && syncErrors[field]

class ContactForm extends PureComponent {
  state = {
    submitted: false,
  }

  attemptSubmit = () => {
    this.setState({ submitted: true })
  }

  componentDidMount() {
    const { initialZipCode, changeFormValue } = this.props
    changeFormValue({
      form: 'contactForm',
      field: 'zip',
      value: initialZipCode,
    })
  }

  handleAddressTextChange = (place) => {
    const mappedAddress = buildAddressComponents(place)
    Object.keys(mappedAddress).forEach((key) => {
      this.props.changeFormValue({
        form: 'contactForm',
        field: key,
        value: mappedAddress[key],
      })
    })

    this.props.changeFormValue({
      form: 'contactForm',
      field: 'pickupAddress',
      value: mappedAddress.addressline1,
    })
  }

  render() {
    const { submitted } = this.state
    const {
      handleSubmit,
      invalid,
      isFinalLocationValid,
      pristine,
      syncErrors,
    } = this.props

    return (
      <form className="row" onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Field
          name="customerFirstName"
          label="First Name"
          component={renderTextInput}
          className={`col-sm-6 col-xs-12 ${styles.firstInput}`}
          externalError={getExternalError({
            field: 'customerFirstName',
            submitted,
            syncErrors,
          })}
        />
        <Field
          name="customerLastName"
          label="Last Name"
          component={renderTextInput}
          className={`col-sm-6 col-xs-12 ${styles.firstInput}`}
          externalError={getExternalError({
            field: 'customerLastName',
            submitted,
            syncErrors,
          })}
        />
        <Field
          type="email"
          name="customerEmail"
          label="Email"
          component={renderTextInput}
          className="col-sm-6 col-xs-12"
          externalError={getExternalError({
            field: 'customerEmail',
            submitted,
            syncErrors,
          })}
        />
        <Field
          name="emailConsent"
          component={renderCheckboxInput}
          className="col-sm-12 form-control"
          style={{
            marginTop: '0.3rem',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'left',
          }}
          label={EMAIL_CONSENT_COPY}
          externalError={getExternalError({
            field: 'emailConsent',
            submitted,
            syncErrors,
          })}
        />
        <div className="col-sm-6 col-xs-12">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label>Customer Primary Phone</label>
            <Tooltip
              title={SMS_CONSENT_COPY}
              style={{ marginTop: '10px', paddingTop: '3px' }}
            >
              <Icon icon={info} size={12} />
            </Tooltip>
          </div>
          <Field
            type="tel"
            name="customerPrimaryPhone"
            normalize={normalizePhone}
            component={renderTextInput}
            externalError={getExternalError({
              field: 'customerPrimaryPhone',
              submitted,
              syncErrors,
            })}
          />
        </div>
        <Field
          type="tel"
          name="customerSecondaryPhone"
          normalize={normalizePhone}
          component={renderTextInput}
          className="col-sm-6 col-xs-12"
          label="Secondary Phone Number (Optional)"
          externalError={getExternalError({
            field: 'customerSecondaryPhone',
            submitted,
            syncErrors,
          })}
        />
        <div className="col-sm-6 visible-sm" />
        <Field
          name="pickupAddress"
          label="Street Address"
          component={RenderAddressAutocompleteInput}
          className="col-sm-9 col-xs-12 form-control"
          onChangeText={this.handleAddressTextChange}
          externalError={getExternalError({
            field: 'pickupAddress',
            submitted,
            syncErrors,
          })}
        />
        <Field
          name="unitNumber"
          label="Apt/Unit #"
          component={renderTextInput}
          className="col-sm-3 col-xs-12"
          externalError={getExternalError({
            field: 'unitNumber',
            submitted,
            syncErrors,
          })}
        />
        <Field
          name="city"
          label="City"
          component={renderTextInput}
          className="col-sm-4 col-xs-12"
          externalError={getExternalError({
            field: 'city',
            submitted,
            syncErrors,
          })}
        />
        <Field
          name="state"
          label="State"
          component={RenderDatalistInput}
          normalize={normalizeState}
          options={STATE_NAMES}
          className="col-sm-4 col-xs-12"
          externalError={getExternalError({
            field: 'state',
            submitted,
            syncErrors,
          })}
        />
        <Field
          name="zip"
          label="Zip Code"
          component={renderTextInput}
          normalize={normalizeZip}
          className="col-sm-4 col-xs-12"
          externalError={getExternalError({
            field: 'zip',
            submitted,
            syncErrors,
          })}
        />
        <hr />
        <Field
          name="elevatorRestriction"
          component={renderExpandableSectionInput}
          content="Does your building require a COI or have any elevator restrictions?"
        >
          <p>A team member will reach out for more information!</p>
        </Field>
        <Field
          name="onSiteContact"
          component={renderExpandableSectionInput}
          content="Will there be a different person on-site when we arrive?"
        >
          <OnSiteContactFields submitted={submitted} syncErrors={syncErrors} />
        </Field>
        <NextSectionButton
          className="col-xs-6 col-sm-3 col-sm-offset-9"
          disabled={invalid || pristine || !isFinalLocationValid}
          type="submit"
          onClick={this.attemptSubmit}
        />
      </form>
    )
  }
}

ContactForm.propTypes = {
  changeFormValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialZipCode: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  isFinalLocationValid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  syncErrors: PropTypes.object.isRequired,
}

const validate = ({
  customerFirstName,
  customerLastName,
  customerEmail,
  customerPrimaryPhone,
  pickupAddress,
  city,
  state,
  zip,
  customerSecondaryPhone,
  unitNumber,
  ...rest
}) => ({
  customerFirstName: isLength(customerFirstName, { min: 0, max: 250 }),
  customerLastName: isLength(customerLastName, { min: 0, max: 250 }),
  customerEmail: isEmail(customerEmail),
  customerPrimaryPhone: isPhoneNumber(customerPrimaryPhone),
  customerSecondaryPhone: isPhoneNumberOrNull(customerSecondaryPhone),
  pickupAddress: isRequired(pickupAddress),
  city: isRequired(city),
  state: isValidState(state),
  zip: isZipCode(zip),
  unitNumber:
    !isRequired(unitNumber) && isLength(unitNumber, { min: 0, max: 250 }),
  ...subValidate(rest),
})

const withForm = reduxForm({
  validate,
  form: 'contactForm',
  destroyOnUnmount: false,
  initialValues: {
    emailConsent: true,
  },
  onSubmit: (values, dispatch, props) => {
    props.onFinishSection(3)
  },
})

const mapStateToProps = (state) => ({
  initialZipCode: PageNavigationSelectors.initialZipCode(state),
  isFinalLocationValid: LocationSelectors.isFinalLocationValid(state),
  isCurrentSection: SectionSelectors.isCurrentSection(CONTACT_SECTION)(state),
})

const mapDispatch = {
  changeFormValue: createChangeValueAction,
}

export default compose(
  connect(mapStateToProps, mapDispatch),
  withForm,
  withSyncFormErrors('contactForm')
)(ContactForm)

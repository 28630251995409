import React from 'react'
import PropTypes from 'prop-types'
import styles from './OptInSwitch.module.scss'

const OptInSwitch = ({ active, onClick }) => (
  <div className="col-xs-4 end-xs" role="group">
    <button
      type="button"
      className={`${styles.toggleBtn} ${
        active ? styles.toggleBtnSuccess : styles.toggleBtn
      }`}
      onClick={() => onClick(true)}
    >
      Yes
    </button>
    <button
      type="button"
      className={`${styles.toggleBtn} ${
        active ? styles.toggleBtn : styles.toggleBtnSuccess
      }`}
      onClick={() => onClick(false)}
    >
      No
    </button>
  </div>
)

OptInSwitch.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OptInSwitch

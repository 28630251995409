import { connect } from 'react-redux'
import { ACTIONS, SELECTORS } from '../../../../../../store/ducks/modals'

export default connect(
  (state) => ({
    paymentCopy: SELECTORS.getPaymentCopy(state),
    isPaymentErrorModalVisible: SELECTORS.isPaymentErrorModalVisible(state),
  }),
  {
    togglePaymentErrorModal: ACTIONS.togglePaymentErrorModal,
  }
)

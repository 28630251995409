/**
 * @desc Validate the card number
 * @param cardNumber
 * @returns {*}
 */
const validateCardNumber = (cardNumber = {}) => {
  if (cardNumber.complete || cardNumber.empty) {
    return null
  }
  return cardNumber.error ? cardNumber.error.message : 'Invalid Card Number'
}

/**
 * @desc Validate the expiration date
 * @param cardExpiry
 * @returns {*}
 */
const validateCardExpiry = (cardExpiry = {}) => {
  if (cardExpiry.complete || cardExpiry.empty) {
    return null
  }
  return cardExpiry.error ? cardExpiry.error.message : 'Invalid Card Expiry'
}

/**
 * @desc Validate the CVC
 * @param cardCVC
 * @returns {*}
 */
const validateCardCVC = (cardCVC = {}) => {
  if (cardCVC.complete || cardCVC.empty) {
    return null
  }
  return cardCVC.error ? cardCVC.error.message : 'Invalid CVC'
}

/**
 * @desc Validate the postal code
 * @param postalCode
 * @returns {*}
 */
const validatePostalCode = (postalCode = {}) => {
  if (
    (postalCode.complete &&
      postalCode.value &&
      postalCode.value.length === 5) ||
    postalCode.empty
  ) {
    return null
  }
  return postalCode.error ? postalCode.error.message : 'Invalid Postal Code'
}

/**
 * @desc Validate the form value
 * @param formValue
 * @returns {*}
 */
export default (formValue = {}) => {
  const { elementType } = formValue

  switch (elementType) {
    case 'cardNumber':
      return validateCardNumber(formValue)
    case 'cardExpiry':
      return validateCardExpiry(formValue)
    case 'cardCvc':
      return validateCardCVC(formValue)
    case 'postalCode':
      return validatePostalCode(formValue)
    default:
      return ' '
  }
}

import * as React from 'react'
import StripeImage from '../../../../../../../../../../assets/images/powered-by-stripe-dark.png'

interface IProps {
  className?: string
  style?: any
}

export default function StripeBadge({ className, style }: IProps) {
  return (
    <div className={className} style={style}>
      <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
        <img src={StripeImage} alt="Powered by Stripe" />
      </a>
    </div>
  )
}

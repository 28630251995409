import React from 'react'
import PropTypes from 'prop-types'
import { ItemTypePropType } from '../../../../../../../../lib/types'
import { toTwoDecimals } from '../../../../../../../../lib/math'

const ItemQuantityRow = ({ item, unitPriceKey }) => (
  <div className="row col-xs-12">
    <div
      className="col-sm-4 col-xs-12"
      style={{ padding: '0', fontSize: '1.4rem' }}
    >
      {item.name}
    </div>
    <div
      className="col-sm-4 col-xs-12"
      style={{ padding: '0', fontSize: '1.4rem' }}
    >
      {item.quantity} x ${toTwoDecimals(item[unitPriceKey])}
    </div>
    <div
      className="col-sm-4 col-xs-12 end-sm"
      style={{ padding: '0', fontSize: '1.4rem', fontWeight: '600' }}
    >
      ${toTwoDecimals(item.quantity * item[unitPriceKey])}
    </div>
  </div>
)

ItemQuantityRow.propTypes = {
  item: ItemTypePropType.isRequired,
  unitPriceKey: PropTypes.string.isRequired,
}

export default ItemQuantityRow

import capitalize from 'lodash/fp/capitalize'

/**
 * @desc Get Marquee items
 * @param items
 */
export const getMarqueeItems = (items = []) =>
  items.filter((item) => item && item.marquee)

/**
 * @desc Get the searchable (not marquee) items
 * @param items
 */
export const getSearchableItems = (items = [], activeItems = []) => {
  const activeItemsIds = activeItems.map(({ id }) => id)

  // Get non-marquee items that are not active
  const remainingItems = items.filter(({ id }) => !activeItemsIds.includes(id))

  // Map the item types to be searchable with key-value
  return mapItemTypesToSearchableTypes(remainingItems)
}

/**
 * @desc Get the selectable items
 * @param items
 * @param activeItems
 */
export const getSelectableItems = (items = [], activeItems = []) => {
  const marqueeItems = getMarqueeItems(items)
  const activeItemsIds = activeItems.map(({ id }) => id)

  return marqueeItems.filter(({ id }) => !activeItemsIds.includes(id))
}

/**
 * @desc Turn the ItemTypes into searchable items
 * @param items
 */
const mapItemTypesToSearchableTypes = (items = []) =>
  items.map((item) => ({
    ...item,
    label: `${item.name} ${item.auxillaryText && `(${item.auxillaryText})`}`,
    key: item.id,
    value: item.id,
    filterString: `${item.name} ${
      item.aliases.length > 0
        ? `${item.aliases.map(capitalize).join(', ')}`
        : ''
    }`.toLowerCase(),
  }))

import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'react-icons-kit'
import { check } from 'react-icons-kit/feather/check'
import color from '../../../../styles'
import styles from './RenderCheckboxInput.module.scss'

const RenderCheckboxInput = ({ input, className, label, dataTid, style }) => {
  const handleClick = React.useCallback(() => {
    input.onChange(!input.value)
  }, [input])
  return (
    <div
      className={`center-xs ${className} ${styles.checkBox}`}
      style={{ ...style }}
    >
      <div
        className={styles.checkArea}
        onClick={handleClick}
        data-tid={dataTid}
        style={{
          cursor: 'pointer',
          backgroundColor: input.value
            ? color.colors.green0
            : color.colors.light3,
        }}
      >
        <Icon icon={check} size={15} />
      </div>
      {label && <label className="mb-0 ml-3">{label}</label>}
    </div>
  )
}

RenderCheckboxInput.propTypes = {
  className: PropTypes.string,
  dataTid: PropTypes.string,
  style: PropTypes.object,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  label: PropTypes.element.isRequired,
}

export default RenderCheckboxInput

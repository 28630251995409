import React from 'react'
import copy from '../../../../lib/copy'

const EmailLink = () => (
  <a className="text-success font-weight-bold" href={`mailto:${copy().email}`}>
    Email Us
  </a>
)

export default EmailLink

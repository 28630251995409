import React from 'react'
import Icon from 'react-icons-kit'
import { useDispatch, useSelector } from 'react-redux'
import { truck } from 'react-icons-kit/feather/truck'
import styles from '../../../../styles'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import WeCallYouDescription from '../UnrecoverableErrorModal/components/WeCallYouDescription'
import YouCallUsDescription from '../UnrecoverableErrorModal/components/YouCallUsDescription'
import {
  ACTIONS as modalActions,
  SELECTORS as modalSelectors,
} from '../../../../store/ducks/modals'

const HaveARepCallYouModal = () => {
  const dispatch = useDispatch()

  const handleClose = React.useCallback(() => {
    dispatch(modalActions.toggleRepCallModal(false))
  }, [dispatch])

  const isRepCallModalVisible = useSelector(
    modalSelectors.getRepCallModalVisible
  )

  return (
    <CenteredModal open={isRepCallModalVisible} onClose={handleClose}>
      <div className="row col-xs-12 center-xs">
        <div className="col-xs-12" style={{ marginBottom: '20px' }}>
          <Icon icon={truck} size={60} color={styles.colors.dark5} />
        </div>
        <h2
          className="col-xs-12"
          style={{ fontWeight: '600', fontSize: '1.8rem' }}
        >
          Not sure what to book? We can help you with that!
        </h2>
        <YouCallUsDescription />
        <h4 className="col-xs-12">Or</h4>
        <WeCallYouDescription leadReason="ITEM_NOT_FOUND" />
      </div>
    </CenteredModal>
  )
}

export default HaveARepCallYouModal

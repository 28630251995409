import React from 'react'
import { StripeProvider } from 'react-stripe-elements'

// eslint-disable-next-line react/display-name
const withStripe = (BaseComponent) => (props) => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
    <BaseComponent {...props} />
  </StripeProvider>
)

export default withStripe

import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { isProductionEnvironment } from '../../environment/environment'

const LOGROCKET_APP_KEY = process.env.REACT_APP_RETAIL_LOGROCKET_KEY

const initialize = () => LogRocket.init(LOGROCKET_APP_KEY)

export default () => {
  // Manually turning off LogRocket temporarily
  if (isProductionEnvironment() && LOGROCKET_APP_KEY && false) {
    console.log('Initializing Error Reporting')
    initialize()
    setupLogRocketReact(LogRocket)
    LogRocket.getSessionURL((sessionURL) => {
      console.log(`LogRocket Session URL: ${sessionURL}`)
    })
  } else {
    console.log('Skipping Error Reporting Initialization in Development')
  }
}

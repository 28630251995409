export default {
  colors: {
    dark0: '#000000',
    dark1: '#3F4242',
    dark2: '#3B3F3F',
    dark3: '#595959',
    dark4: '#88888F',
    dark5: '#919191',
    green0: '#7BAE42',
    green1: '#8CC44F',
    green2: '#8AC646',
    green3: '#9CD156',
    green4: '#BDE794',
    light0: '#E8E8E8',
    light1: '#EAEAEA',
    light2: '#F2F2F2',
    light3: '#FFFFFF',
    light4: '#F7F9F9',
    red0: '#DC2E48',
  },
}

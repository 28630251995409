import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field } from 'redux-form'
import {
  isLength,
  isPhoneNumber,
  isPhoneNumberOrNull,
  isUnrequiredEmail,
} from '../../../../../../../../../../lib/forms/formValidators'
import { requiresOnSiteContact } from '../../../../../../../../../../lib/forms/getPricingVariablesFromForm'
import { normalizePhone } from '../../../../../../../../../../lib/forms/normalizers'
import renderTextInput from '../../../../../../../../../../components/ui/forms/renderTextInput'
import withSyncFormErrors from '../../../../../../../../../../components/hocs/withSyncFormErrors'

const getExternalError = ({ field, submitted, syncErrors }) =>
  submitted && syncErrors[field]

const OnSiteContactFields = ({ submitted, syncErrors }) => (
  <Fragment>
    <h4 className="col-xs-12 qa-h4">On-site Contact Information</h4>
    <Field
      name="orderFirstName"
      label="First Name"
      component={renderTextInput}
      className={`col-sm-6 col-xs-12`}
      externalError={getExternalError({
        field: 'orderFirstName',
        submitted,
        syncErrors,
      })}
    />
    <Field
      name="orderLastName"
      label="Last Name"
      component={renderTextInput}
      className={`col-sm-6 col-xs-12`}
      externalError={getExternalError({
        field: 'orderLastName',
        submitted,
        syncErrors,
      })}
    />
    <Field
      type="tel"
      name="orderPrimaryPhone"
      normalize={normalizePhone}
      component={renderTextInput}
      className="col-sm-6 col-xs-12"
      label="Primary Phone Number"
      externalError={getExternalError({
        field: 'orderPrimaryPhone',
        submitted,
        syncErrors,
      })}
    />
    <Field
      type="tel"
      name="orderSecondaryPhone"
      normalize={normalizePhone}
      component={renderTextInput}
      className="col-sm-6 col-xs-12"
      label="Secondary Phone Number (Optional)"
      externalError={getExternalError({
        field: 'orderSecondaryPhone',
        submitted,
        syncErrors,
      })}
    />
    <Field
      type="email"
      name="orderEmail"
      label="Email (Optional)"
      component={renderTextInput}
      className="col-sm-6 col-xs-12"
      externalError={getExternalError({
        field: 'orderEmail',
        submitted,
        syncErrors,
      })}
    />
  </Fragment>
)

OnSiteContactFields.propTypes = {
  submitted: PropTypes.bool.isRequired,
  syncErrors: PropTypes.object.isRequired,
}

export const validate = ({
  orderFirstName,
  orderLastName,
  orderEmail,
  orderPrimaryPhone,
  orderSecondaryPhone,
}) =>
  requiresOnSiteContact()
    ? {
        orderFirstName: isLength(orderFirstName, { min: 0, max: 250 }),
        orderLastName: isLength(orderLastName, { min: 0, max: 250 }),
        orderEmail: isUnrequiredEmail(orderEmail),
        orderPrimaryPhone: isPhoneNumber(orderPrimaryPhone),
        orderSecondaryPhone: isPhoneNumberOrNull(orderSecondaryPhone),
      }
    : {}

export default compose(withSyncFormErrors('contactForm'))(OnSiteContactFields)

import React from 'react'
import PropTypes from 'prop-types'

const DetailsLayout = ({ children }) => (
  <div className="horizontal-site-spacing">{children}</div>
)

DetailsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
}

export default DetailsLayout

import React, { FormEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'recompose'
import { Field, reduxForm, change } from 'redux-form'
import { Icon } from 'react-icons-kit'
import { bolt } from 'react-icons-kit/fa/bolt'
import withSyncFormErrors from '../../../../../../components/hocs/withSyncFormErrors'
import { isRequired } from '../../../../../../lib/forms/formValidators'
import renderCalendarDatePickerInput from '../../../../../../components/ui/forms/renderCalendarDatePickerInput'
import renderTextareaInput from '../../../../../../components/ui/forms/renderTextareaInput'
import NextSectionButton from '../../../../../../components/ui/touchables/NextSectionButton'
import renderSelectInput from '../../../../../../components/ui/forms/renderSelectInput/index'
import renderCheckboxInput from '../../../../../../components/ui/forms/renderCheckboxInput'
import { selectors as excludedDatesSelectors } from '../../../../../../store/ducks/excludedDates'
import { selectors as pricingDataSelectors } from '../../../../../../store/ducks/pricingData'
import { selectors as formDataSelectors } from '../../../../../../components/hocs/withOrderFormValues'

import styles from './ScheduleForm.module.scss'
import get from 'lodash/fp/get'
import {
  makeGetFirstAvailableDate,
  DATE_FORMAT,
} from '../../../../../../lib/dates/availableDates'

export const TIMESLOT_OPTIONS = {
  MORNING: {
    value: 'MORNING',
    label: 'Morning (8AM - 12PM)',
  },
  AFTERNOON: {
    value: 'AFTERNOON',
    label: 'Afternoon (12PM - 4PM)',
  },
  EVENING: {
    value: 'EVENING',
    label: 'Evening (4PM - 8PM)',
  },
  FULL_DAY: {
    value: 'FULL_DAY',
    label: 'All Day (8AM - 8PM)',
  },
}

const TIMESLOT_SELECT_OPTIONS = Object.entries(
  TIMESLOT_OPTIONS
).map(([, v]) => ({ ...v, key: v.value }))

const ASAP_SCHEDULING_MAIN_COPY =
  'Would you like us to complete your order as soon as possible?'

const ASAP_SCHEDULING_SUB_COPY = `If we can accommodate an earlier time and/or date, we will contact you to verify the change. This could be as soon as 1 hour from the time your booking is placed. If we are unable to come sooner, we will work your order according to your scheduled date/time you've selected.`

interface Props {
  onFinishSection: () => any
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  invalid: boolean
  pristine: boolean
  syncErrors: any
}

const ScheduleSection = ({
  handleSubmit,
  invalid,
  pristine,
  syncErrors,
}: Props) => {
  const dispatch = useDispatch()
  const excludedDates = useSelector(excludedDatesSelectors.excludedDates)
  const asapSchedulingAllowed = useSelector(
    pricingDataSelectors.asapSchedulingAllowed
  )
  const [submitted, setSubmitted] = React.useState(false)

  React.useEffect(() => {
    if (!asapSchedulingAllowed) {
      dispatch(change('scheduleForm', 'asapSchedulingRequested', false))
    }
  }, [dispatch, asapSchedulingAllowed])

  const formState = useSelector(formDataSelectors.formData)

  const attemptSubmit = React.useCallback(() => {
    setSubmitted(true)
  }, [setSubmitted])

  const handleChangeAsap = React.useCallback(() => {
    const firstAvailableDate = makeGetFirstAvailableDate(excludedDates)()

    if (!get('scheduleFormValues.date', formState) && firstAvailableDate) {
      dispatch(
        change('scheduleForm', 'date', firstAvailableDate.format(DATE_FORMAT))
      )
    }
  }, [formState, dispatch, excludedDates])

  return (
    <form
      onSubmit={handleSubmit}
      style={{ width: '100%' }}
      className={styles.mobileWidth}
    >
      <div className={`row ${styles.subSection}`}>
        <div className="col-xs-12 row">
          <div className="col-sm-6 col-xs-12" style={{ marginBottom: '20px' }}>
            <h5>1. Select your pickup date</h5>
            <Field
              name="date"
              disabledDates={excludedDates}
              component={renderCalendarDatePickerInput}
              externalError={submitted && syncErrors.date}
            />
          </div>
          <div className="col-sm-6 col-xs-12">
            <h5>2. Select your pickup time</h5>
            <Field
              name="timeslot"
              returnObjKey="value"
              options={Object.values(TIMESLOT_SELECT_OPTIONS)}
              defaultOption={'-- Please select a timeslot --'}
              component={renderSelectInput}
              externalError={submitted && syncErrors.timeslot}
            />
          </div>
          <p className={`col-xs-12 ${styles.subtext}`}>
            We allow scheduling for next day up to 90 days in advance! Dates
            that are grey are unavailable.
          </p>
        </div>
      </div>
      {asapSchedulingAllowed && (
        <>
          <div
            className={`row ${styles.subSection}`}
            style={{ padding: '2rem 0.5rem' }}
          >
            <Icon
              className="col-xs-1"
              icon={bolt}
              size={24}
              style={{ color: '#8ac646' }}
            />
            <label
              htmlFor="asapSchedulingRequested"
              className={`col-xs-8 ${styles.asapLabel}`}
            >
              {ASAP_SCHEDULING_MAIN_COPY}
            </label>
            <Field
              className="col-xs-3"
              name="asapSchedulingRequested"
              dataTid="asap-scheduling-0asdf91k"
              component={renderCheckboxInput}
              style={{ margin: 0 }}
              onChange={handleChangeAsap}
            />
            <p
              className={`col-xs-12 ${styles.subtext}`}
              style={{ marginTop: '1rem' }}
            >
              {ASAP_SCHEDULING_SUB_COPY}
            </p>
          </div>
        </>
      )}
      <div className={`row ${styles.subSection}`}>
        <div className="col-xs-12">
          <Field
            name="specialInstructions"
            component={renderTextareaInput}
            className="row col-xs-12"
            limit={250}
            label="Special Instructions"
            placeholder="Any special pickup or schedule instructions we should know."
            style={{ minHeight: 120, width: '100%' }}
          />
        </div>
      </div>
      <div className={`row`}>
        <NextSectionButton
          className="col-xs-6 col-sm-3 col-sm-offset-9"
          disabled={invalid || pristine}
          type="submit"
          onClick={attemptSubmit}
        />
      </div>
    </form>
  )
}

const validate = ({ date, timeslot }: any) => ({
  date: isRequired(date),
  timeslot: isRequired(timeslot),
})

const withForm = reduxForm({
  // @ts-ignore
  validate,
  form: 'scheduleForm',
  destroyOnUnmount: false,
  onSubmit: (values, _, props) => {
    // @ts-ignore
    props.onFinishSection(1)
  },
})

export default compose<any, any>(
  withForm,
  withSyncFormErrors('scheduleForm')
)(ScheduleSection)

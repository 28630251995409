import { createSelector } from 'reselect'

export const ACTION_TYPES = {
  SET_INVALID: 'loadup/finalServiceLocation/SET_INVALID',
  SET_VALID: 'loadup/finalServiceLocation/SET_VALID',
}

export const initialState = {
  finalLocationValid: false,
}

export default (reduxState = initialState, { type }) => {
  switch (type) {
    case ACTION_TYPES.SET_INVALID:
      return {
        finalLocationValid: false,
      }
    case ACTION_TYPES.SET_VALID:
      return {
        finalLocationValid: true,
      }
    default:
      return reduxState
  }
}

const setFinalLocationInvalid = () => ({ type: ACTION_TYPES.SET_INVALID })

const setFinalLocationValid = () => ({ type: ACTION_TYPES.SET_VALID })

export const ACTIONS = {
  setFinalLocationInvalid,
  setFinalLocationValid,
}

const getState = ({ finalServiceLocation }) => finalServiceLocation

const isFinalLocationValid = createSelector(
  getState,
  ({ finalLocationValid }) => finalLocationValid
)

export const SELECTORS = {
  isFinalLocationValid,
}

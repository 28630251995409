import React from 'react'
import PropTypes from 'prop-types'
import ExpandablePanel from '../../../../../../../../components/ui/layout/ExpandablePanel'
import OptableTrigger from './components/OptableTrigger/optableTrigger'

const DetailsSectionExpandableContainer = ({
  active,
  children,
  content,
  subcontent,
  disabled,
  onChange,
}) => (
  <ExpandablePanel
    expanded={active}
    disabled={disabled}
    classes={`interiorCard ${active ? 'interiorCardActive' : 'interiorCard'}`}
    onChange={() => {}}
    summary={
      <OptableTrigger
        active={active}
        content={content}
        subcontent={subcontent}
        onClick={onChange}
      />
    }
  >
    {children}
  </ExpandablePanel>
)

DetailsSectionExpandableContainer.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  children: PropTypes.element,
  content: PropTypes.string.isRequired,
  subcontent: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

DetailsSectionExpandableContainer.defaultProps = {
  active: false,
  disabled: false,
}

export default DetailsSectionExpandableContainer

import React from 'react'
// @ts-ignore
import omit from 'lodash/fp/omit'
// @ts-ignore
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import styles from './RenderDatalistInput.module.css'

interface IProps {
  className?: string
  externalError?: string
  input: {
    name?: string
    onChange: () => void
    value?: string
  }
  label?: string
  meta: any
  options: Array<string>
}

export default function RenderDatalistInput(props: IProps) {
  const {
    className = '',
    externalError,
    input,
    label,
    meta,
    options,
    ...rest
  } = props

  const error = fieldIsInvalid(meta, externalError)
  const pertinentProps = omit(['normalize'], rest)
  const datalistId = `datalist-${input.name}-249352`
  return (
    <div className={className}>
      {label && <label htmlFor={input.name}>{label}</label>}
      <div>
        <input
          type="text"
          className={`${error && 'is-invalid'} ${styles.input}`}
          list={datalistId}
          {...input}
          {...pertinentProps}
        />
        <datalist id={datalistId}>
          {options.map((name, index) => (
            <option key={`${name}-${index}`} value={name} />
          ))}
        </datalist>
      </div>
      <div
        className="invalid-feedback"
        style={{ display: error ? 'flex' : 'hidden' }}
      >
        {error}
      </div>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { CardNumberElement } from 'react-stripe-elements'
import CardImg from '../../../../assets/images/credit-cards.png'
import createPaymentInputOptions from '../../../../lib/forms/createPaymentInputOptions'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import { denyWeirdCharacters } from '../../../../lib/forms/normalizers/index'

const renderCreditCardNumberInput = ({
  externalError,
  input,
  label,
  meta,
  className,
}) => {
  const error = fieldIsInvalid(meta, externalError)
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <CardNumberElement
        id="stripe-card-number-element"
        onChange={input.onChange}
        {...createPaymentInputOptions(input)}
      />
      <img
        alt="Credit Card"
        src={CardImg}
        style={{
          maxWidth: '100px',
          maxHeight: '15px',
          objectFit: 'contain',
          marginTop: '5px',
        }}
      />
      <div className="invalid-error">{error}</div>
    </div>
  )
}

renderCreditCardNumberInput.propTypes = {
  className: PropTypes.string,
  externalError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }),
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
}

renderCreditCardNumberInput.defaultProps = {
  className: '',
  normalize: denyWeirdCharacters,
}

export default renderCreditCardNumberInput

import React from 'react'
import PropTypes from 'prop-types'
import { BarLoader } from 'react-spinners'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'

const CreatingOrderLoadingModal = ({ isVisible }) => (
  <CenteredModal
    closeOnEsc={false}
    closeOnOverlayClick={false}
    onClose={() => {}}
    open={isVisible}
    showCloseIcon={false}
  >
    <div className="col-xs-12 center-xs" style={{ padding: '10px' }}>
      <BarLoader height={20} width={240} />
      <h4 className="font-weight-bold text-success">
        {"We're Creating your Order."}
      </h4>
      <h6
        style={{ margin: '0 auto', fontSize: '1.5em' }}
        className="text-center"
      >
        Hang on just a sec to receive your confirmation.
      </h6>
    </div>
  </CenteredModal>
)

CreatingOrderLoadingModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
}

export default CreatingOrderLoadingModal

import React from 'react'
import copy from '../../../../lib/copy'

const copies = [
  {
    en: `The night prior to your junk removal booking appointment you will receive a complimentary phone call to confirm your booking time window with ${
      copy().name
    }.`,
  },
  {
    en: `Once your driver is dispatched, you will receive up-to-date notifications letting you know when your driver leaves, arrives and has completed your pickup. You can also keep up with your driver in real time by following the GPS tracking link in your first notification!`,
  },
  {
    en: `You will receive another notification upon your driver's arrival. Simply greet them at the door and let them get to work.`,
  },
  {
    en: `Last, you will receive a link to let us know about your experience. We love to hear from our customers! Get ready to make your old junk history!`,
  },
]

const BookingPreparation = () => (
  <div className="col-xs-12" style={{ margin: '15px auto' }}>
    <h3 style={{ fontSize: '1.17em', fontWeight: '600' }}>
      Booking Preparation: What Happens Next?
    </h3>
    {copies.map((item, index) => (
      <p key={item.en} style={{ margin: '3px auto' }}>
        <span style={{ fontWeight: 600 }}>
          {index + 1}
          )&nbsp;
        </span>
        {item.en}
      </p>
    ))}
  </div>
)

export default BookingPreparation

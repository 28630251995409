import React from 'react'
import PropTypes from 'prop-types'
import { ItemTypePropType } from '../../../../lib/types'
import ItemCounterRow from './components/ItemCounterRow'

const renderItemCounterInput = ({ input, onRemove, options }) =>
  options.map((option, index) => {
    const currentValue = input.value[option.id] || 0
    return (
      <ItemCounterRow
        last={index === options.length - 1}
        key={option.id}
        subtractDisabled={currentValue < 1}
        count={input.value[option.id] || 0}
        addDisabled={currentValue > 98}
        showWarningMsg={currentValue > 98}
        item={option}
        onSubtract={() =>
          input.onChange({
            ...input.value,
            [option.id]: currentValue - 1,
          })
        }
        onAdd={() =>
          input.onChange({
            ...input.value,
            [option.id]: currentValue + 1,
          })
        }
        onRemove={() => onRemove(option)}
      />
    )
  })

renderItemCounterInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.object,
  }),
  onRemove: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(ItemTypePropType).isRequired,
}

renderItemCounterInput.defaultProps = {
  options: [],
}

export default renderItemCounterInput

import React, { Fragment } from 'react'
import { gql } from 'apollo-boost'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import withSessionData from '../../../../../../components/hocs/withSessionData'
import renderTextInput from '../../../../../../components/ui/forms/renderTextInput'
import {
  isRequired,
  isPhoneNumber,
} from '../../../../../../lib/forms/formValidators'
import { transformPhoneNumberValueToUSPhone } from '../../../../../../lib/forms/transformFieldValues'
import { normalizePhone } from '../../../../../../lib/forms/normalizers'
import {
  handleSpecificError,
  trackError,
} from '../../../../../../lib/errors/specificErrors'

const createLeadMutation = gql`
  mutation CreateLead($inputs: CreateLeadInputs!) {
    createLead(inputs: $inputs) {
      firstName
      phone
    }
  }
`

const CreateLeadForm = ({
  error,
  handleSubmit,
  invalid,
  pristine,
  submitSucceeded,
  submitting,
}) => {
  if (error) {
    handleSpecificError(error)
  }
  return (
    <form className="row col-xs-12 center-xs" onSubmit={handleSubmit}>
      {submitSucceeded ? (
        <h4 className="center-xs text-success">
          {"Thank You! We'll reach out as soon as possible!"}
        </h4>
      ) : (
        <Fragment>
          <div className="col-md-6 col-xs-12">
            <Field
              autoFocus
              name="firstName"
              label="First Name"
              component={renderTextInput}
            />
          </div>
          <div className="col-md-6 col-xs-12">
            <Field
              name="phone"
              label="Phone Number"
              component={renderTextInput}
              normalize={normalizePhone}
            />{' '}
          </div>
          <button
            className="btn btn-success"
            disabled={invalid || pristine || submitting}
            type="submit"
          >
            Call Me
          </button>
        </Fragment>
      )}
    </form>
  )
}

CreateLeadForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const validate = (values) => ({
  firstName: isRequired(values.firstName),
  phone: isPhoneNumber(values.phone),
})

const withForm = reduxForm({
  form: 'createLeadForm',
  validate,
  onSubmit: async (values, _, props) => {
    try {
      const inputs = Object.assign({}, values, {
        phone: transformPhoneNumberValueToUSPhone(values.phone),
        leadReason: props.leadReason,
        orderSessionUuid: props.sessionIdentifier,
      })
      await props.createLead({ variables: { inputs } })
      props.reset()
      return true
    } catch (error) {
      trackError(error)
      throw new SubmissionError({ _error: error.message })
    }
  },
})

const withCreateLeadMutation = graphql(createLeadMutation, {
  name: 'createLead',
})

export default compose(
  withSessionData,
  withCreateLeadMutation,
  withForm
)(CreateLeadForm)

import React from 'react'
import PropTypes from 'prop-types'
import LeftIconButton from '../../../../../../components/ui/touchables/LeftIconButton'
import iconSource from '../../../../../../assets/icons/moving_truck_icon.svg'

const GoButton = ({ disabled }) => (
  <LeftIconButton
    className="col-xs-12"
    data-tid="go-button-37632"
    iconSource={iconSource}
    onClick={() => {}}
    title="Go"
    type="submit"
    disabled={disabled}
  />
)

GoButton.propTypes = {
  disabled: PropTypes.bool,
}

export default GoButton

import store from '../../store/configureStore'
import { SELECTORS } from '../../store/ducks/session'
import { logRocketIdentify, logRocketTrack } from './logrocket'
import {
  trackGoogleAdsCompletedOrder,
  trackGoogleAnalyticsCompletedOrder,
} from './googleAnalytics'
import { graphQLTrack } from './graphqlMutation'
import { trackFacebookConversion } from './facebook'
import { storageAvailable } from '../storage'

export const MODALS = {
  INVALID_DATE: 'InvalidDate',
  INVALID_SERVICE_LOCATION: 'InvalidServiceLocation',
  OUT_OF_SERVICE: 'OutOfService',
  PAYMENT_ERROR: 'PaymentError',
  PRICING_BREAKDOWN: 'PricingBreakdown',
  UNRECOVERABLE_ERROR: 'UnrecoverableError',
}

const localStorageCustomerIdKey = 'loadUpCustomerId'

const withOrderSessionId = (props) => ({
  sessionIdentifier: SELECTORS.getSessionIdentifier(store.getState()),
  ...props,
})

export const getCustomerIdFromLocalStorage = () =>
  storageAvailable()
    ? window.localStorage.getItem(localStorageCustomerIdKey)
    : null

export const storeCustomerIdInLocalStorage = (customerId) =>
  storageAvailable() &&
  window.localStorage.setItem(localStorageCustomerIdKey, customerId)

export const configureTracking = () => {
  identifyCustomer()
}

export const trackEvent = (event, properties = {}) => {
  graphQLTrack(event, withOrderSessionId(properties))
  logRocketTrack(event)
}

export const trackOrderCompleted = ({ order, total }) => {
  trackEvent('completed_order', {
    revenue: total,
    orderId: order.id,
  })
  trackGoogleAnalyticsCompletedOrder({ order, total })
  trackGoogleAdsCompletedOrder({ order, total })
  trackFacebookConversion({ total })
}

export const trackPage = ({ name, properties = {} }) => {
  const eventName = `visited_${name}`
  trackEvent(eventName, properties)
}

export const trackViewModal = ({ name, properties = {} }) => {
  const eventName = `viewed_${name}_modal`
  trackEvent(eventName, properties)
}

export const identifyCustomer = (customer = {}) => {
  const id = customer.id || getCustomerIdFromLocalStorage()
  const idPresent = id && id.length > 0
  if (!idPresent) return
  logRocketIdentify(id)
}

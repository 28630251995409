export const EMAIL_CONSENT_COPY =
  'I would like to receive emails with special deals and promotions'

export const SMS_CONSENT_COPY =
  'Note: You may receive text updates about the status of your order'

export const LAYOUT_BREAKPOINT = 768

export const PAYPAL_BUTTONS_ID = 'paypal-button-container-9396c748'

export const STATE_NAMES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MH',
  'MA',
  'MI',
  'FM',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'VI',
  'WA',
  'WV',
  'WI',
  'WY',
]

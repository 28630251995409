import { getHostSource } from '../../environment/environment'

export const trackGoogleAdsCompletedOrder = ({ order, total }) => {
  if (!window.gtag) {
    console.log('GTAG not loaded during conversion tracking')
    return
  }
  if (!window.loadupGoogleAdwordsConversionCodes) {
    console.log('loadupGoogleAdwordsConversionCodes not loaded')
    return
  }

  if (window.loadupGoogleAdwordsConversionCodes.length === 0) {
    console.log('loadupGoogleAdwordsConversionCodes has length of 0')
    return
  }

  window.loadupGoogleAdwordsConversionCodes.forEach((code) => {
    window.gtag('event', 'conversion', {
      send_to: code,
      value: total,
      currency: 'USD',
      transaction_id: order.id,
    })
  })
}

export const trackGoogleAnalyticsCompletedOrder = ({ order, total }) => {
  addTransaction({ order, total })
  addEcommerceItems({ order })
  window.ga('ecommerce:send')
}

const addTransaction = ({ order, total }) => {
  window.ga('ecommerce:addTransaction', {
    id: order.id, // Transaction ID. Required.
    affiliation: getHostSource(), // Affiliation or store name.
    revenue: total, // Grand Total.
  })
}

const addEcommerceItems = ({ order }) =>
  order.orderEntries.forEach((oe) => {
    if (oe.assemblyCount > 0) {
      window.ga('ecommerce:addItem', {
        id: order.id, // Transaction ID. Required.
        name: `${oe.itemType.name} - Assembly`, // Product name. Required.
        sku: `${oe.itemType.slug}-assembly`, // SKU/code.
        price: oe.itemType.assemblyPrice, // Unit price.
        quantity: oe.assemblyCount, // Quantity.
      })
    }

    if (oe.disassemblyCount > 0) {
      window.ga('ecommerce:addItem', {
        id: order.id, // Transaction ID. Required.
        name: `${oe.itemType.name} - Disssembly`, // Product name. Required.
        sku: `${oe.itemType.slug}-disassembly`, // SKU/code.
        price: oe.itemType.disassemblyPrice, // Unit price.
        quantity: oe.disassemblyCount, // Quantity.
      })
    }

    if (oe.pickupCount > 0) {
      window.ga('ecommerce:addItem', {
        id: order.id, // Transaction ID. Required.
        name: `${oe.itemType.name} - Pickup`, // Product name. Required.
        sku: `${oe.itemType.slug}-pickup`, // SKU/code.
        price: oe.itemType.pickupPrice, // Unit price.
        quantity: oe.pickupCount, // Quantity.
      })
    }
  })

import get from 'lodash/fp/get'
import { formValueSelector } from 'redux-form'
import store from '../../../store/configureStore'
import { getTotalPrice } from '../../../routes/NewOrder/components/PricingBreakdownModal/components/PricingBreakdown/utils'
import { transformPhoneNumberValueToUSPhone } from '../transformFieldValues'
import { readRemovalItemsData } from '../../../routes/NewOrder/components/OrderAccordion/components/withRemovalItemsData'
import { SELECTORS } from '../../../store/ducks/session'

/**
 * @desc Create the form value selectors
 */
const contactFormSelector = formValueSelector('contactForm')
const disassemblyItemsFormSelector = formValueSelector('disassemblyItemsForm')
const flightsOfStairsFormSelector = formValueSelector('flightsOfStairsForm')
const pickupLocationFormSelector = formValueSelector('pickupLocationForm')
const promoCodeFormSelector = formValueSelector('promoCodeForm')
const removalItemsFormSelector = formValueSelector('removalItemsForm')
const scheduleFormSelector = formValueSelector('scheduleForm')
const zipCodeFormSelector = formValueSelector('zipCodeForm')
const creditCardPaymentSelector = formValueSelector('creditCardPaymentForm')

/**
 * @desc Create the items array
 * @param removal
 * @param disassembly
 */
const createItems = ({ removal, disassembly }) =>
  Object.keys(removal).map((removalItemKey) => ({
    assemblyCount: 0,
    disassemblyCount: disassembly[removalItemKey],
    id: removalItemKey,
    pickupCount: removal[removalItemKey],
  }))

/**
 * @desc Collate the address components
 * @param original
 * @param parsed
 */
const getAddressComponents = () => {
  return {
    addressline1: getValueFromContactForm('pickupAddress'),
    city: getValueFromContactForm('city'),
    state: getValueFromContactForm('state'),
    zip: getValueFromContactForm('zip'),
    unitNumber: getValueFromContactForm('unitNumber'),
  }
}

/**
 * @desc Collate the contact info
 */
const getContactInfo = () => ({
  elevatorRestriction: getValueFromContactForm('elevatorRestriction') || false,
  customerEmail: getValueFromContactForm('customerEmail'),
  orderEmail: getValueFromContactForm(
    requiresOnSiteContact() ? 'orderEmail' : 'customerEmail'
  ),
  customerFirstName: getValueFromContactForm('customerFirstName'),
  orderFirstName: getValueFromContactForm(
    requiresOnSiteContact() ? 'orderFirstName' : 'customerFirstName'
  ),
  customerLastName: getValueFromContactForm('customerLastName'),
  orderLastName: getValueFromContactForm(
    requiresOnSiteContact() ? 'orderLastName' : 'customerLastName'
  ),
  customerPrimaryPhone: transformPhoneNumberValueToUSPhone(
    getValueFromContactForm('customerPrimaryPhone')
  ),
  orderPrimaryPhone: transformPhoneNumberValueToUSPhone(
    getValueFromContactForm(
      requiresOnSiteContact() ? 'orderPrimaryPhone' : 'customerPrimaryPhone'
    )
  ),
  orderSecondaryPhone: transformPhoneNumberValueToUSPhone(
    getValueFromContactForm(
      requiresOnSiteContact() ? 'orderSecondaryPhone' : 'customerSecondaryPhone'
    )
  ),
  customerSecondaryPhone: transformPhoneNumberValueToUSPhone(
    getValueFromContactForm('customerSecondaryPhone')
  ),
})

/**
 * @desc Get the order date
 */
const getDate = () => getValueFromScheduleForm('date')

/**
 * @desc Get the disassembly items
 */
const getDisassemblyItems = () =>
  getValueFromDisassemblyItemsForm('disassemblyItems') || {}

/**
 * @desc Get the flights of stairs count
 */
export const getFlightsOfStairsCount = () =>
  getValueFromFlightsOfStairsForm('flightsOfStairs') || 0

/**
 * @desc Get the parsed object returned from the LoadUp Autocomplete component
 */
export const getParsedAddress = () =>
  get('parsed', getValueFromContactForm('pickupAddress'))

/**
 * @desc Get the placement
 */
const getPlacement = () => getValueFromPickupLocactionForm('placement')

/**
 * @desc Get the promoCode
 */
export const getPromoCode = () => {
  return getValueFromPromoCodeForm('promoCode')
}

/**
 * @desc Gets the promo code if it's applied
 * @param pricingDetails
 */
const getPromoCodeIfApplied = (pricingDetails) =>
  get('promoApplied', pricingDetails) ? getPromoCode() : null

/**
 * @desc Get the removal items
 */
export const getRemovalItems = () =>
  getValueFromRemovalItemsForm('removalItems') || {}

/**
 * @desc Get the special instructions
 */
const getSpecialInstructions = () =>
  getValueFromScheduleForm('specialInstructions')

/**
 * @desc Get the Redux store state
 */
const getState = () => store.getState()

/**
 * @desc Get the timeslot
 */
const getTimeslot = () => getValueFromScheduleForm('timeslot')

/**
 * @desc Form value selector to get data from the contactForm
 * @param value
 */
export const getValueFromContactForm = (value) =>
  contactFormSelector(getState(), value)

/**
 * @desc Form value selector to get data from the disassemblyItemsForm
 * @param value
 */
const getValueFromDisassemblyItemsForm = (value) =>
  disassemblyItemsFormSelector(getState(), value)

/**
 * @desc Form value selector to get data from the flightsOfStairsForm
 * @param value
 */
const getValueFromFlightsOfStairsForm = (value) =>
  flightsOfStairsFormSelector(getState(), value)

/**
 * @desc Form value selector to get data from the pickupLocationForm
 * @param value
 */
const getValueFromPickupLocactionForm = (value) =>
  pickupLocationFormSelector(getState(), value)

/**
 * @desc Form value selector to get data from the promoCodeForm
 * @param value
 */
const getValueFromPromoCodeForm = (value) =>
  promoCodeFormSelector(getState(), value)

/**
 * @desc Form value selector to get data from the removalItemsForm
 * @param value
 */
const getValueFromRemovalItemsForm = (value) =>
  removalItemsFormSelector(getState(), value)

/**
 * @desc Form value selector to get data from the scheduleForm
 * @param value
 */
const getValueFromScheduleForm = (value) =>
  scheduleFormSelector(getState(), value)

const getValueFromCreditCardPaymentForm = (value) =>
  creditCardPaymentSelector(getState(), value)
/**
 * @ desc Get the Order Form Session UUiD
 */

const getOrderSessionUuid = () => SELECTORS.getSessionIdentifier(getState())
/**
 * @desc Get the pricing variables
 */
export const getPricingVariables = (
  overrides = {},
  isPromoCodeValid = false
) => {
  const { itemTypesVersion } = readRemovalItemsData()
  return {
    date: getDate(),
    flightsOfStairsCount: getFlightsOfStairsCount(),
    items: createItems({
      removal: getRemovalItems(),
      disassembly: getDisassemblyItems(),
    }),
    itemTypesVersion,
    placement: getPlacement(),
    promoCode: isPromoCodeValid ? getPromoCode() : null,
    timeslot: getTimeslot(),
    zip: getValueFromContactForm('zip'),
    orderSessionUuid: getOrderSessionUuid(),
    ...overrides,
  }
}

/**
 * @desc Get the order creation variables
 * @param stripeCardToken
 */
export const getOrderCreationVariables = ({
  stripeCardToken,
  pricingDetails,
}) => {
  return {
    asapSchedulingRequested: getValueFromScheduleForm(
      'asapSchedulingRequested'
    ),
    displayedTotal: getTotalPrice(pricingDetails),
    specialInstructions: getSpecialInstructions(),
    stripeCardToken,
    orderSessionUuid: getOrderSessionUuid(),
    ...getContactInfo(),
    ...getPricingVariables({
      promoCode: getPromoCodeIfApplied(pricingDetails),
    }),
    ...getAddressComponents(),
  }
}

export const getPaypalOrderCreationVariables = ({ pricingDetails }) => ({
  displayedTotal: getTotalPrice(pricingDetails),
  specialInstructions: getSpecialInstructions(),
  orderSessionUuid: getOrderSessionUuid(),
  ...getContactInfo(),
  ...getPricingVariables({
    promoCode: getPromoCodeIfApplied(pricingDetails),
  }),
  paypalOrderId: getValueFromCreditCardPaymentForm('paypalOrderId'),
  ...getAddressComponents(),
})

/**
 * @desc Gets only the zip code from the parsed address
 */
export const getZipCodeFromParsedAddress = () =>
  get('zipCode', getParsedAddress()) ||
  zipCodeFormSelector(store.getState(), 'zipcode')

/**
 * @desc Boolean to determine if on-site contact is selected
 */
export const requiresOnSiteContact = () =>
  getValueFromContactForm('onSiteContact')

export default getPricingVariables

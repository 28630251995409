import React from 'react'
import PropTypes from 'prop-types'
import ICONS from '../../../../../../../../../../assets/icons'
import styles from './SelectableItemOption.module.scss'

const SelectableItemOption = ({ data, ...rest }) => (
  <div className={styles.box} data-tid="selectable-item-option-12499" {...rest}>
    <div className={styles.boxContent}>
      <img
        src={ICONS[data.icon]}
        alt={`LoadUp ${data.name}`}
        className={styles.img}
      />
      <p className={styles.label}>{data.name}</p>
    </div>
  </div>
)

SelectableItemOption.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SelectableItemOption

import React from 'react'
import PropTypes from 'prop-types'
import ItemRestriction from '../../../NewOrder/components/ItemRestrictionsModal/components/ItemRestriction'

const ItemRestrictions = ({ orderEntries }) =>
  orderEntries.length > 0 && (
    <div className="col-xs-12" style={{ margin: '15px auto' }}>
      <h3 style={{ fontSize: '1.17em', fontWeight: '600' }}>
        Item Restrictions
      </h3>
      {orderEntries.map(({ itemType }) => (
        <ItemRestriction key={itemType.id} {...itemType} />
      ))}
    </div>
  )

ItemRestrictions.propTypes = {
  orderEntries: PropTypes.array.isRequired,
}

ItemRestrictions.defaultProps = {
  orderEntries: [],
}

export default ItemRestrictions

import React from 'react'
import PropTypes from 'prop-types'
import formatAddress from '../../../../lib/locations/formatAddress'
import ContactListItem from './components/ContactListItem/contactListItem'
import { TIMESLOT_OPTIONS } from '../../../NewOrder/components/OrderAccordion/components/ScheduleSection'
import styles from './CustomerContactInfo.module.scss'

const CustomerContactInfo = ({ order }) => {
  const {
    customer: { email, firstName, lastName, primaryPhone, secondaryPhone },
    date,
    specialInstructions,
    timeslot,
  } = order

  return (
    <div className={styles.card}>
      <h3 className={styles.cardTitle}>Billing Contact</h3>
      <ContactListItem label="Name" value={`${firstName} ${lastName}`} />
      <ContactListItem label="Phone" value={primaryPhone} />
      <ContactListItem label="Secondary Phone" value={secondaryPhone} />
      <ContactListItem label="Email" value={email} />
      <ContactListItem label="Address" value={formatAddress(order)} />
      <ContactListItem label="Scheduled Date" value={date} />
      <ContactListItem
        label="Time Window"
        value={TIMESLOT_OPTIONS[timeslot].label}
      />
      <ContactListItem
        label="Special Instructions"
        value={specialInstructions}
      />
    </div>
  )
}

CustomerContactInfo.propTypes = {
  order: PropTypes.shape({
    customer: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      primaryPhone: PropTypes.string,
      secondaryPhone: PropTypes.string,
    }),
    date: PropTypes.string,
    specialInstructions: PropTypes.string,
    timeslot: PropTypes.string,
  }).isRequired,
}

export default CustomerContactInfo

const COUCH_DISPOSAL_PLUS_SUBSTRING = 'couch'
// const LOADUP_SUBSTRING = 'loadup'
// const LOCALHOST_SUBSTRING = 'localhost'
const MATTRESS_DISPOSAL_PLUS_SUBSTRING = 'mattress'

/**
 * @desc Determine whether running in a production environment
 */
export const isProductionEnvironment = () =>
  process.env.NODE_ENV === 'production'

/**
 * @desc Determines whether we're actually using a production host name.
 */
export const isProductionHost = () => window.location.host.includes('.com')

/**
 * @desc Get the host source by parsing the URL
 * @returns {*}
 */
export const getHostSource = () => {
  const { origin } = window.location
  // Case of Couch Disposal Plus
  if (origin.includes(COUCH_DISPOSAL_PLUS_SUBSTRING)) {
    return 'CDP'
    // Case of Mattress Disposal Plus
  } else if (origin.includes(MATTRESS_DISPOSAL_PLUS_SUBSTRING)) {
    return 'MDP'
  }
  // Case of LoadUp or Development (locahost)
  return 'LOADUP'
}

export const isSmallerThanXWidth = (width) => () => window.innerWidth <= width

export const toUpper = (value = '') => value.toUpperCase()

export const onlyNumbers = (value) => (value ? value.replace(/[^\d]/g, '') : '')

export const denyWeirdCharacters = (value) =>
  value ? value.replace(/^[-@./#&+\w\s]*$/g, '') : null

export const normalizeState = (value) => {
  if (!value) {
    return value
  }

  return value.replace(/[^a-zA-Z]/g, '').toUpperCase()
}

export const normalizeZip = (value) => {
  if (!value) {
    return value
  }

  const onlyNumbers = value.replace(/\D/g, '')

  if (onlyNumbers.length > 5) {
    return onlyNumbers
      .split('')
      .slice(0, 5)
      .join('')
  }

  return onlyNumbers
}

export const normalizePhone = (value, previousValue) => {
  // check for value
  if (!value) {
    return value
  }

  // strip out non-numberic characters
  let onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.startsWith('1')) {
    onlyNums = onlyNums.substr(1)
  }

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + ' '
    }

    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + ' ' + onlyNums.slice(3) + '-'
    }
  }

  if (onlyNums.length <= 3) {
    return onlyNums
  }

  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + ' ' + onlyNums.slice(3)
  }

  return (
    onlyNums.slice(0, 3) +
    ' ' +
    onlyNums.slice(3, 6) +
    '-' +
    onlyNums.slice(6, 10)
  )
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import ExplainerHeading from '../ExplainerHeading'
import DiscountAppliedRow from '../DiscountAppliedRow'
import avoidRenderingEmptyList from '../avoidRenderingEmptyList'

const DiscountsExplainer = ({ data }) => (
  <Fragment>
    <ExplainerHeading
      content="You've got Discounts"
      subcontent="It's the new 'You've got mail.'"
    />
    {data.map((item) => (
      <DiscountAppliedRow key={item.type} item={item} />
    ))}
  </Fragment>
)

DiscountsExplainer.propTypes = {
  data: PropTypes.array.isRequired,
}

DiscountsExplainer.defaultProps = {
  data: [
    { name: 'Full Day', amount: 5 },
    { name: 'Curbside Pickup', amount: 10 },
  ],
}

export default compose(avoidRenderingEmptyList)(DiscountsExplainer)

import BedFrameRemoval from './bed-frame-removal.svg'
import BedFrameSetRemoval from './bed-frame-set-removal.svg'
import BoxSpringRemoval from './box-spring-removal.svg'
import ChristmasTreeRemoval from './christmas-tree-removal.svg'
import CouchRemoval from './couch-removal.svg'
import DresserRemoval from './dresser-removal.svg'
import ExerciseEquipmentRemoval from './exercise-equipment-removal.svg'
import GrillRemoval from './grill-removal.svg'
import GenericJunkRemoval from './junk-removal.svg'
import MattressRemoval from './mattress-removal.svg'
import RefrigeratorRemoval from './refrigerator-removal.svg'

export default {
  BED_FRAME: BedFrameRemoval,
  BED_FRAME_SET: BedFrameSetRemoval,
  BOX_SPRING: BoxSpringRemoval,
  COUCH: CouchRemoval,
  DRESSER: DresserRemoval,
  CHRISTMAS_TREE: ChristmasTreeRemoval,
  GENERIC: GenericJunkRemoval,
  GRILL: GrillRemoval,
  TREADMILL: ExerciseEquipmentRemoval,
  MATTRESS: MattressRemoval,
  REFRIGERATOR_FULL_SIZE: RefrigeratorRemoval,
}

/**
 * Formats a number to always have two decimal places
 * @param num Number to format
 * @returns {num}
 */
export const toTwoDecimals = (num) => {
  if (num === 0) return '0'
  if (isNaN(num)) return num
  if (!num) return null
  return parseFloat(Math.round(num * 100) / 100).toFixed(2)
}

import React from 'react'
import styles from '../../../../../../../../../../styles'

const EmptyRemovalItemsDisclaimer = () => (
  <div
    className="col-xs-12 center-xs middle-xs"
    style={{
      backgroundColor: styles.colors.light0,
      borderRadius: 2,
      padding: 5,
      margin: '20px 0',
    }}
  >
    <p className="col-xs-12" style={{ fontSize: '1.2rem', fontWeight: 700 }}>
      No Items Selected
    </p>
    <p
      className="col-xs-12"
      style={{
        color: styles.colors.dark3,
        fontStyle: 'italic',
        fontSize: '1.3rem',
      }}
    >
      Select one of our most popular items, or search below.
    </p>
  </div>
)

export default EmptyRemovalItemsDisclaimer

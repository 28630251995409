import React from 'react'
import PropTypes from 'prop-types'
import CreateLeadForm from '../../../ZipCodeOutOfServiceModal/components/CreateLeadForm'

const WeCallYouDescription = ({ leadReason }) => (
  <div
    style={{ marginTop: '20px', background: '#f7f7f7', padding: '10px' }}
    className="col-xs-12"
  >
    <h4>Have Us Call You</h4>
    <CreateLeadForm leadReason={leadReason} />
  </div>
)

WeCallYouDescription.propTypes = {
  leadReason: PropTypes.oneOf([
    'ITEM_NOT_FOUND',
    'ZIP_NOT_FOUND',
    'TOO_MANY_ITEMS',
    'UNRECOVERABLE_ERROR',
  ]),
}

WeCallYouDescription.defaultProps = {
  leadReason: 'UNRECOVERABLE_ERROR',
}

export default WeCallYouDescription

import { Dispatch } from 'redux'
import { getMinAndMaxDateRange } from '../../../lib/dates/availableDates'
import { getZipCodeFromParsedAddress } from '../../../lib/forms/getPricingVariablesFromForm'
import { SELECTORS as sessionDataSelectors } from '../session'
import { AppState } from '../../rootReducer'
import { client } from '../../../components/hocs/withGraphQL'
import { handleSpecificError } from '../../../lib/errors/specificErrors'
import { query as EXCLUDED_DATES } from '../../../components/hocs/withExcludedDates'

const SET_EXCLUDED_DATES = 'retail/excludedDates/setExcludedDates'
const SET_EXCLUDED_DATES_LOADING =
  'retail/excludedDates/setExcludedDatesLoading'
const SET_EXCLUDED_DATES_ERROR = 'retail/excludedDates/setExcludedDatesError'

export type ExcludedDatesState = Readonly<{
  excludedDates: string[]
  error: string | null
  loading: boolean
}>

type Action = Readonly<{
  type: string
}> &
  any

const actions = {
  setExcludedDates: (excludedDates: string[]) => ({
    type: SET_EXCLUDED_DATES,
    excludedDates,
  }),
  setExcludedDatesError: (error: string | null) => ({
    type: SET_EXCLUDED_DATES_ERROR,
    error,
  }),
  setExludedDatesLoading: (loading: boolean) => ({
    type: SET_EXCLUDED_DATES_LOADING,
    loading,
  }),
}

const initialState: ExcludedDatesState = {
  excludedDates: [],
  error: null,
  loading: false,
}

export const reducer = (
  state: ExcludedDatesState = initialState,
  action: Action
): ExcludedDatesState => {
  switch (action.type) {
    case SET_EXCLUDED_DATES: {
      return {
        ...state,
        excludedDates: action.excludedDates,
      }
    }
    case SET_EXCLUDED_DATES_ERROR: {
      return {
        ...state,
        error: action.error,
      }
    }
    case SET_EXCLUDED_DATES_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    default:
      return state
  }
}

export const selectors = {
  excludedDates: (state: AppState) => state.excludedDates.excludedDates,
  error: (state: AppState) => state.excludedDates.error,
  loading: (state: AppState) => state.excludedDates.loading,
}

export const thunks = {
  fetchExcludedDates: () => async (
    dispatch: Dispatch,
    getState: () => AppState
  ) => {
    const orderSessionUuid = sessionDataSelectors.getSessionIdentifier(
      getState()
    )
    const inputs = {
      ...getMinAndMaxDateRange(),
      zip: getZipCodeFromParsedAddress(),
      orderSessionUuid,
    }
    try {
      dispatch(actions.setExludedDatesLoading(true))
      const {
        data: { excludedDates },
      } = await client.query({
        query: EXCLUDED_DATES,
        variables: { inputs },
        fetchPolicy: 'network-only',
      })
      dispatch(actions.setExcludedDates(excludedDates))
    } catch (error) {
      dispatch(actions.setExcludedDatesError(error))
      handleSpecificError(error)
    } finally {
      dispatch(actions.setExludedDatesLoading(false))
    }
  },
}

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import withPaymentErrorModalData from './components/withPaymentErrorModalData'

const PaymentErrorModal = ({
  paymentCopy,
  isPaymentErrorModalVisible,
  togglePaymentErrorModal,
}) => (
  <CenteredModal
    showCloseIcon
    open={isPaymentErrorModalVisible}
    onClose={() => {
      togglePaymentErrorModal(false)
    }}
  >
    <div className="col-xs-12 center-xs">
      <h3 style={{ color: '#DC2E48' }}>Issue with your Payment</h3>
      <p style={{ margin: '5px auto' }}>{paymentCopy}</p>
    </div>
  </CenteredModal>
)

PaymentErrorModal.propTypes = {
  paymentCopy: PropTypes.string.isRequired,
  isPaymentErrorModalVisible: PropTypes.bool.isRequired,
  togglePaymentErrorModal: PropTypes.func.isRequired,
}

export default compose(withPaymentErrorModalData)(PaymentErrorModal)

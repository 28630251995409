import React from 'react'
import EmailLink from '../../../../components/ui/typography/EmailLink'
import PhoneLink from '../../../../components/ui/typography/PhoneLink'

const SeeSomethingWrongDescription = () => (
  <div className="col-xs-12">
    <p style={{ fontWeight: 600 }}>See Something Wrong?</p>
    <p>
      Call our dedicated Customer Support team at <PhoneLink /> or <EmailLink />{' '}
      to get it fixed.
    </p>
  </div>
)

export default SeeSomethingWrongDescription

/* eslint-disable camelcase */
import * as React from 'react'
import { ic_textsms } from 'react-icons-kit/md/ic_textsms'
import styles from './TextForEstimate.module.css'
import Icon from 'react-icons-kit'

export default function TextForEstimate() {
  return (
    <div className={styles.container}>
      <p className={styles.mainText}>
        Do you have a pile of junk or a random assortment of items? Text us a
        picture of your junk pile and get an upfront quote.
      </p>
      <p className={styles.subtitleText}>
        <Icon icon={ic_textsms} size={20} style={{ color: '#222' }} />
        <span className={styles.textPrompt}>Text a pic to... </span>
        <span className={styles.phoneNumber}>
          <b>(404) 795-6030</b>
        </span>
      </p>
    </div>
  )
}

import React from 'react'

const GuaranteedPriceExplainer = () => (
  <div style={{ padding: '10px' }} className="row col-xs-12 center-xs">
    <p>
      <sup>*</sup>
      {
        "Your Guaranteed Price is based on the items and site details you've selected."
      }
    </p>
  </div>
)

export default GuaranteedPriceExplainer

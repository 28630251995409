import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'react-icons-kit'
import { check } from 'react-icons-kit/feather/check'
import styles from './SelectableExplainer.module.scss'

const SelectableExplainer = ({ active, content, onClick, title, subtitle }) => (
  <div
    onClick={onClick}
    className={`col-xs-12 middle-xs row ${styles.box} ${
      active ? `${styles.borderSuccess}` : ''
    }`}
  >
    {active && (
      <div className="col-xs-1">
        <Icon icon={check} size={24} />
      </div>
    )}
    <div className="col-xs-11">
      <p
        style={{ fontWeight: 'bold', fontSize: '1.3rem' }}
        className={active ? 'text-success' : ''}
      >
        {title}{' '}
        {subtitle && (
          <span
            style={{
              fontSize: '1rem',
              fontWeight: '300',
            }}
          >
            ({subtitle})
          </span>
        )}
      </p>
      <p style={{ fontSize: '1.3rem', lineHeight: '1.8rem' }}>{content}</p>
    </div>
  </div>
)

SelectableExplainer.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}

export default SelectableExplainer

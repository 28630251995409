import React from 'react'
import PropTypes from 'prop-types'
import EmailLink from '../../../../components/ui/typography/EmailLink'
import PhoneLink from '../../../../components/ui/typography/PhoneLink'
import copy from '../../../../lib/copy'

/**
 * @desc Formats the confirmation message in the description with the user params
 * @param firstName
 * @param email
 */
const formatMessage = ({ firstName, email }) => (
  <h5 className="col-xs-12" style={{ marign: '5px auto' }}>
    <b>{firstName}</b>, your junk removal booking is <b>confirmed</b>. You will
    receive a confirmation email at <b>{email}</b> shortly. Thanks for putting
    your trust in {copy().name}.
  </h5>
)

formatMessage.propTypes = {
  email: PropTypes.string,
  firstName: PropTypes.string,
}

const OrderMetaDescription = ({ order }) => (
  <div className="row col-xs-12">
    <h2
      style={{ fontSize: '1.5em', fontWeight: '600' }}
      className="text-success col-xs-12"
    >
      We look forward to providing you a great junk removal experience.
    </h2>
    {formatMessage(order)}
    <div className="row col-xs-12" style={{ margin: '20px auto', padding: 0 }}>
      <div className="col-md-6 col-xs-12" style={{ padding: 0 }}>
        <h2 style={{ fontSize: '1.5em', fontWeight: '600' }}>
          <span>Confirmation Number: </span>
          {order.id}
        </h2>
      </div>
      <div className="col-md-6 col-xs-12 end-md">
        <h6>Questions?</h6>
        <h6>
          Call <PhoneLink />
        </h6>
        <h6>
          or <EmailLink />
        </h6>
      </div>
    </div>
  </div>
)

OrderMetaDescription.propTypes = {
  order: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
}

export default OrderMetaDescription

import React from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { change } from 'redux-form'
import { compose } from 'recompose'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import withFinalServiceLocationData from '../../../../components/hocs/withFinalServiceLocationData'
import copy from '../../../../lib/copy'
import {
  ACTIONS as modalActions,
  SELECTORS as modalSelectors,
} from '../../../../store/ducks/modals'
import ConfirmNewZipForm from './ConfirmNewZipForm'
import styles from './FinalServiceLocationUnavailableModal.module.css'

const HEADER_COPY = 'Hmm...that looks like a different address'
const ZIP_CODE_EXPLANATION_COPY =
  "To make sure we get you the best pricing and availability information, let's double check your zip code"
const CALL_US_COPY =
  "If your zip code isn't in our core service area, don't worry! We can often still book you over the phone"

const FinalServiceLocationUnavailableModal = ({
  changeZipField,
  clearPickupAddressField,
  clearAddressline1Field,
  clearUnitNumberField,
  clearCityField,
  clearStateField,
  isFinalLocationValid,
}) => {
  const dispatch = useDispatch()

  const [zipIsAvailable, setZipIsAvailable] = React.useState(null)
  const [updatedZip, setUpdatedZip] = React.useState(null)

  const isInvalidFinalServiceLocationModalVisible = useSelector(
    modalSelectors.isInvalidFinalServiceLocationModalVisible
  )

  const handleCloseInvalidFinalServiceModal = React.useCallback(() => {
    dispatch(modalActions.toggleInvalidFinalServiceLocationModalVisible(false))
  }, [dispatch])

  const handleZipCompleted = React.useCallback(
    ({ available, zip }) => {
      setZipIsAvailable(Boolean(available))
      setUpdatedZip(zip)
    },
    [setZipIsAvailable, setUpdatedZip]
  )

  const handleSaveCorrectedZip = React.useCallback(
    (event) => {
      event.preventDefault()
      changeZipField(updatedZip)
      clearPickupAddressField()
      clearAddressline1Field()
      clearUnitNumberField()
      clearCityField()
      clearStateField()
      handleCloseInvalidFinalServiceModal()
      setZipIsAvailable(null)
    },
    [
      changeZipField,
      clearPickupAddressField,
      clearAddressline1Field,
      clearUnitNumberField,
      clearCityField,
      clearStateField,
      handleCloseInvalidFinalServiceModal,
      setZipIsAvailable,
      updatedZip,
    ]
  )

  return (
    <CenteredModal
      blockScroll={false}
      open={!isFinalLocationValid && isInvalidFinalServiceLocationModalVisible}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      focusTrapped
    >
      <div className={styles.container}>
        <h3 className={`text-warning ${styles.header}`}>{HEADER_COPY}</h3>
        <p className={styles.copy}>{ZIP_CODE_EXPLANATION_COPY}</p>
        <ConfirmNewZipForm onCompleted={handleZipCompleted} />
        {zipIsAvailable === false && (
          <p className={styles.outOfServiceText}>This Zip is Not in Service</p>
        )}
        {zipIsAvailable === true && (
          <>
            <p className={styles.inServiceText}>This Zip is In Service</p>
            <button
              className={`btn btn-success ${styles.useZipButton}`}
              onClick={handleSaveCorrectedZip}
              aria-label="Use this zip code and close modal"
            >
              Use This Zip
            </button>
          </>
        )}
        <p className={styles.copy}>{CALL_US_COPY}</p>
        <h3 className={styles.phoneNumber}>{copy().phone}</h3>
      </div>
    </CenteredModal>
  )
}

FinalServiceLocationUnavailableModal.propTypes = {
  changeZipField: PropTypes.func.isRequired,
  clearPickupAddressField: PropTypes.func.isRequired,
  clearAddressline1Field: PropTypes.func.isRequired,
  clearUnitNumberField: PropTypes.func.isRequired,
  clearCityField: PropTypes.func.isRequired,
  clearStateField: PropTypes.func.isRequired,
  isFinalLocationValid: PropTypes.bool.isRequired,
}

const mapDispatch = {
  changeZipField: (value) => change('contactForm', 'zip', value),
  clearPickupAddressField: () => change('contactForm', 'pickupAddress', ''),
  clearAddressline1Field: () => change('contactForm', 'addressline1', ''),
  clearUnitNumberField: () => change('contactForm', 'unitNumber', ''),
  clearCityField: () => change('contactForm', 'city', ''),
  clearStateField: () => change('contactForm', 'state', ''),
}

export default compose(
  connect(null, mapDispatch),
  withFinalServiceLocationData
)(FinalServiceLocationUnavailableModal)

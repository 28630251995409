import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from './components/hocs/withGraphQL'
import { SOURCE_VERSION } from './lib/revision'
import Routes from './routes'
import './vendorStyles'
import { Provider } from 'react-redux'
import store from './store/configureStore'
import { configureTracking } from './lib/tracking'

const App = () => {
  React.useEffect(() => {
    console.log('SOURCE_VERSION:', SOURCE_VERSION)
    configureTracking()
  })

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <div className="bg-white order-form">
          <Routes />
        </div>
      </Provider>
    </ApolloProvider>
  )
}

export default App

import { createSelector } from 'reselect'

export const ACTION_TYPES = {
  ADD_ITEM: 'loadup/activeRemovalItems/ADD_ITEM',
  REMOVE_ITEM: 'loadup/activeRemovalItems/REMOVE_ITEM',
}

export const initialState = {
  activeItems: [],
}

export default (reduxState = initialState, { payload, type }) => {
  switch (type) {
    case ACTION_TYPES.ADD_ITEM:
      return {
        activeItems: reduxState.activeItems.concat(payload.item),
      }
    case ACTION_TYPES.REMOVE_ITEM:
      return {
        activeItems: reduxState.activeItems.filter(
          ({ id }) => id !== payload.item.id
        ),
      }
    default:
      return reduxState
  }
}

const addActiveRemovalItem = (item) => ({
  type: ACTION_TYPES.ADD_ITEM,
  payload: { item },
})

const removeActiveRemovalItem = (item) => ({
  type: ACTION_TYPES.REMOVE_ITEM,
  payload: { item },
})

export const ACTIONS = {
  addActiveRemovalItem,
  removeActiveRemovalItem,
}

const getState = ({ activeRemovalItems }) => activeRemovalItems

const getActiveItems = createSelector(
  getState,
  ({ activeItems }) => activeItems
)

export const SELECTORS = {
  getActiveItems,
}

import React from 'react'
import copy from '../../../../lib/copy'

const PhoneLink = () => (
  <a className="text-success font-weight-bold" href={`tel:${copy().phone}`}>
    {copy().phone}
  </a>
)

export default PhoneLink

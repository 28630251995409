import React from 'react'
import { useDispatch } from 'react-redux'
import ActionLink from '../../../../../../../../../../components/ui/typography/ActionLink/index'
import copy from '../../../../../../../../../../lib/copy'
import styles from '../../../../../../../../../../styles'
import { ACTIONS as modalActions } from '../../../../../../../../../../store/ducks/modals'

const TOSLink = () => (
  <a
    className="text-success"
    href={copy().tos}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: styles.colors.green0 }}
  >
    Terms of Service
  </a>
)

const smsPolicyAlert = () => {
  window.alert(
    'Note: You may receive SMS texts from LoadUp drivers while they are en route to your destination. ' +
      'LoadUp will never sell your information to anyone.'
  )
}

const DisclaimerLabel = () => {
  const dispatch = useDispatch()

  const handleOpenItemRestrictions = React.useCallback(() => {
    dispatch(modalActions.toggleItemRestrictionsModal(true))
  }, [dispatch])

  const handleOpenCancellation = React.useCallback(() => {
    dispatch(modalActions.toggleCancellationPolicyModal(true))
  }, [dispatch])

  return (
    <span>
      {`I agree to ${copy().name}'s `}
      <ActionLink
        onClick={handleOpenCancellation}
        content="Cancellation Policy"
      />
      , <ActionLink onClick={smsPolicyAlert} content="SMS Policy" />
      , <TOSLink />, and{' '}
      <ActionLink
        onClick={handleOpenItemRestrictions}
        content="Item Restrictions"
      />
      .
    </span>
  )
}

export default DisclaimerLabel

import { gql } from 'apollo-boost'
import pickBy from 'lodash/fp/pickBy'
import identity from 'lodash/fp/identity'
import {
  getOrderCreationVariables,
  getPromoCode,
  getValueFromContactForm,
} from '../../../lib/forms/getPricingVariablesFromForm'

export const createFormValues = ({
  contactFormValues,
  disassemblyItemsFormValues,
  flightsOfStairsFormValues,
  orderFormValues,
  pickupLocationFormValues,
  promoCodeFormValues,
  removalItemsFormValues,
  scheduleFormValues,
}: any) => ({
  contactFormValues,
  disassemblyItemsFormValues,
  flightsOfStairsFormValues,
  orderFormValues,
  pickupLocationFormValues,
  promoCodeFormValues,
  removalItemsFormValues,
  scheduleFormValues,
})

export const updateOrderSessionData = ({ zipCodeFormValues = {} }: any) => {
  const {
    addressline1,
    city,
    customerEmail,
    customerFirstName,
    customerLastName,
    date,
    elevatorRestriction,
    flightsOfStairsCount,
    items,
    orderEmail,
    orderFirstName,
    orderLastName,
    placement,
    specialInstructions,
    state,
    timeslot,
    unitNumber,
    zip,
  } = getOrderCreationVariables({ stripeCardToken: null, pricingDetails: {} })
  const rawData = {
    addressline1,
    city,
    customerEmail,
    customerFirstName,
    customerLastName,
    customerPrimaryPhone: getValueFromContactForm('customerPrimaryPhone'),
    customerSecondaryPhone: getValueFromContactForm('customerSecondaryPhone'),
    date,
    elevatorRestriction,
    flightsOfStairsCount,
    items,
    orderEmail,
    emailConsent: getValueFromContactForm('emailConsent'),
    orderFirstName,
    orderLastName,
    orderPrimaryPhone: getValueFromContactForm('orderPrimaryPhone'),
    orderSecondaryPhone: getValueFromContactForm('orderSecondaryPhone'),
    placement,
    promoCode: getPromoCode(),
    specialInstructions,
    state,
    timeslot,
    unitNumber,
    zip: zip || zipCodeFormValues.zipCode,
  }

  return pickBy(identity, rawData)
}

export const CREATE_ORDER_SESSION = gql`
  mutation CreateOrderSession($inputs: CreateOrderSessionInputs!) {
    createOrderSession(inputs: $inputs) {
      errorCode
      orderSession {
        uuid
      }
    }
  }
`

export const UPDATE_SESSION = gql`
  mutation UpdateOrderSession($inputs: UpdateOrderSessionInputs!) {
    updateOrderSession(inputs: $inputs) {
      errorCode
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../../../styles'

const borderStyle = `2px solid ${styles.colors.dark0}`

const PricingInfo = ({ order }) => {
  const total = (order.transactionTotal || 0).toFixed(2)
  return (
    <div
      className="row col-xs-12"
      style={{
        borderTop: borderStyle,
        borderBottom: borderStyle,
        padding: '10px 0',
        marginBottom: '10px',
      }}
    >
      <div className="col-sm-8 col-xs-6">
        <p
          style={{
            fontSize: '2.5rem',
            fontWeight: '900',
            lineHeight: '2.5rem',
          }}
        >
          Order Total
        </p>
      </div>
      <div className="col-sm-4 col-xs-6 end-sm">
        <p
          style={{
            fontSize: '2.5rem',
            fontWeight: '900',
            lineHeight: '2.5rem',
          }}
        >
          ${total}
        </p>
      </div>
    </div>
  )
}

PricingInfo.propTypes = {
  order: PropTypes.shape({
    transactionTotal: PropTypes.number.isRequired,
  }),
}

export default PricingInfo

import React from 'react'
import omit from 'lodash/fp/omit'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { getRemovalItems } from '../../../../../../../../lib/forms/getPricingVariablesFromForm'
import createChangeValueAction from '../../../../../../../../lib/forms/createChangeValueAction'
import {
  ACTIONS as activeRemovalItemsActions,
  SELECTORS as activeRemovalItemsSelectors,
} from '../../../../../../../../store/ducks/activeRemovalItems'
import ActionLink from '../../../../../../../../components/ui/typography/ActionLink'
import withSyncFormErrors from '../../../../../../../../components/hocs/withSyncFormErrors'
import { ItemTypePropType } from '../../../../../../../../lib/types'
import renderItemCounterInput from '../../../../../../../../components/ui/forms/renderItemCounterInput'
import RenderSearchableAutocompleteInput from '../../../../../../../../components/ui/forms/renderSearchableAutocompleteInput'
import NextSectionButton from '../../../../../../../../components/ui/touchables/NextSectionButton'
import { sumGreaterThanZero } from '../../../../../../../../lib/forms/formValidators'
import { getSearchableItems, getSelectableItems } from './utils'
import EmptyRemovalItemsDisclaimer from './components/EmptyRemovalItemsDisclaimer/emptyRemovalItemsDisclaimer'
import SelectableItemOption from './components/SelectableItemOption'
import styles from './RemovalItemsForm.module.scss'
import { readItemTypes } from '../../../withRemovalItemsData'
import { ACTIONS as modalActions } from '../../../../../../../../store/ducks/modals'

const RemovalItemsForm = ({ handleSubmit, invalid, pristine, syncErrors }) => {
  const dispatch = useDispatch()
  const itemTypes = readItemTypes()

  const [submitted, setSubmitted] = React.useState(false)
  const attemptSubmit = React.useCallback(() => {
    setSubmitted(true)
  }, [setSubmitted])

  const addActiveItem = React.useCallback(
    (item) => {
      dispatch(activeRemovalItemsActions.addActiveRemovalItem(item))
      dispatch(
        createChangeValueAction({
          form: 'removalItemsForm',
          field: 'removalItems',
          value: { ...getRemovalItems(), [item.id]: 1 },
        })
      )
    },
    [dispatch]
  )

  const removeActiveItem = React.useCallback(
    (item) => {
      dispatch(
        createChangeValueAction({
          form: 'removalItemsForm',
          field: 'removalItems',
          value: omit([item.id], getRemovalItems()),
        })
      )
      dispatch(activeRemovalItemsActions.removeActiveRemovalItem(item))
    },
    [dispatch]
  )

  const activeItems = useSelector(activeRemovalItemsSelectors.getActiveItems)
  // Find all selectable items
  const selectableItems = getSelectableItems(itemTypes, activeItems)

  // Find all searchable items
  const searchableItems = getSearchableItems(itemTypes, activeItems)

  // Checks whether an error should be shown
  const shouldShowError = submitted && syncErrors.removalItems

  const openRepCallModal = React.useCallback(() => {
    dispatch(modalActions.toggleRepCallModal(true))
  }, [dispatch])

  return (
    <form
      className="row col-xs-12"
      onSubmit={handleSubmit}
      style={{ width: '100%' }}
    >
      <h5 className="col-xs-12">Most Popular Items</h5>
      <div
        className={`row ${styles.row} ${styles.border} ${shouldShowError &&
          `${styles.borderDanger}`}`}
      >
        {selectableItems.map((data) => (
          <div key={data.id} className="center-xs col-xs-6 col-sm-3">
            <SelectableItemOption
              data={data}
              onClick={() => addActiveItem(data)}
            />
          </div>
        ))}
      </div>
      {shouldShowError && (
        <div className="col-xs-12 invalid-error">Please Select an Item</div>
      )}
      <h5 className={`col-xs-12 ${styles.subSection}`}>
        {"Don't See Your Item?"}
      </h5>
      <div className="col-xs-12" data-tid="searchable-autocomplete-input-24693">
        <RenderSearchableAutocompleteInput
          placeholder="Start Typing Here..."
          input={{ onChange: addActiveItem }}
          value={null}
          options={searchableItems}
        />
      </div>
      <h6 className={`col-xs-12 ${styles.subSection}`}>
        Not sure what to book?{' '}
        <ActionLink onClick={openRepCallModal} content="Have a Rep Call You!" />
      </h6>
      {activeItems.length > 0 && (
        <h5 className={`col-xs-12 ${styles.myItems}`}>My Items</h5>
      )}
      <Field
        name="removalItems"
        component={renderItemCounterInput}
        options={activeItems}
        validate={(value) => sumGreaterThanZero(Object.values(value || {}))}
        onRemove={removeActiveItem}
      />
      {activeItems.length === 0 && <EmptyRemovalItemsDisclaimer />}
      <NextSectionButton
        className="col-xs-6 col-sm-3 col-sm-offset-9"
        disabled={invalid || pristine}
        type="submit"
        onClick={attemptSubmit}
      />
    </form>
  )
}

RemovalItemsForm.propTypes = {
  activeItems: PropTypes.array.isRequired,
  addActiveRemovalItem: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  itemTypes: PropTypes.arrayOf(ItemTypePropType).isRequired,
  removeActiveRemovalItem: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  syncErrors: PropTypes.object.isRequired,
}

RemovalItemsForm.defaultProps = {
  activeItems: [],
  itemTypes: [],
}

const validate = ({ removalItems }) => ({
  removalItems: sumGreaterThanZero(Object.keys(removalItems || {})),
})

const withForm = reduxForm({
  validate,
  form: 'removalItemsForm',
  destroyOnUnmount: false,
  onSubmit: (values, _, props) => {
    props.onFinishSection(0)
  },
})

export default compose(
  withForm,
  withSyncFormErrors('removalItemsForm')
)(RemovalItemsForm)

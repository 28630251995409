/**
 * @desc Checks if a section is active
 * @param activeSection
 * @param candidateSection
 */
export const isSectionActive = (activeSection, candidateSection) =>
  activeSection === candidateSection

/**
 * @desc Checks if a section is finished
 * @param finishedSections
 * @param candidateSection
 */
export const isSectionFinished = (finishedSections, candidateSection) =>
  finishedSections.includes(candidateSection)

/**
 * @desc Checks if a section is disabled
 * @param activeSection
 * @param candidateSection
 */
export const isSectionDisabled = (activeSection, candidateSection) =>
  candidateSection > activeSection

/**
 * @desc Provides a trackable name for each section
 */
export const ITEM_SELECTION_PAGE = 'ITEM_SELECTION_PAGE'
export const SCHEDULE_PAGE = 'SCHEDULE_PAGE'
export const ORDER_DETAILS_PAGE = 'ORDER_DETAILS_PAGE'
export const CONTACT_SECTION = 'CONTACT_SECTION'
export const BILLING_SECTION = 'BILLING_SECTION'
export const SECTION_NAMES = [
  ITEM_SELECTION_PAGE,
  SCHEDULE_PAGE,
  ORDER_DETAILS_PAGE,
  CONTACT_SECTION,
  BILLING_SECTION,
]

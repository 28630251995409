import { connect } from 'react-redux'
import { ACTIONS, SELECTORS } from '../../../../../../store/ducks/modals'

export default connect(
  (state) => ({
    invalidZipAttempts: SELECTORS.getInvalidZipAttempts(state),
  }),
  {
    setInvalidZipAttempts: ACTIONS.setInvalidZipAttempts,
  }
)

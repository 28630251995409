import { createSelector } from 'reselect'

export const ACTION_TYPES = {
  ZIP_CODE_SUCCESS: 'loadup/pageNavigation/ZIP_CODE_SUCCESS',
}

export const initialState = {
  initialZipCode: null,
  validZipCode: false,
}

export default (reduxState = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ZIP_CODE_SUCCESS:
      return {
        initialZipCode: action.zipCode,
        validZipCode: true,
      }
    default:
      return reduxState
  }
}

const setZipCodeSuccess = (zipCode) => ({
  type: ACTION_TYPES.ZIP_CODE_SUCCESS,
  zipCode,
})

export const ACTIONS = {
  setZipCodeSuccess,
}

const getState = (state) => state.pageNavigation

const isZipCodeValid = createSelector(
  getState,
  ({ validZipCode }) => validZipCode
)

const initialZipCode = createSelector(
  getState,
  ({ initialZipCode }) => initialZipCode
)

export const SELECTORS = {
  isZipCodeValid,
  initialZipCode,
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACTIONS as modalActions,
  SELECTORS as modalSelectors,
} from '../../../../store/ducks/modals'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'

const UPFRONT_CHARGE_COPY =
  "We charge upfront to reserve your Loader team on the scheduled date and time you've requested. Your price is guaranteed and will not change once onsite. This helps provide a smooth process at the time of pickup. No haggling and no awkward transactions."

const ChargeUpfrontModal = () => {
  const dispatch = useDispatch()

  const handleClose = React.useCallback(() => {
    dispatch(modalActions.toggleChargeUpfrontModal(false))
  }, [dispatch])
  const isChargeUpfrontModalVisible = useSelector(
    modalSelectors.isChargeUpfrontModalVisible
  )

  return (
    <CenteredModal
      blockScroll={false}
      open={isChargeUpfrontModalVisible}
      onClose={handleClose}
    >
      <div className="col-xs-12 center-xs" style={{ padding: '0 10px 40px' }}>
        <h2
          style={{
            fontWeight: '900',
            marginTop: '3rem',
            paddingBottom: '0.8em',
          }}
        >
          Why do we charge up front?
        </h2>
        <p>{UPFRONT_CHARGE_COPY}</p>
      </div>
    </CenteredModal>
  )
}

export default ChargeUpfrontModal

const DISCOUNT_ENUMS = {
  BED_SET_DISCOUNT: {
    en: 'Full Bed Set',
  },
  FULL_DAY_DISCOUNT: {
    en: 'Full Day',
  },
  CHRISTMAS_TREE_DISCOUNT: {
    en: 'Christmas Tree Discount',
  },
  OUTDOOR_PLACEMENT_DISCOUNT: {
    en: 'Curbside Pickup',
  },
  VOLUME_DISCOUNT: {
    en: 'Volume Discount',
  },
}

export const mapDiscountTypeToName = ({ type }) =>
  DISCOUNT_ENUMS[type] ? DISCOUNT_ENUMS[type].en : 'Discount'

import React, { Fragment, useCallback } from 'react'
import omit from 'lodash/fp/omit'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import {
  makeGetFirstAvailableDate,
  makeIsDayBlocked,
  DATE_FORMAT,
} from '../../../../lib/dates/availableDates'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import { isSmallerThanXWidth } from '../../../../lib/environment/environment'

interface Props {
  input: {
    value: string | Object
    onChange: () => any
  }
  disabledDates: Array<any>
  onChange: () => any
  meta: any
  externalError: any
}

function RenderCalendarDatePickerInput({
  input,
  disabledDates = [],
  onChange = () => {},
  ...rest
}: Props) {
  const [focused, setFocused] = React.useState(false)
  const pertinentProps = omit(['externalError', 'meta', 'onChange'], rest)
  const error = fieldIsInvalid(
    rest.meta,
    rest.externalError,
    'Please select a pickup date'
  )

  const withPortal = isSmallerThanXWidth(500)()
  // the lowercase function is confusing eslint here
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleFocusChange = useCallback(
    ({ focused }) => {
      setFocused(focused)
    },
    [setFocused]
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleDateChange = useCallback(
    (date) => {
      if (date) {
        // @ts-ignore
        input.onChange(moment(date).format(DATE_FORMAT))
        onChange()
      }
    },
    [input, onChange]
  )

  return (
    <Fragment>
      <div
        style={{ width: '95%' }}
        className={error ? 'border border-danger' : ''}
      >
        <SingleDatePicker
          hideKeyboardShortcutsPanel
          withPortal={withPortal}
          focused={focused}
          onFocusChange={handleFocusChange}
          numberOfMonths={1}
          // @ts-ignore
          initialVisibleMonth={makeGetFirstAvailableDate(disabledDates)}
          date={input.value ? moment(input.value) : null}
          onDateChange={handleDateChange}
          isDayBlocked={makeIsDayBlocked(disabledDates)}
          displayFormat="dddd, MMM D YYYY"
          readOnly
          {...pertinentProps}
        />
      </div>
      <div className="invalid-error">{error}</div>
    </Fragment>
  )
}

export default RenderCalendarDatePickerInput

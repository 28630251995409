import React from 'react'
import PropTypes from 'prop-types'

const ContactListItem = ({ label, value }) =>
  value && (
    <p style={{ fontSize: '1.4rem', fontWeight: 400 }}>
      <span
        style={{
          fontWeight: 600,
          paddingRight: '5px',
          textTransform: 'uppercase',
          color: '#595959',
          fontSize: '12px',
        }}
      >
        {label}:&nbsp;
      </span>
      {value}
    </p>
  )

ContactListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default ContactListItem

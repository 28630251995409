import React from 'react'
import get from 'lodash/fp/get'
import { compose } from 'recompose'
import { connect, useSelector } from 'react-redux'
import { selectors as pricingDetailsSelectors } from '../../../../../../store/ducks/pricingData'
import createChangeValueAction from '../../../../../../lib/forms/createChangeValueAction'
import NextSectionButton from '../../../../../../components/ui/touchables/NextSectionButton'
import DetailsSectionExpandableContainer from './components/DetailsSectionExpandableContainer/detailsSectionExpandableContainer'
import DisassemblyItemsForm from './components/DisassemblyItemsForm/disassemblyItemsForm'
import FlightsOfStairsForm from './components/FlightsOfStairsForm/flightsOfStairsForm'
import PickupLocationForm from './components/PickupLocationForm/pickupLocationForm'
import withOrderDetailsAccordionData from './components/withOrderDetailsAccordionData/withOrderDetailsAccordionData'
import { getDisassemblableItems } from './utils'

interface IProps {
  changeFormValue: Function
  isDisassemblyActive: boolean
  isStairsActive: boolean
  onFinishSection: Function
  toggleDisassembly: Function
  toggleStairs: Function
}

const DetailsSection = ({
  changeFormValue,
  isDisassemblyActive,
  isStairsActive,
  onFinishSection,
  toggleDisassembly,
  toggleStairs,
}: IProps) => {
  const pricingDetails = useSelector(pricingDetailsSelectors.pricingData)

  const disassemblableItems = getDisassemblableItems(pricingDetails.items)

  return (
    <div className="row" style={{ width: '100%' }}>
      <h3
        className="col-xs-12"
        style={{ fontSize: '1.5rem', color: '#3B3F3F' }}
      >
        In Home or Outdoor Pickup?
      </h3>
      <PickupLocationForm initialValues={{ placement: 'INDOOR' }} />
      <DetailsSectionExpandableContainer
        active={isStairsActive}
        content="Any flights of stairs?"
        subcontent="A flight of stairs is 6 or more steps inside or outside your home."
        onChange={(nextValue) => {
          changeFormValue({
            form: 'flightsOfStairsForm',
            field: 'flightsOfStairs',
            value: nextValue ? 1 : 0,
          })
          toggleStairs(nextValue)
        }}
      >
        <FlightsOfStairsForm
          unitPrice={get(['flightsOfStairsUnitPrice'], pricingDetails)}
          initialValues={{ flightsOfStairs: 0 }}
        />
      </DetailsSectionExpandableContainer>
      {disassemblableItems.length > 0 && (
        <DetailsSectionExpandableContainer
          active={isDisassemblyActive}
          content="Any items require disassembly?"
          onChange={(nextValue) => {
            if (!nextValue) {
              changeFormValue({
                form: 'disassemblyItemsForm',
                field: 'disassemblyItems',
                value: {},
              })
            }
            toggleDisassembly(nextValue)
          }}
        >
          <DisassemblyItemsForm
            initialValues={{ disassemblyItems: {} }}
            items={disassemblableItems}
          />
        </DetailsSectionExpandableContainer>
      )}
      <NextSectionButton
        className="col-xs-6 col-sm-3 col-sm-offset-9"
        onClick={onFinishSection}
        disabled={false}
      />
    </div>
  )
}

const withRedux = connect(null, {
  changeFormValue: createChangeValueAction,
})

// @ts-ignore
export default compose(withOrderDetailsAccordionData, withRedux)(DetailsSection)

import React from 'react'
import PropTypes from 'prop-types'

const PageLayout = ({ left, right }) => (
  <div className="row">
    <div className="col-sm-8 col-xs-12">{left}</div>
    <div
      className="col-sm-4 col-xs-12 sticky-container visible-sm"
      style={{ marginTop: 20, paddingLeft: '20px' }}
    >
      {right}
    </div>
  </div>
)

PageLayout.propTypes = {
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired,
}

export default PageLayout

const LENGTH_OF_FORMATTED_PHONE_NUMBER = 12

export const transformPhoneNumberValueToUSPhone = (phoneNumber = '') => {
  if (phoneNumber.length !== LENGTH_OF_FORMATTED_PHONE_NUMBER) return null
  return `+1${phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace('-', '')}`
}

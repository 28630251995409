/* eslint-disable camelcase */
const ADDRESS_COMPONENT_MAP: { [key: string]: string } = {
  street_number: 'short_name',
  route: 'short_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'short_name',
  postal_code: 'short_name',
}

export interface IAddress {
  street_number?: string
  route?: string
  locality?: string
  sublocality_level_1?: string
  administrative_area_level_1: string
  administrative_area_level_3?: string
  neighborhood?: string
  country: string
  postal_code: string
}

interface IMappedAddress {
  addressline1: string
  city: string
  state: string
  zip: string
  country: string
}

export function buildAddressComponents(addressData: IAddress): IMappedAddress {
  return {
    addressline1: `${addressData.street_number || ''} ${
      addressData.route || ''
    }`.trim(),
    city:
      addressData.locality ||
      addressData.sublocality_level_1 ||
      addressData.administrative_area_level_3 ||
      addressData.neighborhood ||
      '',
    state: addressData.administrative_area_level_1,
    zip: addressData.postal_code,
    country: addressData.country,
  }
}

export function extractAddressComponents(
  addressComponents: Array<any>
): IAddress {
  return addressComponents
    .filter((placeData) =>
      Object.keys(ADDRESS_COMPONENT_MAP).includes(placeData.types[0])
    )
    .reduce((acc, placeData) => {
      const type = placeData.types[0]
      const desiredDataKey = ADDRESS_COMPONENT_MAP[type]
      acc[type] = placeData[desiredDataKey]
      return acc
    }, {})
}

export const ACTION_TYPES = {
  SET_CURRENT_SECTION: 'loadup/section/SET_CURRENT_SECTION',
}

export const initialState = {
  currentSection: '',
}

export default (reduxState = initialState, { payload, type }) => {
  switch (type) {
    case ACTION_TYPES.SET_CURRENT_SECTION:
      return {
        ...reduxState,
        currentSection: payload.currentSection,
      }
    default:
      return reduxState
  }
}

const setCurrentSection = (currentSection) => ({
  payload: { currentSection },
  type: ACTION_TYPES.SET_CURRENT_SECTION,
})

export const ACTIONS = {
  setCurrentSection,
}

export const SELECTORS = {
  getCurrentSection(state) {
    return state.section.currentSection
  },
  isCurrentSection: (sectionName) => (state) =>
    SELECTORS.getCurrentSection(state) === sectionName,
}

import React from 'react'
import PropTypes from 'prop-types'
import ContactForm from './components/ContactForm/contactForm'

const ContactSection = (props) => <ContactForm {...props} />

ContactSection.propTypes = {
  onFinishSection: PropTypes.func.isRequired,
}

export default ContactSection

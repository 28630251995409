import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import WeCallYouDescription from '../UnrecoverableErrorModal/components/WeCallYouDescription'
import YouCallUsDescription from '../UnrecoverableErrorModal/components/YouCallUsDescription'
import withOutOfServiceModal from './components/withOutOfServiceModalData'

const ZipCodeOutOfServiceModal = ({
  isOutOfServiceModalVisible,
  toggleOutOfServiceModal,
}) => (
  <CenteredModal
    open={isOutOfServiceModalVisible}
    onClose={() => toggleOutOfServiceModal(false)}
  >
    <div className="col-xs-12 center-xs">
      <h3 style={{ marginTop: '30px' }}>
        {"We'll need some extra information for your quote."}
      </h3>
      <p style={{ margin: '5px auto' }}>
        {
          "Don't worry! Our (fully human) customer support specialists can help!"
        }
      </p>
      <YouCallUsDescription />
      <h4 style={{ margin: '5px auto' }}>Or</h4>
      <WeCallYouDescription leadReason="ZIP_NOT_FOUND" />
    </div>
  </CenteredModal>
)

ZipCodeOutOfServiceModal.propTypes = {
  isOutOfServiceModalVisible: PropTypes.bool.isRequired,
  toggleOutOfServiceModal: PropTypes.func.isRequired,
}

export default compose(withOutOfServiceModal)(ZipCodeOutOfServiceModal)

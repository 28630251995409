import { createSelector } from 'reselect'

export const ACTION_TYPES = {
  SET_COMPLETED_ORDER: 'loadup/orderConfirmation/SET_COMPLETED_ORDER',
}

export const initialState = {
  completedOrder: null,
}

export default (reduxState = initialState, { payload, type }) => {
  switch (type) {
    case ACTION_TYPES.SET_COMPLETED_ORDER:
      return {
        completedOrder: payload.order,
      }
    default:
      return reduxState
  }
}

const setCompletedOrder = (order) => ({
  type: ACTION_TYPES.SET_COMPLETED_ORDER,
  payload: { order },
})

export const ACTIONS = {
  setCompletedOrder,
}

const getState = ({ orderConfirmation }) => orderConfirmation

const getCompletedOrder = createSelector(
  getState,
  ({ completedOrder }) => completedOrder
)

export const SELECTORS = {
  getCompletedOrder,
}

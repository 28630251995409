import moment from 'moment'
import { gql } from 'apollo-boost'
import { client } from '../../../components/hocs/withGraphQL'
import { trackError } from '../../errors/specificErrors'

const mutationTemplate = gql`
  mutation CreateOrderSessionEvent($inputs: CreateOrderSessionEventInputs!) {
    createOrderSessionEvent(inputs: $inputs) {
      errorCode
    }
  }
`

export const graphQLTrack = async (event, { sessionIdentifier, ...rest }) => {
  try {
    const inputs = {
      data: JSON.stringify(rest),
      eventType: event,
      firedAt: moment().toISOString(),
      uuid: sessionIdentifier,
    }

    await client.mutate({
      mutation: mutationTemplate,
      variables: { inputs },
    })
  } catch (error) {
    trackError(error)
  }
}

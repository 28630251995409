import { Dispatch } from 'redux'
import get from 'lodash/fp/get'
import { isZipCode } from '../../../lib/forms/formValidators'
import { client } from '../../../components/hocs/withGraphQL'
import { getPricingVariables } from '../../../lib/forms/getPricingVariablesFromForm'
import {
  SELECTORS as pricingDetailsSelectors,
  ACTIONS as pricingDetailsActions,
} from '../pricingDetails'
import { query } from '../../../components/hocs/withPricingData'
import { ACTIONS } from '../finalServiceLocation'
import { handleGraphQLErrors } from '../../../lib/errors/specificErrors'

const SET_PRICING_DATA = 'retail/pricingData/setPricingData'
const SET_PRICING_DATA_LOADING = 'retail/pricingData/setPricingDataLoading'
const SET_PRICING_DATA_ERROR = 'retail/pricingData/setPricingDataError'

export type PricingDataState = Readonly<{
  pricingData: any
  error: string | null
  loading: boolean
}>

type Action = Readonly<{
  type: string
}> &
  any

export const actions = {
  setPricingData: (pricingData: any) => ({
    type: SET_PRICING_DATA,
    pricingData,
  }),
  setPricingDataError: (error: string | null) => ({
    type: SET_PRICING_DATA_ERROR,
    error,
  }),
  setPricingDataLoading: (loading: boolean) => ({
    type: SET_PRICING_DATA_LOADING,
    loading,
  }),
}

const initialState = {
  pricingData: {},
  error: null,
  loading: false,
}

export const reducer = (
  state: PricingDataState = initialState,
  action: Action
): PricingDataState => {
  switch (action.type) {
    case SET_PRICING_DATA: {
      return {
        ...state,
        pricingData: action.pricingData,
      }
    }
    case SET_PRICING_DATA_ERROR: {
      return {
        ...state,
        error: action.error,
      }
    }
    case SET_PRICING_DATA_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    default:
      return state
  }
}
interface AppState {
  pricingData: PricingDataState
}
export const selectors = {
  pricingData: (state: AppState) => state.pricingData.pricingData,
  error: (state: AppState) => state.pricingData.error,
  loading: (state: AppState) => state.pricingData.loading,
  asapSchedulingAllowed: (state: AppState) =>
    state.pricingData.pricingData.asapSchedulingAllowed,
  flightsOfStairsCount: (state: AppState) =>
    state.pricingData.pricingData?.flightsOfStairsCount,
}

export const thunks = {
  fetchPricingData: () => async (dispatch: Dispatch, getState: any) => {
    const isPromoCodeValid = pricingDetailsSelectors.isPromoCodeValid(
      getState()
    )

    dispatch(actions.setPricingDataLoading(true))

    try {
      // Get variables
      const inputs = getPricingVariables(
        {},
        // eslint-disable-next-line react/prop-types
        isPromoCodeValid
      )
      const promoCode = isPromoCodeValid
        ? get('form.promoCodeForm.values.promoCode', getState())
        : null

      // Don't even ask for pricing details without a valid zip
      const zipIsInvalid = isZipCode(inputs.zip)
      if (zipIsInvalid) {
        return
      }

      // Make query
      const {
        data: { pricingDetails },
      } = await client.query({
        query,
        variables: {
          inputs: {
            ...inputs,
            promoCode,
          },
        },
        fetchPolicy: 'cache-first',
      })

      if (pricingDetails.total !== null && pricingDetails.total !== undefined) {
        dispatch(ACTIONS.setFinalLocationValid())
      }

      if (!pricingDetails.allowedDate) {
        throw new Error('DATE_NOT_ALLOWED')
      }

      dispatch(
        pricingDetailsActions.toggleValidPromoCode(pricingDetails.promoApplied)
      )
      dispatch(actions.setPricingData(pricingDetails))
    } catch (error) {
      dispatch(actions.setPricingDataError(error.message))
      handleGraphQLErrors(error)
    } finally {
      dispatch(actions.setPricingDataLoading(false))
    }
  },
  fetchPromoData: () => async (dispatch: Dispatch, getState: any) => {
    dispatch(actions.setPricingDataLoading(true))

    try {
      // Get variables
      const inputs = getPricingVariables({}, true)
      const promoCode = get('form.promoCodeForm.values.promoCode', getState())

      // Don't even ask for pricing details without a valid zip
      const zipIsInvalid = isZipCode(inputs.zip)
      if (zipIsInvalid) {
        return
      }

      // Make query
      const {
        data: { pricingDetails },
      } = await client.query({
        query,
        variables: {
          inputs: {
            ...inputs,
            promoCode,
          },
        },
      })

      dispatch(
        pricingDetailsActions.toggleValidPromoCode(pricingDetails.promoApplied)
      )

      if (pricingDetails.promoApplied) {
        dispatch(actions.setPricingData(pricingDetails))
      }
    } catch (error) {
      dispatch(actions.setPricingDataError(error.message))
      handleGraphQLErrors(error)
    } finally {
      dispatch(actions.setPricingDataLoading(false))
    }
  },
}

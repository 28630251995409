import React from 'react'
import PropTypes from 'prop-types'

const ItemRestriction = ({ detail, name }) =>
  detail && (
    <div style={{ marginTop: 10 }}>
      <p>
        <span style={{ fontWeight: 'bold' }}>{name}:</span> {detail}
      </p>
    </div>
  )

ItemRestriction.propTypes = {
  detail: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default ItemRestriction

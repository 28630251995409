import React from 'react'

const SERVICE_NOTICE_WARNING_COPY =
  'We do not guarantee pickup of mattresses, furniture, or other items with bed bug infestations or that are water logged. We reserve the right to refuse service if there is an active infestation due to potential risk of contaminating other mattresses,furniture items, and our trucks. We do not issue refunds if a pickup attempt is made and bed bugs are present. (Mattress Disposal Bags do not guarantee prevention.)'

const SERVICE_TEAM_NOTICE_COPY =
  "Our team will call to confirm on the day of your pickup. If you do not respond, this may result in a rescheduling of your pickup. We do our absolute best to accommodate all time-frames selected. However, due to unforeseen circumstances (i.e. weather, routing distance, truck issues, etc...), the time frame could be altered. We will contact you immediately if that is to happen. By submitting your booking, you agree to Mattress Disposal Plus's standard Terms and Conditions of Service."

const ServiceNotice = () => (
  <div className="col-xs-12" style={{ margin: '15px auto' }}>
    <h3 style={{ fontSize: '1.17em', fontWeight: '600' }}>Service Notice</h3>
    <p>{SERVICE_NOTICE_WARNING_COPY}</p>
    <p>{SERVICE_TEAM_NOTICE_COPY}</p>
  </div>
)

export default ServiceNotice

import React from 'react'
import PropTypes from 'prop-types'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid'
import styles from './RenderTextareaInput.module.scss'

const renderTextareaInput = ({
  className,
  input,
  label,
  limit,
  meta,
  ...rest
}) => {
  const error = fieldIsInvalid(meta)
  return (
    <div className={className}>
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        maxLength={limit}
        className={`col-xs-12 ${styles.textArea} ${error && 'is-invalid'}`}
        {...input}
        {...rest}
      />
      <span className="text-danger">
        {input.value ? input.value.length : 0}/{limit}
      </span>
      <div className="invalid-feedback">{error}</div>
    </div>
  )
}

renderTextareaInput.propTypes = {
  className: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  limit: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
}

renderTextareaInput.defaultProps = {
  className: '',
  limit: 200,
}

export default renderTextareaInput

import * as React from 'react'
import { extractAddressComponents } from '../../../../lib/addresses'
import styles from './RenderAddressAutocompleteInput.module.css'

interface IProps {
  className?: string
  name?: string
  label?: string
  onChangeText: (value: any) => void
  input: {
    onChange: (value: any) => void
    value: any
  }
  externalError: string | null
}

const ELEM_ID = 'google-maps-picker-45cbd4994cd3'

export default function RenderAddressAutocompleteInput(props: IProps) {
  const { input, onChangeText } = props

  React.useEffect(() => {
    // @ts-ignore
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(ELEM_ID),
      { types: ['geocode'] }
    )

    autocomplete.setFields(['address_components'])

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      const mappedPlaceData = extractAddressComponents(
        place['address_components']
      )
      onChangeText(mappedPlaceData)
    })
  }, [onChangeText])

  const { externalError, label, name = '', className } = props

  return (
    <div className={`${styles.input} ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        id={ELEM_ID}
        name={name}
        className={`form-control ${Boolean(externalError) && 'is-invalid'}`}
        type="text"
        {...input}
      />
      {externalError && <div className="invalid-error">{externalError}</div>}
    </div>
  )
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import ExplainerHeading from '../ExplainerHeading'
import ItemQuantityRow from '../ItemQuantityRow'
import avoidRenderingEmptyList from '../avoidRenderingEmptyList'

const AssemblyExplainer = ({ data }) => (
  <Fragment>
    <ExplainerHeading
      content="Items for Assembly"
      subcontent="These are items you've requested be assembled at your sweet pad."
    />
    {data.map((item) => (
      <ItemQuantityRow key={item.name} unitPriceKey="unitPrice" item={item} />
    ))}
  </Fragment>
)

AssemblyExplainer.propTypes = {
  data: PropTypes.array.isRequired, // @TODO Use actual Shape
}

AssemblyExplainer.defaultProps = {
  data: [1],
}

export default compose(avoidRenderingEmptyList)(AssemblyExplainer)

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACTIONS as modalActions,
  SELECTORS as modalSelectors,
} from '../../../../store/ducks/modals'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'

const CancellationPolicyModal = () => {
  const dispatch = useDispatch()
  const handleClose = React.useCallback(() => {
    dispatch(modalActions.toggleCancellationPolicyModal(false))
  }, [dispatch])
  const isCancellationPolicyModalVisible = useSelector(
    modalSelectors.isCancellationPolicyModalVisible
  )

  return (
    <CenteredModal
      onClose={handleClose}
      open={isCancellationPolicyModalVisible}
    >
      <div className="col-xs-12 center-xs" style={{ padding: '0 10px 40px' }}>
        <h2
          style={{
            fontWeight: '900',
            marginTop: '3rem',
            paddingBottom: '0.8em',
          }}
        >
          Cancellation Policy
        </h2>
        <p>
          A cancellation fee of 50% applies for cancellations made less than 24
          hours in advance. A cancellation fee of 20% applies for cancellations
          made 24 or more hours in advance.
        </p>
      </div>
    </CenteredModal>
  )
}

export default CancellationPolicyModal

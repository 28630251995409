import React from 'react'
import PropTypes from 'prop-types'
import fieldIsInvalid from '../../../../lib/forms/fieldIsInvalid/index'
import styles from './RenderSelectInput.module.scss'

const renderSelectInput = ({
  className,
  defaultOption,
  externalError,
  input,
  label,
  meta,
  options,
}) => {
  const error = fieldIsInvalid(meta, externalError)
  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <select
        className={styles.customSelect}
        onChange={(value) => input.onChange(value)}
        value={input.value}
      >
        {defaultOption && (
          <option value="" disabled selected>
            {defaultOption}
          </option>
        )}
        {options.map((item) => (
          <option key={item.key} value={item.key}>
            {item.label}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">{error}</div>
    </div>
  )
}

const OptionPropType = PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.string,
})

renderSelectInput.propTypes = {
  className: PropTypes.string,
  defaultOption: PropTypes.string,
  externalError: PropTypes.any,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  }),
  label: PropTypes.string,
  meta: PropTypes.any,
  options: PropTypes.arrayOf(OptionPropType).isRequired,
}

renderSelectInput.defaultProps = {
  className: '',
  options: [],
}

export default renderSelectInput

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ExplainerHeading from '../ExplainerHeading'

const PromoCodeExplainer = ({
  promoApplied,
  promoCode,
  promoDiscountSubtotal,
}) =>
  promoApplied && (
    <Fragment>
      <ExplainerHeading
        content="Applied Promo Code"
        subcontent="You win this round."
      />
      <div className="row col-xs-12">
        <div className="col-sm-8 col-xs-12 text-success">
          Promo Code:{' '}
          <span className="font-weight-bold text-success">{promoCode}</span>
        </div>
        <div className="col-sm-4 col-xs-12 end-xs text-danger">
          -$
          {promoDiscountSubtotal}
        </div>
      </div>
    </Fragment>
  )

PromoCodeExplainer.propTypes = {
  promoApplied: PropTypes.bool.isRequired,
  promoCode: PropTypes.string,
  promoDiscountSubtotal: PropTypes.number,
}

export default PromoCodeExplainer

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BookingPreparation from './components/BookingPreparation/BookingPreparation'
import CustomerContactInfo from './components/CustomerContactInfo/customerContactInfo'
import DetailsLayout from './components/DetailsLayout/detailsLayout'
import OrderContactInfo from './components/OrderContactInfo/orderContactInfo'
import OrderMetaDescription from './components/OrderMetaDescription/orderMetaDescription'
import PricingInfo from './components/PricingInfo/pricingInfo'
import SeeSomethingWrongDescription from './components/SeeSomethingWrongDescription/seeSomethingWrongDescription'
import SuccessBanner from './components/SuccessBanner/successBanner'
import CancellationPolicy from './components/CancellationPolicy/CancellationPolicy'
import ServiceNotice from './components/ServiceNotice/serviceNotice'
import ItemRestrictions from './components/ItemRestrictions/itemRestrictions'
import ShareASaleConversionTracker from './components/ShareASaleConversionTracker'
import AdServerConversionTracker from './components/AdServerConversionTracker/AdServerConversionTracker'
import {
  getHostSource,
  isProductionEnvironment,
  isProductionHost,
} from '../../lib/environment/environment'

class BookingConfirmation extends Component {
  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0), 200)
  }

  render() {
    const { order } = this.props
    return (
      <div>
        {getHostSource() === 'LOADUP' &&
          isProductionEnvironment() &&
          isProductionHost() && <AdServerConversionTracker />}
        <SuccessBanner />
        <DetailsLayout>
          <OrderMetaDescription order={order} />
          <div className="row">
            <PricingInfo order={order} />
            <div className="col-sm-6 col-xs-12">
              <CustomerContactInfo order={order} />
            </div>
            <div className="col-sm-6 col-xs-12">
              <OrderContactInfo order={order} />
              <SeeSomethingWrongDescription />
            </div>
          </div>
          <hr style={{ margin: '15px auto' }} />
          <CancellationPolicy />
          <BookingPreparation />
          <ItemRestrictions orderEntries={order.orderEntries} />
          <ServiceNotice />
        </DetailsLayout>
        {isProductionEnvironment() && (
          <ShareASaleConversionTracker
            id={order.id}
            total={order.transactionTotal}
          />
        )}
      </div>
    )
  }
}

BookingConfirmation.propTypes = {
  order: PropTypes.object.isRequired,
}

export default BookingConfirmation

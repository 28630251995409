import get from 'lodash/fp/get'
import { client } from '../../../../../../../../components/hocs/withGraphQL'
import {
  getOrderCreationVariables,
  getPaypalOrderCreationVariables,
} from '../../../../../../../../lib/forms/getPricingVariablesFromForm'
import {
  handleSpecificError,
  STRIPE_GENERIC_ERROR,
  trackError,
} from '../../../../../../../../lib/errors/specificErrors'
import {
  identifyCustomer,
  storeCustomerIdInLocalStorage,
  trackOrderCompleted,
} from '../../../../../../../../lib/tracking'
// @ts-ignore
import { CREATE_ORDER } from '../../../../../../../../components/hocs/withOrderCreation'
import { ACTIONS as orderConfirmationActions } from '../../../../../../../../store/ducks/orderConfirmation'
import {
  selectors as pricingDataSelectors,
  thunks as pricingDataThunks,
} from '../../../../../../../../store/ducks/pricingData'
import { SELECTORS as sessionSelectors } from '../../../../../../../../store/ducks/session'
import store from '../../../../../../../../store/configureStore'

export async function stripeSubmit(dispatch: any, props: any) {
  try {
    // Create stripe token (async)
    const { token, error } = await props.stripe.createToken()

    if (error) {
      trackError(error)
      throw new Error(STRIPE_GENERIC_ERROR)
    }

    await pricingDataThunks.fetchPricingData()
    const state = store.getState()
    const pricingDetails = pricingDataSelectors.pricingData(state)
    const sessionIdentifier = sessionSelectors.getSessionIdentifier(state)

    const inputs = getOrderCreationVariables({
      pricingDetails,
      stripeCardToken: token.id,
    })

    // Run the createOrder mutation
    const result = await client.mutate({
      mutation: CREATE_ORDER,
      variables: {
        inputs: { ...inputs, orderSessionUuid: sessionIdentifier },
      },
    })

    // Check for any specific error code
    const errorCode = get('data.createOrder.errorCode', result)

    if (errorCode) {
      throw new Error(errorCode)
    }

    // Handle a successful order and set it in Redux
    const order = get('data.createOrder.order', result)
    const { customer } = order

    storeCustomerIdInLocalStorage(customer.id)
    identifyCustomer(customer)
    trackOrderCompleted({ order, total: pricingDetails.total })
    dispatch(orderConfirmationActions.setCompletedOrder(order))
  } catch (error) {
    handleSpecificError({ error })
  }
}

export async function paypalSubmit(dispatch: any, _props: any) {
  try {
    await pricingDataThunks.fetchPricingData()
    const state = store.getState()
    const pricingDetails = pricingDataSelectors.pricingData(state)
    const sessionIdentifier = sessionSelectors.getSessionIdentifier(state)
    const inputs = getPaypalOrderCreationVariables({
      pricingDetails,
    })

    // Run the createOrder mutation
    const result = await client.mutate({
      mutation: CREATE_ORDER,
      variables: {
        inputs: { ...inputs, orderSessionUuid: sessionIdentifier },
      },
    })

    // Check for any specific error code
    const errorCode = get('data.createOrder.errorCode', result)

    if (errorCode) {
      throw new Error(errorCode)
    }

    // Handle a successful order and set it in Redux
    const order = get('data.createOrder.order', result)
    const { customer } = order

    storeCustomerIdInLocalStorage(customer.id)
    identifyCustomer(customer)
    trackOrderCompleted({ order, total: pricingDetails.total })
    dispatch(orderConfirmationActions.setCompletedOrder(order))
  } catch (error) {
    handleSpecificError({ error })
  }
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SELECTORS, ACTIONS } from '../../../../store/ducks/modals'
import { selectors as excludedDatesSelectors } from '../../../../store/ducks/excludedDates'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import ReselectDateForm from './components/ReselectDateForm'

function InvalidDateModal() {
  const dispatch = useDispatch()
  const handleClose = React.useCallback(() => {
    dispatch(ACTIONS.toggleInvalidDateModal(false))
  }, [dispatch])

  const excludedDates = useSelector(excludedDatesSelectors.excludedDates)
  const isInvalidDateModalVisible = useSelector(
    SELECTORS.isInvalidDateModalVisible
  )

  return (
    <CenteredModal
      onClose={handleClose}
      open={isInvalidDateModalVisible}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      focusTrapped={false}
    >
      <div className="col-xs-12 center-xs">
        <h3 className="text-warning font-weight-bold">
          {"We've run out of capacity on your Pickup Date."}
        </h3>
        <p style={{ margin: '5px auto' }}>
          {"We've run out of capacity on your Pickup Date."}
        </p>
        <ReselectDateForm
          excludedDates={excludedDates}
          toggleInvalidDateModal={handleClose}
        />
      </div>
    </CenteredModal>
  )
}

export default InvalidDateModal

import React from 'react'
import SectionTitle from '../../components/ui/typography/SectionTitle'
import ZipCodeForm from './components/ZipCodeForm'
import styles from './ZipCodeWall.module.scss'
import { useSelector } from 'react-redux'
import { hideGlobalLoader } from '../../lib/globalLoader'
import { SELECTORS as sessionDataSelectors } from '../../store/ducks/session'

const ZipCodeWall = () => {
  React.useEffect(() => {
    hideGlobalLoader()
  }, [])

  const sessionIdentifier = useSelector(
    sessionDataSelectors.getSessionIdentifier
  )

  return (
    <div className="row center-xs">
      <SectionTitle content="Book Online" step={1} />
      <div className="col-xs-12">
        <h2 className={styles.sub_Title}>
          Enter your Zip Code to check pricing and availability in your area.
        </h2>
      </div>
      <div className={styles.singleCard}>
        <ZipCodeForm sessionIdentifier={sessionIdentifier} />
      </div>
      <h5 className="col-xs-12 center-xs">
        Are you a Partner? Head over to our new{' '}
        <a className={styles.link} href="http://partner.goloadup.com">
          Partner Portal
        </a>
      </h5>
    </div>
  )
}

export default ZipCodeWall

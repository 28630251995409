import React from 'react'
import PropTypes from 'prop-types'
import styles from './NextSectionButton.module.scss'

const NextSectionButton = ({ className, disabled, ...rest }) => (
  <button
    className={`${styles.btnOutline} ${className} ${disabled &&
      `${styles.disabled}`}`}
    {...rest}
  >
    Next
  </button>
)

NextSectionButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default NextSectionButton

import * as React from 'react'
import { PAYPAL_BUTTONS_ID } from '../../../../lib/const'
import { graphQLTrack } from '../../../../lib/tracking/graphqlMutation'
import store from '../../../../store/configureStore'
import styles from './PaypalButtons.module.css'
import {
  thunks as pricingDataThunks,
  selectors as pricingDataSelectors,
} from '../../../../store/ducks/pricingData'
import { useDispatch } from 'react-redux'

// TODO: Find a way to get CRA to play nice with .d.ts files
declare global {
  interface Window {
    paypal: any
  }
}

interface IProps {
  onApprove: (paypalOrderId: string) => void
  setUsePaypal: () => void
  sessionIdentifier: string
}

export default function PaypalButtons(props: IProps) {
  const [hasRenderedPaypal, setHasRenderedPaypal] = React.useState(false)
  const dispatch = useDispatch()

  const { sessionIdentifier, setUsePaypal, onApprove } = props

  React.useEffect(() => {
    if (window.paypal && !hasRenderedPaypal) {
      window.paypal
        .Buttons({
          style: {
            label: 'pay',
            layout: 'vertical',
            shape: 'pill',
            height: 40,
          },
          async createOrder(data: any, actions: any) {
            await graphQLTrack('PAYPAL_PAYMENT_INITIATED', {
              sessionIdentifier,
            })
            await dispatch(pricingDataThunks.fetchPricingData())
            const { total } = pricingDataSelectors.pricingData(store.getState())
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: total,
                  },
                },
              ],
            })
          },
          async onApprove(data: any, _actions: any) {
            await graphQLTrack('PAYPAL_PAYMENT_COMPLETED', {
              sessionIdentifier,
            })

            onApprove(data.orderID)
            setUsePaypal()
          },
        })
        .render(`#${PAYPAL_BUTTONS_ID}`)
    }
    setHasRenderedPaypal(true)
  }, [
    hasRenderedPaypal,
    onApprove,
    sessionIdentifier,
    setUsePaypal,
    setHasRenderedPaypal,
    dispatch,
  ])

  return <div className={styles.buttons} id={PAYPAL_BUTTONS_ID} />
}

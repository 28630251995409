import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import CenteredModal from '../../../../components/ui/layout/CenteredModal'
import WeCallYouDescription from '../UnrecoverableErrorModal/components/WeCallYouDescription'
import YouCallUsDescription from '../UnrecoverableErrorModal/components/YouCallUsDescription'
import withInvalidZipAttemptsData from './components/withInvalidZipAttemptsData'

const InvalidZipAttemptsModal = ({
  invalidZipAttempts,
  setInvalidZipAttempts,
}) => (
  <CenteredModal
    open={invalidZipAttempts > 2}
    onClose={() => setInvalidZipAttempts(0)}
  >
    <div className="col-xs-12 center-xs">
      <h3 className="text-warning font-weight-bold">
        {"Looks like you're having trouble with your zip code."}
      </h3>
      <p style={{ margin: '5px auto' }}>
        {"Let's get in touch to see if we can send our team to help."}
      </p>
      <YouCallUsDescription />
      <h4 style={{ margin: '5px auto' }}>Or</h4>
      <WeCallYouDescription leadReason="ZIP_NOT_FOUND" />
    </div>
  </CenteredModal>
)

InvalidZipAttemptsModal.propTypes = {
  invalidZipAttempts: PropTypes.number.isRequired,
  setInvalidZipAttempts: PropTypes.func.isRequired,
}

export default compose(withInvalidZipAttemptsData)(InvalidZipAttemptsModal)

import React from 'react'
import { useDispatch } from 'react-redux'
import ActionLink from '../../../../../../../../../../components/ui/typography/ActionLink/index'
import { ACTIONS as modalActions } from '../../../../../../../../../../store/ducks/modals'

const ChargeUpfrontLabel = () => {
  const dispatch = useDispatch()

  const handleOpen = React.useCallback(() => {
    dispatch(modalActions.toggleChargeUpfrontModal(true))
  }, [dispatch])

  return (
    <p className="col-xs-12" style={{ padding: 0 }}>
      <ActionLink onClick={handleOpen} content="Why do we Charge Up Front?" />
    </p>
  )
}

export default ChargeUpfrontLabel

import React from 'react'
import PropTypes from 'prop-types'
import SelectableExplainer from '../../touchables/SelectableExplainer'

const renderSelectableContainer = ({ input, options }) =>
  options.map((option) => (
    <SelectableExplainer
      key={option.value}
      active={option.value === input.value}
      content={option.content}
      onClick={() => input.onChange(option.value)}
      subtitle={option.subtitle}
      title={option.title}
    />
  ))

const OptionPropType = PropTypes.shape({
  content: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
})

renderSelectableContainer.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  }),
  options: PropTypes.arrayOf(OptionPropType).isRequired,
}

renderSelectableContainer.defaultProps = {
  options: [],
}

export default renderSelectableContainer

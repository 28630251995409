import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = ({ content }) => (
  <h1 className="entry-title col-xs-12">{content}</h1>
)

SectionTitle.propTypes = {
  content: PropTypes.string.isRequired,
}

export default SectionTitle

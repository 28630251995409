const GLOBAL_LOADER_ID = 'global-loader-3023259'

export const hideGlobalLoader = () => {
  const elem = document.getElementById(GLOBAL_LOADER_ID)
  if (elem) {
    elem.parentNode.removeChild(elem)
  } else {
    console.log('Global Loader Not Found')
  }
}

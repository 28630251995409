import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { CREATE_ORDER_OPERATION } from '../../../lib/operations'

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 10,
        retryIf: (error, operation) => {
          // Don't retry order creation
          if (operation.operationName === CREATE_ORDER_OPERATION) {
            return false
          }
          return Boolean(error)
        },
      },
    }),
    new HttpLink({
      uri: process.env.REACT_APP_RETAIL_GRAPHQL_API_URI,
      credentials: 'same-origin',
    }),
  ]),
  cache: new InMemoryCache(),
})

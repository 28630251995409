import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { minus } from 'react-icons-kit/icomoon/minus'
import { plus } from 'react-icons-kit/icomoon/plus'
import colors from '../../../../../../styles'
import { ItemTypePropType } from '../../../../../../lib/types'
import ICONS from '../../../../../../assets/icons'
import ActionLink from '../../../../typography/ActionLink'
import ItemCounterButton from '../ItemCounterButton'
import { ACTIONS as modalActions } from '../../../../../../store/ducks/modals'
import styles from './ItemCounterRow.module.scss'

const ItemCounterRow = ({
  count,
  item,
  onAdd,
  onRemove,
  onSubtract,
  addDisabled,
  subtractDisabled,
  showWarningMsg,
}) => {
  const dispatch = useDispatch()
  const toggleManyItemsCallModal = React.useCallback(() => {
    dispatch(modalActions.toggleManyItemsCallModal(true))
  }, [dispatch])
  return (
    <div
      className={`row col-xs-12 middle-xs center-xs blink-borders ${styles.itemRow}`}
      style={{ minHeight: 100 }}
    >
      {showWarningMsg && (
        <div className={`${styles.warningBar} col-xs-12`}>
          If you have more than 99 items please call us at{' '}
          <a href="tel:844.239.7711">844-239-7711</a> or{' '}
          <ActionLink
            onClick={toggleManyItemsCallModal}
            content="Have Us Call You!"
          />
        </div>
      )}
      <div className="col-xs-2">
        <img
          src={ICONS[item.icon]}
          alt={`LoadUp ${item.name}`}
          className={styles.img}
        />
      </div>
      <div className="col-sm-6 col-md-6 col-xs-5 col-md-offset-1 start-xs">
        <div className={styles.label} style={{ fontWeight: '800' }}>
          {item.name}
        </div>
        {item.subtext && (
          <p style={{ color: colors.colors.dark3 }} className={styles.subText}>
            {item.subtext}
          </p>
        )}
      </div>
      <div className="col-md-3 col-sm-4 col-xs-5 row middle-xs center-xs">
        <ItemCounterButton
          className="item-counter-button-minus"
          disabled={subtractDisabled}
          icon={minus}
          onClick={onSubtract}
        />
        <h3 style={{ fontWeight: '900', margin: '0 10px', fontSize: '1.8rem' }}>
          {count || 0}
        </h3>
        <ItemCounterButton
          className="item-counter-button-plus"
          disabled={addDisabled}
          icon={plus}
          onClick={onAdd}
        />
        <div className="col-xs-12">
          <p
            className={`text-danger ${styles.remove}`}
            style={{ cursor: 'pointer', maxWidth: 40, margin: '15px auto 0' }}
            onClick={onRemove}
          >
            Remove
          </p>
        </div>
      </div>
    </div>
  )
}

ItemCounterRow.propTypes = {
  addDisabled: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  item: ItemTypePropType.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onSubtract: PropTypes.func.isRequired,
  subtractDisabled: PropTypes.bool.isRequired,
  showWarningMsg: PropTypes.bool.isRequired,
}

export default ItemCounterRow

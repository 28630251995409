import { getHostSource } from '../environment/environment'

export default () => {
  switch (getHostSource()) {
    case 'cdp':
      return {
        email: 'support@couchdisposalplus.com',
        name: 'Couch Disposal Plus',
        phone: '844-311-0204',
        tos: 'https://couchdisposalplus.com/terms/',
        url: 'https://www.couchdisposalplus.com',
      }
    case 'mdp':
      return {
        email: 'support@mattressdisposalplus.com',
        name: 'Mattress Disposal Plus',
        phone: '877-708-8329',
        tos: 'https://mattressdisposalplus.com/terms-and-conditions/',
        url: 'https://www.mattressdisposalplus.com',
      }
    default:
      return {
        email: 'support@goloadup.com',
        name: 'LoadUp',
        phone: '844-239-7711',
        tos: 'https://goloadup.com/terms/',
        url: 'https://www.goloadup.com',
      }
  }
}

import get from 'lodash/fp/get'
import isObject from 'lodash/fp/isObject'
import store from '../../../store/configureStore'
import { ACTIONS as modalActions } from '../../../store/ducks/modals'
import { ACTIONS as finalServiceLocationActions } from '../../../store/ducks/finalServiceLocation'
import { client } from '../../../components/hocs/withGraphQL'
import { query as ExcludedDatesQuery } from '../../../components/hocs/withExcludedDates'
import { getMinAndMaxDateRange } from '../../../lib/dates/availableDates'
import { MODALS, trackViewModal } from '../../tracking'
import { logRocketCaptureError } from '../../tracking/logrocket/index'

const PARTNER_PORTAL_URI = 'https://partner.goloadup.com'

/**
 * Export this constant and use it in errorMap so as to be able to manually
 * handle this exact error in stripe calls
 */
export const STRIPE_GENERIC_ERROR = 'STRIPE_GENERIC_ERROR'

/**
 * @desc Trigger the InvalidDateModal visibility
 */
const triggerInvalidDateModal = async () => {
  const orderSessionUuid = get('session.sessionIdentifier', store.getState())
  await client.query({
    query: ExcludedDatesQuery,
    variables: {
      inputs: {
        ...getMinAndMaxDateRange(),
        orderSessionUuid,
      },
    },
    fetchPolicy: 'network-first',
  })
  store.dispatch(modalActions.toggleInvalidDateModal(true))
  trackViewModal({ name: MODALS.INVALID_DATE })
}

/**
 * @desc Triggers the FinalServiceLocationUnavailableModal
 */
const triggerInvalidFinalServiceLocationModal = () => {
  store.dispatch(
    modalActions.toggleInvalidFinalServiceLocationModalVisible(true)
  )
  trackViewModal({ name: MODALS.INVALID_SERVICE_LOCATION })
}

/**
 * @desc Trigger the PaymentErrorModal visibility
 */
const triggerPaymentErrorModal = (copy) => {
  store.dispatch(modalActions.togglePaymentErrorModal(true, copy))
  trackViewModal({ name: MODALS.PAYMENT_ERROR, properties: { copy } })
}

/**
 * @desc Trigger the UnrecoverableErrorModal visibility
 */
const triggerUnrecoverableErrorModal = (copy) => {
  store.dispatch(modalActions.toggleUnrecoverableErrorModal(true, copy))
  trackViewModal({ name: MODALS.UNRECOVERABLE_ERROR, properties: { copy } })
}

/**
 * @desc Sets the final service location as invalid
 */
const setFinalServiceLocationAsInvalid = () =>
  store.dispatch(finalServiceLocationActions.setFinalLocationInvalid())

const errorMap = {
  CUSTOMER_MISSING: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en: "Uh Oh! It looks like we don't have a customer on your account.",
    },
  },
  CUSTOMER_ORGANIZATION_MISSING: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en: "Uh Oh! It looks like there's no customer organization for you!",
    },
  },
  DATE_NOT_ALLOWED: {
    action: triggerInvalidDateModal,
    lang: {
      en:
        "Uh Oh! It doesn't look like we offer pickup on that date. Please choose a different date or contact support.",
    },
  },
  EXISTING_CUSTOMER_WITH_EMAIL: {
    action: alert,
    lang: {
      en: "Hey! It looks like there's already a customer using that email",
    },
  },
  FAILED_RAILS_VALIDATION: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        'Hey! It looks like some of your information is incorrect. Give us a call and we can sort it out.',
    },
  },
  INCORRECTLY_FORMATTED_INPUT: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        'Hey! It looks like some of your information is incorrect. Give us a call and we can sort it out.',
    },
  },
  INVALID_CUSTOMER_TYPE: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en: 'Uh Oh! It looks like your ordering on the wrong portal!',
    },
  },
  INVALID_USERNAME_OR_PASSWORD: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        "We can't seem to find your username and password. We would be happy to help you ove the phone. Give us a call or we can call you!",
    },
  },
  OUTDATED_ITEM_TYPES_VERSION: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        "Whoops! It looks like we have made some significant changes to our ordering system since you started. Let's get you over to the right place",
    },
  },
  PASSWORD_RESET_PERIOD_EXPIRED: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        'Whoops! It looks like your request has expired. We would be happy to help you over the phone. Give us a call or we can call you!',
    },
  },
  PASSWORD_RESET_FAILED: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        "Whoops! We can't seem to verify your password. We would be happy to help you over the phone. Give us a call or we can call you!",
    },
  },
  PAYPAL_CAPTURE_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmm..we're having trouble processing your PayPal transaction. Try again or give us a call! We would be happy to help you over the phone.'",
    },
  },
  PAYPAL_ORDER_MULTIPLE_PURCHASE_UNITS: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmm..we're having trouble processing your PayPal transaction. Try again or give us a call! We would be happy to help you over the phone.'",
    },
  },
  PAYPAL_ORDER_RETRIEVAL_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmm..we're having trouble processing your PayPal transaction. Give us a call! We would be happy to help you over the phone.'",
    },
  },
  PAYPAL_ORDER_UPDATE_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmm..we're having trouble processing your PayPal transaction. Try again or give us a call! We would be happy to help you over the phone.'",
    },
  },
  PAYPAL_PURCHASE_UNIT_NOT_USD: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmm..we're having trouble processing your PayPal transaction. Give us a call! We would be happy to help you over the phone.'",
    },
  },
  PAYPAL_ORDER_INVALID_STATUS: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmm..we're having trouble processing your PayPal transaction. Give us a call! We would be happy to help you over the phone.'",
    },
  },
  RETAIL_ORDER_BY_NON_RETAIL_CUSTOMER: {
    action: () => {
      const result = window.confirm(
        'Howdy, Partner! Did you know this is the retail form? Please log into your partner account to complete this order. Click OK to be redirected to the partner portal, or visit https://partner.goloadup.com.'
      )

      if (result) {
        window.location = PARTNER_PORTAL_URI
      }
    },
    lang: {
      en:
        'Are you sure you want to use the retail form, Partner? You can log into your account to see this order on your partner portal.',
    },
  },
  STRIPE_LOST_CARD: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Uh Oh! Your Credit Card was reported lost. Please check the number and try again or give us a call! We would be happy to help you over the phone.',
    },
  },
  STRIPE_CARD_DECLINED: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Your Credit Card has declined in our system. Please check the number and try again or give us a call! We would be happy to help you over the phone.',
    },
  },
  STRIPE_EXPIRED_CARD: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Uh Oh! You're Credit Card seems to be expired. Please check the number and try again or give us a call! We would be happy to help you over the phone.",
    },
  },
  [STRIPE_GENERIC_ERROR]: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Oh no! We are having trouble processing your payment. Please check the number and try again or give us a call! We would be happy to help you over the phone.',
    },
  },
  STRIPE_FRAUDULENT_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        "Hmmm...Something isn't quite right with your Credit Card number. Please check the number and try again or give us a call! We would be happy to help you over the phone.",
    },
  },
  STRIPE_INSUFFICIENT_FUNDS_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Your Credit Card has declined in our system. Please check the number and try again or give us a call! We would be happy to help you over the phone.',
    },
  },
  STRIPE_INVALID_REQUEST: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        'Oh no! We are having trouble processing your payment. We would be happy to help you over the phone. Give us a call or we can call you!',
    },
  },
  STRIPE_INVALID_CVC: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Houston, we have a problem with your CVC number. Please check the number and try again or give us a call! We would be happy to help you over the phone.',
    },
  },
  STRIPE_PROCESSING_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Oh no! We are having trouble processing your payment. We would be happy to help you over the phone. Give us a call or we can call you!',
    },
  },
  STRIPE_UNKNOWN_CARD_ERROR: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Oh no! We are having trouble processing your payment. We would be happy to help you over the phone. Give us a call or we can call you!',
    },
  },
  STRIPE_TOKEN_ALREADY_USED: {
    action: triggerPaymentErrorModal,
    lang: {
      en:
        'Oh no! We are having trouble processing your payment. We would be happy to help you over the phone. Give us a call or we can call you!',
    },
  },
  UNABLE_TO_DETERMINE_LOCATION_OF_ZIP_CODE: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        "Hmmm. We can't seem to find this zip code. We would be happy to help you over the phone. Give us a call or we can call you!",
    },
  },
  UNABLE_TO_FIND_PLACE_ID_DETAILS: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        'Houston, we have a problem with your address. We would be happy to help you over the phone. Give us a call or we can call you!',
    },
  },
  UNABLE_TO_FIND_SERVICE_AREA: {
    action: () => {
      triggerInvalidFinalServiceLocationModal()
      setFinalServiceLocationAsInvalid()
    },
    lang: {
      en:
        'Uh Oh! There looks to be something wrong with your address. We would be happy to help you over the phone.',
    },
  },
  OTHER: {
    action: triggerUnrecoverableErrorModal,
    lang: {
      en:
        "Whoops! Something isn't quite right here. We would be happy to help you over the phone. Give us a call or we can call you!",
    },
  },
}

/**
 * @desc Handles specific error structure returned from GraphQL
 * @param graphQLErrors
 */
export const handleGraphQLErrors = (error) => {
  console.error('Handling GraphQL Error')
  logErrorToConsole(error)
  const { graphQLErrors = [], message = 'OTHER' } = error
  let errorMessage = message
  if (graphQLErrors.length > 0) {
    errorMessage = graphQLErrors[0].message
  }
  handleSpecificError({ error: errorMessage })
  logRocketCaptureError(error)
}

/**
 * @desc Handles a specific error enum with a specific action
 * @param error
 */
export const handleSpecificError = ({ error }) => {
  logErrorToConsole(error)
  const errorMessage = getErrorMessage(error)
  const { action, lang } = errorMap[errorMessage] || errorMap['OTHER']
  if (action) {
    action(lang.en)
  }
  logRocketCaptureError(getErrorObject(error))
  return lang.en
}

/**
 * Tracks errors with tracking services but doesn't stop the user
 * @param error
 */
export const trackError = (error) => {
  try {
    logErrorToConsole(error)
    logRocketCaptureError(getErrorObject(error))
  } catch (error) {
    console.error('Whoa unable to track error', error)
  }
}

const getErrorMessage = (error) => (isObject(error) ? error.message : error)

const getErrorObject = (error) => (isObject(error) ? error : new Error(error))

/**
 * Takes an error and logs it!
 * @param error
 */
const logErrorToConsole = (error) => {
  console.error('Handling error')
  console.error(error)
  console.error(JSON.stringify(error))
}

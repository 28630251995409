/**
 * @desc Utilities for retrieving pricing specific information
 */

const NULL_PRICE_COPY = '--'

/**
 * @desc Get the common params for each type of item
 * @param item
 */
const getCommonParams = (item = {}) => ({
  id: item.id,
  name: item.itemType.name,
})

/**
 * @desc Get the items for assembly
 */
export const getAssemblyItems = ({ items = [] }) =>
  items
    .map((item) => ({
      ...getCommonParams(item),
      quantity: item.assemblyCount,
      unitPrice: item.assemblyUnitPrice,
      subtotal: item.assemblySubtotal,
    }))
    .filter(({ subtotal }) => subtotal)

/**
 * @desc Get the base price
 */
export const getBasePrice = ({ basePrice } = {}) => basePrice || 0

/**
 * @desc Get the items for disassembly
 */
export const getDisassemblyItems = ({ items = [] }) =>
  items
    .map((item) => ({
      ...getCommonParams(item),
      quantity: item.disassemblyCount,
      unitPrice: item.disassemblyUnitPrice,
      subtotal: item.disassemblySubtotal,
    }))
    .filter(({ subtotal }) => subtotal)

/**
 * @desc Get the discounts applied to the order
 * @param discounts
 * @returns {Array}
 */
export const getDiscountsApplied = ({ discounts }) =>
  discounts.filter(({ subtotal }) => subtotal)

/**
 * @desc Gets the extras
 * @param props
 */
export const getExtrasInfo = (pricingDetails) =>
  [getFlightsOfStairsInfo(pricingDetails)].filter(({ subtotal }) => subtotal)

/**
 * @desc Get info on the flights of stairs
 * @param flightsOfStairsCount
 * @param flightsOfStairsSubtotal
 * @param flightsOfStairsUnitPrice
 */
const getFlightsOfStairsInfo = ({
  flightsOfStairsCount,
  flightsOfStairsSubtotal,
  flightsOfStairsUnitPrice,
} = {}) => ({
  name: 'Flights of Stairs',
  quantity: flightsOfStairsCount,
  subtotal: flightsOfStairsSubtotal,
  unitPrice: flightsOfStairsUnitPrice,
})

/**
 * @desc Get the promo code variables
 * @param promoApplied
 * @param promoCode
 * @param promoDiscountSubtotal
 */
export const getPromoVariables = ({
  promoApplied,
  promoCode,
  promoDiscountSubtotal,
}) => ({
  promoApplied,
  promoCode,
  promoDiscountSubtotal,
})

/**
 * @desc Get the items for removal
 */
export const getRemovalItems = ({ items = [] }) =>
  items
    .map((item) => ({
      ...getCommonParams(item),
      quantity: item.pickupCount,
      unitPrice: item.pickupUnitPrice,
      subtotal: item.pickupSubtotal,
    }))
    .filter(({ subtotal }) => subtotal)

/**
 * @desc Checks if we should be displaying the minimum price
 * @param minimumPriceApplied
 */
export const shouldUseMinimumPrice = ({ minimumPriceApplied }) =>
  minimumPriceApplied

/**
 * @desc Gets the display price if items delected
 * @param minimumPrice
 * @param minimumPriceApplied
 * @param total
 * @param rest
 */
const getDisplayTotalPrice = ({ minimumPrice, total, ...rest }) =>
  getBasePrice(rest) === total
    ? NULL_PRICE_COPY
    : shouldUseMinimumPrice(rest)
    ? minimumPrice
    : total

/**
 * @desc Checks if there are any items slated for pickup
 * @param items
 */
const hasRemovalItemsSelected = ({ items }) =>
  items && items.reduce((acc, item) => acc + item.pickupCount, 0)

/**
 * @desc Get the total price
 * @desc Potentially apply the minimum price if necessary
 */
export const getTotalPrice = (props) =>
  hasRemovalItemsSelected(props) ? getDisplayTotalPrice(props) : NULL_PRICE_COPY

import sortBy from 'lodash/fp/sortBy'
import itemTypesData from '../../../../../../itemTypesData.json'

const handleSort = (item) => {
  return Number(item.id)
}

export const readItemTypes = () => sortBy([handleSort], itemTypesData.itemTypes)

export const itemTypesVersion = itemTypesData.itemTypesVersion

export const readRemovalItemsData = () => {
  return itemTypesData
}

import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SELECTORS as orderConfirmationSelectors } from '../store/ducks/orderConfirmation'
import { SELECTORS as pageNavigationSelectors } from '../store/ducks/pageNavigation'
import {
  SELECTORS as sessionSelectors,
  THUNKS as sessionThunks,
} from '../store/ducks/session'
import BookingConfirmation from './BookingConfirmation'
import NewOrder from './NewOrder'
import CancellationPolicyModal from './NewOrder/components/CancellationPolicyModal'
import HaveARepCallYouModal from './NewOrder/components/HaveARepCallYouModal'
import InvalidZipAttemptsModal from './NewOrder/components/InvalidZipAttemptsModal'
import ManyItemsCallModal from './NewOrder/components/ManyItemsCallYouModal'
import { readItemTypes } from './NewOrder/components/OrderAccordion/components/withRemovalItemsData'
import UnrecoverableErrorModal from './NewOrder/components/UnrecoverableErrorModal'
import ZipCodeOutOfServiceModal from './NewOrder/components/ZipCodeOutOfServiceModal'
import ZipCodeWall from './ZipCodeWall'

const Routes = () => {
  const dispatch = useDispatch()
  const itemTypes = React.useMemo(readItemTypes, [])
  const completedOrder = useSelector(
    orderConfirmationSelectors.getCompletedOrder
  )
  const isValidZipCode = useSelector(pageNavigationSelectors.isZipCodeValid)
  const isOrderSessionCreated = useSelector(
    sessionSelectors.isOrderSessionCreated
  )

  React.useEffect(() => {
    dispatch(sessionThunks.resetSession())
  }, [dispatch])

  return (
    isOrderSessionCreated && (
      <Fragment>
        {completedOrder ? (
          <BookingConfirmation order={completedOrder} />
        ) : (
          <div className="horizontal-site-spacing">
            {isValidZipCode ? (
              <NewOrder itemTypes={itemTypes} />
            ) : (
              <ZipCodeWall />
            )}
          </div>
        )}
        <HaveARepCallYouModal />
        <ManyItemsCallModal />
        <ZipCodeOutOfServiceModal />
        <InvalidZipAttemptsModal />
        <UnrecoverableErrorModal />
        <CancellationPolicyModal />
      </Fragment>
    )
  )
}

export default Routes

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import renderCalendarDatePickerInput from '../../../../../../components/ui/forms/renderCalendarDatePickerInput'

const ReselectDateForm = ({ excludedDates, toggleInvalidDateModal }) => (
  <div id="reselect-date-input-wrapper">
    <Field
      name="date"
      component={renderCalendarDatePickerInput}
      disabledDates={excludedDates}
      onChange={toggleInvalidDateModal}
    />
  </div>
)

ReselectDateForm.propTypes = {
  excludedDates: PropTypes.arrayOf(PropTypes.string),
  toggleInvalidDateModal: PropTypes.func.isRequired,
}

const withForm = reduxForm({
  form: 'scheduleForm',
  destroyOnUnmount: false,
})

export default compose(withForm)(ReselectDateForm)

import React from 'react'
import PropTypes from 'prop-types'
import { gql } from 'apollo-boost'
import { ItemTypePropType } from '../../../../../../lib/types'
import RemovalItemsForm from './components/RemovalItemsForm'

const RemovalItemsSection = ({ itemTypes, onFinishSection }) => (
  <RemovalItemsForm
    itemTypes={itemTypes}
    initialValues={{ removalItems: {} }}
    onFinishSection={onFinishSection}
  />
)

RemovalItemsSection.propTypes = {
  onFinishSection: PropTypes.func.isRequired,
  itemTypes: PropTypes.arrayOf(ItemTypePropType).isRequired,
}

RemovalItemsSection.defaultProps = {
  itemTypes: [],
}

RemovalItemsSection.fragments = {
  itemTypesFields: gql`
    fragment itemTypeFields on ItemType {
      aliases
      assemblyAllowed
      assemblyPrice
      auxillaryText
      detail
      disassemblyAllowed
      disassemblyPrice
      icon
      id
      marquee
      name
      pickupAllowed
      pickupPrice
      slug
      subtext
    }
  `,
}

export default RemovalItemsSection
